<script setup>
import { EventPicture } from '@/models/EventPicture'
import { useEventStore } from '@/stores/Event'

const { openEventPictureModal } = useEventStore()
const props = defineProps({
  eventPicture: {
    type: EventPicture,
    required: true
  }
})
const openEventPicture = () => {
  openEventPictureModal(props.eventPicture)
}
</script>

<template>
  <div>
    <div
      class="lg:hover:scale-105 hover:shadow-lg hover:z-10 duration-500 delay-100 cursor-pointer"
      @click="openEventPicture"
    >
      <div class="relative overflow-hidden rounded-lg uk-transition-toggle">
        <div class="relative w-full h-full aspect-[3/3]">
          <img
            :src="props.eventPicture.photo.thumbnailUrl"
            alt=""
            class="object-cover w-full h-full"
          />
        </div>
      </div>
    </div>
  </div>
</template>
