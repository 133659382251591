<script setup>
import { useAuthStore } from '@/stores/auth'
import { ref } from 'vue'
import { storeToRefs } from 'pinia'
import IconLoading from '@/components/icons/IconLoading.vue'
import { AVAILABLE_LOCALES } from '@/services/I18n'
import TwErrorsList from '@/components/ui/TwErrorsList.vue'
import { UpdatableUser } from '@/valueObjects/UpdatableUser'
import { base64EncodeFile } from '@/services/Base64EncodeFile'
import { UserRepository } from '@/repositories/UserRepository'
import { mapErrors } from '@/services/MapErrors'
import { auth } from '../../../firebaseConfig'
import { updateEmail } from 'firebase/auth'

const userRepo = new UserRepository()
const authStore = useAuthStore()
const { user } = storeToRefs(authStore)

const name = ref(user.value.name)
const email = ref(user.value.email)
const avatarUrl = ref(user.value.avatarUrl)
const locale = ref(user.value.locale)
const avatarFile = ref(null)
const saving = ref(false)
const errors = ref([])

const DEFAULT_AVATAR_URL = '/assets/images/avatars/avatar-3.jpg'
const removeAvatar = () => {
  avatarFile.value = null
  avatarUrl.value = DEFAULT_AVATAR_URL
}

const pickAvatar = () => {
  avatarFile.value.click()
}

const changeAvatar = () => {
  const file = avatarFile.value.files[0]
  const reader = new FileReader()
  reader.onload = (e) => {
    avatarUrl.value = e.target.result
  }
  reader.readAsDataURL(file)
}

const save = async () => {
  saving.value = true
  errors.value = []
  const updatableUser = UpdatableUser.fromUser(user.value)
  const file = avatarFile.value.files[0]
  updatableUser.name = name.value
  updatableUser.email = email.value
  updatableUser.locale = locale.value
  updatableUser.avatarFile = file ? await base64EncodeFile(file) : null
  updatableUser.shouldRemoveAvatar = avatarUrl.value === DEFAULT_AVATAR_URL
  updatableUser.avatarFileName = file ? file.name : null
  const resp = await userRepo.updateUser(updatableUser)
  if (resp.errors) {
    errors.value = mapErrors(resp.errors)
  } else {
    if (email.value !== user.value.email) {
      await updateEmail(auth.currentUser, email.value)
    }
    authStore.getUser()
  }

  saving.value = false
}
</script>

<template>
  <!-- tab user basic info -->
  <div>
    <div>
      <div class="space-y-6">
        <div class="md:flex items-center gap-10">
          <label class="md:w-32 text-right">{{ $t('settings.name') }}</label>
          <div class="flex-1 max-md:mt-4">
            <input v-model="name" type="text" class="lg:w-1/2 w-full" />
          </div>
        </div>

        <div class="md:flex items-center gap-10">
          <label class="md:w-32 text-right">{{ $t('settings.email') }}</label>
          <div class="flex-1 max-md:mt-4">
            <input v-model="email" type="text" class="w-full" />
          </div>
        </div>

        <div class="md:flex items-center gap-10">
          <label class="md:w-32 text-right">{{ $t('settings.language') }}</label>
          <div class="flex-1 max-md:mt-4">
            <select v-model="locale" class="w-full !border-0 !rounded-md">
              <option v-for="locale in AVAILABLE_LOCALES" :key="locale" :value="locale.locale">
                {{ locale.label }}
              </option>
            </select>
          </div>
        </div>

        <div class="flex flex-col md:flex-row md:items-center md:gap-10">
          <label class="md:w-32 md:text-right">{{ $t('settings.avatar') }}</label>
          <div class="flex-1 flex items-center gap-5 max-md:mt-4">
            <input
              ref="avatarFile"
              type="file"
              class="hidden"
              accept=".jpg,.jpeg,.png"
              @change="changeAvatar"
            />
            <img :src="avatarUrl" alt="" class="w-10 h-10 rounded-full object-cover" />
            <div class="flex md:flex-row gap-2">
              <button
                type="submit"
                class="px-4 py-1 rounded-full bg-slate-100/60 border"
                @click="pickAvatar"
              >
                {{ $t('settings.pickAvatar') }}
              </button>
              <button
                type="submit"
                class="px-4 py-1 rounded-full border bg-red-100 border-red-500/30 border-red-200 flex items-center text-red-600"
                @click="removeAvatar"
              >
                <ion-icon name="trash-outline" class=""></ion-icon>
                {{ $t('settings.removeAvatar') }}
              </button>
            </div>
          </div>
        </div>
        <TwErrorsList :errors="errors" />
      </div>

      <div class="flex items-center justify-center gap-4 mt-16">
        <button
          type="submit"
          class="button lg:px-10 bg-primary text-white max-md:flex-1"
          @click="save"
        >
          <span v-if="!saving">{{ $t('save') }} </span>
          <IconLoading v-else class="text-white" />
          <span class="ripple-overlay"></span>
        </button>
      </div>
    </div>
  </div>
</template>
<style>
select::-ms-expand {
  color: rebeccapurple !important;
}
</style>
