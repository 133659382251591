<script setup>
import { useAuthStore } from '@/stores/auth'
import { ROUTES } from '@/router/Routes'

const authStore = useAuthStore()
const user = authStore.user
</script>

<template>
  <div>
    <a id="profile-link" class="flex items-center gap-3 p-3 group">
      <img
        :src="user.avatarUrl"
        :alt="user.name"
        class="rounded-full md:w-7 md:h-7 w-5 h-5 shrink-0 object-cover"
      />
      <span class="font-semibold text-sm max-xl:hidden">{{ user.name }}</span>
      <ion-icon
        name="chevron-forward-outline"
        class="text-xl ml-auto duration-200 group-aria-expanded:-rotate-90 max-xl:hidden"
      ></ion-icon>
    </a>
    <div
      class="bg-white sm:w-64 2xl:w-[calc(100%-16px)] w-full shadow-lg border rounded-xl overflow-hidden max-md:!top-auto max-md:bottom-16 border2 hidden"
      uk-drop="animation:uk-animation-slide-bottom-medium ;animate-out: true"
    >
      <div class="w-full h-1.5 bg-gradient-to-r to-purple-500 via-red-500 from-pink-500"></div>

      <div class="p-4 text-xs font-medium flex gap-3 items-center">
        <img :src="user.avatarUrl" class="w-8 h-8 rounded-full object-cover" :alt="user.name" />
        <div class="text-base font-semibold">{{ user.name }}</div>
      </div>
      <hr class="opacity-60" />
      <ul class="text-sm font-semibold p-2">
        <li>
          <router-link
            :to="{ name: ROUTES.SETTINGS }"
            class="flex gap-3 rounded-md p-2 hover:bg-secondery"
          >
            <ion-icon name="settings-outline" class="text-lg"></ion-icon
            >{{ $t('menu.settings') }}</router-link
          >
        </li>
        <li>
          <a
            class="flex gap-3 rounded-md p-2 hover:bg-secondery cursor-pointer"
            @click="authStore.logout()"
          >
            <ion-icon name="log-out-outline" class="text-lg"></ion-icon>{{ $t('menu.logOut') }}</a
          >
        </li>
      </ul>
    </div>
  </div>
</template>
