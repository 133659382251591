<script setup>
import { RouterView } from 'vue-router'
import { useAuthStore } from './stores/auth'
import LoadingView from './views/LoadingView.vue'
import UIkit from 'uikit'
import Icons from 'uikit/dist/js/uikit-icons'
import TwButton from '@/components/ui/TwButton.vue'
import Cookies from 'js-cookie'

import { ref } from 'vue'
UIkit.use(Icons)

const authStore = useAuthStore()
authStore.getUser()

const showInstallPrompt = ref(false)
// window.addEventListener('beforeinstallprompt', (e) => {
//   e.preventDefault()
//   // Stash the event so it can be triggered later.
//   if (Cookies.get('add-to-home-screen') === undefined) {
//     showInstallPrompt.value = e
//   }
// })
// window.addEventListener('appinstalled', () => {
//   showInstallPrompt.value = null
// })

if (Cookies.get('add-to-home-screen') === undefined) {
  showInstallPrompt.value = true
}
const install = () => {
  showInstallPrompt.value.prompt()
}

const dismiss = () => {
  Cookies.set('add-to-home-screen', null, { expires: 365 })
  showInstallPrompt.value = null
}
</script>

<template>
  <div
    v-if="showInstallPrompt"
    class="fixed p-6 w-full bg-sky-100 z-50 flex gap-4 md:items-center items-end flex-col md:flex-row justify-between shadow"
  >
    <div>
      {{ $t('installPwaCopy') }}
    </div>
    <div class="flex gap-4 justify-end">
      <TwButton color="bg-primary text-white" @click="dismiss">{{ $t('dismiss') }}</TwButton>
    </div>
  </div>
  <component :is="$route.meta.layout || 'div'" v-if="!authStore.isProfileLoading">
    <RouterView />
  </component>
  <LoadingView v-else />
</template>
