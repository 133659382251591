<script setup>
import TwButton from '@/components/ui/TwButton.vue'
import IconLoading from '@/components/icons/IconLoading.vue'
import { useAuthStore } from '@/stores/auth'
import { storeToRefs } from 'pinia'
import { UpdatableUser } from '@/valueObjects/UpdatableUser'
import { ref } from 'vue'
import { UserRepository } from '@/repositories/UserRepository'

const authStore = useAuthStore()
const { user } = storeToRefs(authStore)
const userRepo = new UserRepository()

const isLoading = ref(false)
const emailNotificationsEnabled = ref(user.value.emailNotificationsEnabled)
const pushNotificationsEnabled = ref(user.value.pushNotificationsEnabled)
const save = async () => {
  if (isLoading.value) {
    return
  }
  isLoading.value = true
  const updatableUser = UpdatableUser.fromUser(user.value)
  updatableUser.emailNotificationsEnabled = emailNotificationsEnabled.value
  updatableUser.pushNotificationsEnabled = pushNotificationsEnabled.value
  await userRepo.updateUser(updatableUser)
  await authStore.getUser()
  isLoading.value = false
}
</script>

<template>
  <div>
    <div>
      <div class="space-y-6">
        <div class="md:flex items-start gap-16">
          <label class="md:w-32 text-right font-semibold">{{
            $t('subscription.enableNotifications')
          }}</label>

          <div class="flex-1 space-y-4 interactive-effect max-md:mt-5">
            <!--            <div>-->
            <!--              <label class="inline-flex items-center">-->
            <!--                <input v-model="emailNotificationsEnabled" class="rounded" type="checkbox" />-->
            <!--                <span class="ml-3">{{ $t('subscription.notificationViaEmail') }}</span>-->
            <!--              </label>-->
            <!--            </div>-->

            <div>
              <label class="inline-flex items-center">
                <input v-model="pushNotificationsEnabled" class="rounded" type="checkbox" />
                <span class="ml-3">{{ $t('subscription.notificationViaPush') }}</span>
              </label>
            </div>
          </div>
        </div>
        <div class="flex items-center justify-center gap-4 mt-16">
          <TwButton color="primary" rounded="rounded-md" @click="save">
            <IconLoading v-if="isLoading" class="text-white" />
            <span v-else>{{ $t('save') }}</span>
          </TwButton>
        </div>
      </div>
    </div>
  </div>
</template>
