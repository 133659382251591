<script setup>
import { ref } from 'vue'
import { EventsRepository } from '@/repositories/EventsRepository'
import TwCard from './ui/TwCard.vue'
import TwBadge from './ui/TwBadge.vue'
import { formatToHumanReadable } from '@/services/DateTimeFormatter'
import { ROUTES } from '@/router/Routes'
import { useRouter } from 'vue-router'
import TwButton from '@/components/ui/TwButton.vue'

const eventRepository = new EventsRepository()
const events = ref(await eventRepository.getEvents())
const router = useRouter()
const openEvent = (event) => {
  router.push({
    name: ROUTES.EVENT,
    params: {
      id: event.id
    }
  })
}
</script>

<template>
  <div v-if="events.length > 0" class="grid grid-cols-1 lg:grid-cols-2 gap-4">
    <TwCard
      v-for="(event, index) of events"
      :key="`event-${index}`"
      class="cursor-pointer"
      @click="openEvent(event)"
    >
      <div class="relative w-full h-80 sm:px-4 sm:pt-4">
        <img
          v-if="event.coverImageUrl"
          :src="event.coverImageUrl"
          :alt="event.name"
          class="rounded-t-lg sm:rounded-lg w-full h-full object-cover"
        />
        <img
          v-else
          src="/assets/images/avatars/avatar-lg-4.jpg"
          :alt="event.name"
          class="rounded-t-lg sm:rounded-lg w-full h-full object-cover"
        />
      </div>

      <div class="sm:p-4 p-2.5 border-gray-100 font-normal space-y-3 relative">
        <div class="flex gap-2 items-start sm:items-center justify-between flex-col sm:flex-row">
          <h4 class="text-black font-medium inline-block">{{ event.name }}</h4>
          <TwBadge color="bg-sky-100">
            <template v-if="event.startAt.toLocaleString() === event.endAt.toLocaleString()">
              {{ formatToHumanReadable(event.startAt) }}
            </template>
            <template v-else>
              {{ formatToHumanReadable(event.startAt) }}
              -
              {{ formatToHumanReadable(event.endAt) }}
            </template>
          </TwBadge>
        </div>
        <p class="mt-0.5">{{ event.description }}</p>
      </div>
    </TwCard>
  </div>
  <div v-else>
    <div class="w-full text-center flex items-center flex-col gap-2">
      <p>{{ $t('events.noEvents') }}</p>
      <p>{{ $t('events.addEvent') }}</p>
      <div class="flex gap-2">
        <TwButton color="bg-sky-100" uk-toggle="target: #manage-event-modal">
          {{ $t('events.createEvent') }}
        </TwButton>
        <TwButton color="bg-sky-100" uk-toggle="target: #join-event-modal">
          {{ $t('events.joinEvent') }}
        </TwButton>
      </div>
    </div>
  </div>
</template>
