import { ROUTES } from '@/router/Routes'

const KEY_REDIRECT_URL = 'redirectUrl'

export const setRedirectPath = (path) => {
  localStorage.setItem(KEY_REDIRECT_URL, path)
}

export const redirect = (router) => {
  const redirectPath = localStorage.getItem(KEY_REDIRECT_URL)
  if (redirectPath) {
    router.replace(redirectPath)
  } else {
    router.to({ name: ROUTES.HOME })
  }
  localStorage.setItem(KEY_REDIRECT_URL, null)
}
