import { BaseAuthRepository } from './BaseAuthRepository'
import { User } from '@/models/User'
import { auth } from '../../firebaseConfig'
import { createUserWithEmailAndPassword } from 'firebase/auth'
import { sendPasswordResetEmail } from '@firebase/auth'

export class UserRepository extends BaseAuthRepository {
  async getUser() {
    const { data } = await this.getAuth('/user')

    return new User(data.data)
  }
  async register({ name, email, password } = {}) {
    try {
      const userCredentials = await createUserWithEmailAndPassword(auth, email, password)
      await this.postWithoutAuth('/user', {
        name,
        email,
        uid: userCredentials.user.uid,
        avatarUrl: userCredentials.user.avatarUrl
      })
    } catch (e) {
      if (e.code === 'auth/email-already-in-use') {
        return { success: false, error: 'login.errors.emailAlreadyInUse' }
      }
    }

    return { success: true }
  }

  async registerViaGoogle({ name, email, uid, avatarUrl } = {}) {
    await this.postWithoutAuth('/user', {
      name,
      email,
      uid,
      avatarUrl
    })

    return { success: true }
  }

  async resetPassword(email) {
    await sendPasswordResetEmail(auth, email)
  }

  async updateUser(updatableUser) {
    try {
      await this.putAuth('/user', updatableUser.toJson())

      return { success: true }
    } catch (e) {
      return { success: false, errors: e.response.data.errors }
    }
  }

  async startTrial(plan) {
    try {
      await this.getAuth('/user/start-trial', plan)

      return { success: true }
    } catch (e) {
      return { success: false, errors: e.response.data.errors }
    }
  }

  async subscribe(plan) {
    try {
      const response = await this.getAuth('/user/subscribe')

      return { paymentUrl: response.data.paymentUrl }
    } catch (e) {
      return { success: false, errors: e.response.data.errors }
    }
  }

  async updateFcmToken(token) {
    try {
      await this.putAuth('/user/fcm-token', {
        token
      })

      return { success: true }
    } catch (e) {
      return { success: false }
    }
  }
}
