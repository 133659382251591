<template>
  <svg
    class="h-5 w-5 stroke-current fill-current"
    viewBox="0 0 24 24"
    fill="fill-current"
    xmlns="http://www.w3.org/2000/svg"
    transform="rotate(0)matrix(1, 0, 0, 1, 0, 0)"
  >
    <circle cx="12" cy="6" r="1.5"></circle>
    <circle cx="12" cy="12" r="1.5"></circle>
    <circle cx="12" cy="18" r="1.5"></circle>
  </svg>
</template>
<script setup></script>
