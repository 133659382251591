<script setup>
import { useEventStore } from '@/stores/Event'
import TwModal from '@/components/ui/TwModal.vue'
import { EventMember } from '@/models/EventMember'

const { removeMember } = useEventStore()
const props = defineProps({
  member: {
    type: EventMember,
    required: true
  }
})

const confirmRemoveMember = () => {
  removeMember(props.member)
}
</script>

<template>
  <TwModal
    :id="`remove-member-${member.id}`"
    :title="$t('event.removeMember')"
    :dynamic-height="true"
  >
    <div class="p-6 py-0">
      <p>{{ $t('event.removeMemberConfirm') }}</p>
    </div>
    <template #actions>
      <button
        class="px-5 py-1.5 bg-red-50 rounded-md uk-modal-close text-red-400 flex items-center"
        type="button"
        @click="confirmRemoveMember"
      >
        <ion-icon name="trash-outline" class="text-sm mr-1"></ion-icon>
        {{ $t('event.removeMember') }}
      </button>
    </template>
  </TwModal>
</template>
