<script setup>
import TwPageTitle from '../components/ui/TwPageTitle.vue'
import TwButton from '../components/ui/TwButton.vue'
import { useRouter } from 'vue-router'
import { ROUTES } from '@/router/Routes'
import TwCard from '@/components/ui/TwCard.vue'
import OrderInfo from '@/components/orders/OrderInfo.vue'
import { ref } from 'vue'
import { OrdersRepository } from '@/repositories/OrderRepository'

const router = useRouter()
const ordersRepo = new OrdersRepository()
const newOrder = () => {
  router.push({
    name: ROUTES.NEW_ORDER
  })
}

const loading = ref(true)
const orders = ref([])
const loadOrders = async () => {
  loading.value = true
  orders.value = await ordersRepo.getOrders()
  loading.value = false
}

loadOrders()
</script>

<template>
  <TwPageTitle>
    {{ $t('orders.title') }}
    <template #actions>
      <TwButton color="bg-primary text-white" @click="newOrder">
        <ion-icon name="add-outline" class="text-xl"></ion-icon>
        {{ $t('orders.newOrder') }}
      </TwButton>
    </template>
  </TwPageTitle>
  <div class="flex-1 xl:space-y-6 space-y-3 mt-12">
    <TwCard class="p-5">
      <div v-if="!loading" class="">
        <div v-if="orders.length > 0">
          <OrderInfo v-for="(order, index) in orders" :key="`order-${index}`" :order="order" />
        </div>
        <div v-else>
          <div class="w-full text-center flex items-center flex-col gap-2">
            <p>{{ $t('orders.noOrders') }}</p>
            <p>{{ $t('orders.addOrder') }}</p>

            <TwButton color="bg-primary text-white" @click="newOrder">
              {{ $t('orders.newOrder') }}
            </TwButton>
          </div>
        </div>
      </div>
      <div v-else class="p-2">
        <div class="flex">
          <div class="bg-slate-300/60 rounded-md animate-pulse w-60 h-40"></div>
          <div class="gap-2 flex flex-col pl-4">
            <div class="bg-slate-300/60 rounded-md animate-pulse w-40 h-6"></div>
            <div class="bg-slate-300/60 rounded-md animate-pulse w-60 h-6"></div>
          </div>
        </div>
      </div>
    </TwCard>
  </div>
</template>
