export const enTranslations = {
  event: {
    title: 'Event',
    addPhoto: 'Add photo',
    noPhotos: 'There are currently no photos added to this event.',
    addPhotos: 'Go ahead - add some!',
    statusActive: 'Active',
    statusEnded: 'Ended',
    statusNotStarted: 'Not started',
    participants: 'Participants',
    gridView: 'Grid view',
    listView: 'List view',
    eventDetails: 'Event details',
    memberTypeOwner: 'owner',
    memberTypeMember: 'member',
    memberTypeViewer: 'viewer',
    deletePhoto: 'Delete photo?',
    deletePhotoConfirm: 'Are you sure you want to delete this photo?',
    deleteEventTitle: 'Delete event?',
    deleteEventConfirm: 'Are you sure you want to delete this event?',
    delete: 'Delete',
    uploadPhotos: 'Upload photos',
    dropPhotosHere: 'Drop photos here',
    or: 'Or',
    selectPhotos: 'Select photos',
    caption: 'Caption',
    photoTakenAt: 'Photo taken at',
    removePhoto: 'Remove photo',
    addMorePhotos: 'Add more photos',
    uploading: 'Uploading...',
    inviteParticipants: 'Invite participants',
    editEvent: 'Edit event',
    editPhoto: 'Edit photo',
    copyLink: 'Copy link',
    addParticipantsWithLink:
      'To invite participants to your event, please share this link with them:',
    addParticipantsWithQrCode: 'Or let them scan the QR code below:',
    makeOwner: 'Make owner',
    makeMember: 'Make member',
    makeViewer: 'Make viewer',
    removeMember: 'Remove member',
    removeMemberConfirm: 'Are you sure you want to remove this member?',
    saveEvent: 'Save event',
    postPhotosNotificationTitle: 'Post new photos to event!',
    postPhotosNotificationBody: 'It is time to post most recent photos to {eventName}!',
    noActiveSubscriptionToCreateEvent:
      "Currently you can't create a new event. In order to create an event, you need to have an active subscription.",
    pickSubscription: 'Select subscription',
    noActiveSubscriptionToUploadPhotosOwner:
      "Currently you can't upload photos to this event since none of the owners has active subscription. In order to upload photos, you need to have an active subscription.",
    noActiveSubscriptionToUploadPhotosMember:
      "Currently you can't upload photos to this event since none of the owners has active subscription. In order to upload photos, one of the owners has to have an active subscription."
  },
  events: {
    title: 'Events',
    createEvent: 'Create event',
    joinEvent: 'Join event',
    joinEventCopy:
      'To join an existing event please ask the owner of the event to share a link with you or show you a QR code.',
    joiningEventCopy: 'We are doing some magic to add you to the event. Please hold on...',
    eventTitle: 'Title',
    description: 'Description',
    duration: 'Duration',
    startOn: 'Starts on',
    endsOn: 'Ends on',
    coverImage: 'Cover image',
    generalSettings: 'General settings',
    notificationSettings: 'Notification settings',
    notificationSettingPostNewPhotos: 'Receive notification to post new pictures',
    notificationSettingPostedPicturesMembers:
      'Members should receive notification when new pictures are posted',
    notificationSettingPostedPicturesViewers:
      'Viewers should receive notification when new pictures are posted',
    never: 'Never',
    immediate: 'Immediate',
    daily: 'Daily',
    weekly: 'Weekly',
    monthly: 'Monthly',
    selectCoverImage: 'Pick cover image',
    removeCoverImage: 'Remove cover image',
    savingEvent: 'Saving event...',
    noEvents: 'You currently have no events...',
    addEvent: 'Not to worry - simply create or join your first one!',
    noEventsOrders: 'You currently have no events that you can order albums for...',
    addEventOrders:
      'Not to worry - simply create or join your first one! You can do that by going to the events page and clicking on "Create event" or "Join event" button.',
    goToEventsPage: 'Go to events page'
  },
  menu: {
    events: 'Events',
    profile: 'Profile',
    orders: 'Orders',
    settings: 'Settings',
    logOut: 'Log out'
  },
  login: {
    forgotPassword: 'Forgot password',
    joinNow: 'Join now',
    signIn: 'Sign in',
    resetPassword: 'Reset password',
    noAccount: 'No account?',
    email: 'Email',
    password: 'Password',
    invalidCredentials: 'Invalid credentials',
    name: 'Name',
    retypePassword: 'Retype password',
    logIn: 'Login',
    register: 'Register',
    alreadyHaveAccount: 'Already have an account?',
    resetPasswordSentCopy: 'You will receive an email with instructions to reset your password.',
    backToLoginCopy: 'Back to login page',
    errors: {
      noName: 'Please enter your name',
      noPassword: 'Password must be at least 8 symbols long',
      noRetypePassword: 'Please retype your password',
      passwordsDoNotMatch: 'Passwords do not match',
      invalidEmail: 'Please enter a valid email address',
      emailAlreadyInUse: 'This email is already in use',
      agreeWithTerms: 'You must agree with terms and conditions'
    }
  },
  orders: {
    title: 'Orders',
    newOrder: 'New order',
    noOrders: "You don't have any orders yet...",
    addOrder: 'Go ahead, create your first one!',
    pickEventCopy: 'To start creating a new photo album, please pick an event:',
    pickBookTypeCopy: 'Please select the type of photo album you would like to create:',
    back: 'Back',
    'ordersBookSize-1-square': '20cm x 20cm',
    'ordersBookSize-2-square': '28cm x 28cm',
    'coverType-hard': 'Hard cover',
    'coverType-soft': 'Soft cover',
    'pageType-front-cover': 'Front cover',
    'pageType-back-cover': 'Back cover',
    'pageType-front-endsheet': 'Front endsheet',
    'pageType-back-endsheet': 'Back endsheet',
    pageCount: 'Page count',
    pagesIncluded: 'Pages included in base price',
    startingFrom: 'Starting from',
    cover: 'Cover',
    pricePerExtraSpread: 'Price for extra spread',
    cantPickPhotoCopy: "Photo can't be picked for this page",
    changePhoto: 'Change photo',
    addSpread: 'Add 2 spreads',
    removePage: 'Remove page',
    addBlankPage: 'Add a blank page',
    pageInformation: 'Page information',
    selectedPage: 'Selected page',
    price: 'Price',
    continue: 'Continue',
    currentPicture: 'Current picture',
    createdAt: 'Order placed at',
    albumSize: 'Album size',
    'status-pending': 'Pending',
    'status-paid': 'Paid',
    'status-printing': 'Printing',
    'status-completed': 'Completed',
    'status-invoice-sent': 'Invoice sent',
    'status-canceled': 'Canceled',
    titleColor: 'Title color',
    titleSize: 'Title size',
    titlePosition: 'Title position'
  },
  newOrder: {
    title: 'Create new order',
    albumInformation: 'Album information',
    pageSize: 'Page size',
    cover: 'Cover',
    hardCover: 'Hard cover',
    softCover: 'Soft cover',
    numberOfPages: 'Number of pages',
    showPageNumber: 'Show page numbers',
    showPhotoDate: 'Show date on page',
    vat: 'VAT',
    total: 'Total',
    shippingPrice: 'Shipping',
    priceExclVat: 'Price excl. VAT',
    showPhotoCaptions: 'Show captions',
    placeOrder: 'Place order',
    shipping: 'Shipping',
    shippingPriceExplanation:
      'We will calculate the shipping price based on your shipping address and let you know before sending the invoice.'
  },
  address: {
    name: 'Name',
    address: 'Address',
    address2: 'Address 2',
    city: 'City',
    zipCode: 'Zip code',
    country: 'Country',
    phone: 'Phone',
    state: 'State',
    shippingAddress: 'Shipping address',
    shippingAddressSameAsBillingAddress: 'Billing address same as shipping address',
    billingAddress: 'Billing address'
  },
  newOrderSuccessful: {
    title: 'Order placed',
    copy: 'Thank you for creating amazing photo album! You will receive an email from us with an invoice. Once the payment is received, we will start printing your album. You can track the status of your order in the "Orders" section.',
    cta: 'Go to orders section'
  },
  errors: {
    imageFile: {
      imageFile: {
        file: 'The file must be an image and 20 MB or less in size'
      }
    },
    coverImageFile: {
      coverImageFile: {
        file: 'The file must be an image and 20 MB or less in size'
      }
    },
    avatarFile: {
      avatarFile: {
        file: 'The avatar be an image and 20 MB or less in size'
      }
    },
    name: {
      string: 'Title must be text',
      required: 'Title is required'
    },
    description: {
      string: 'Description must be text'
    },
    email: {
      unique: 'This email is already taken',
      email: 'Please enter valid email address',
      required: 'Email is required'
    }
  },
  settings: {
    title: 'Settings',
    general: 'General',
    notifications: 'Notifications',
    password: 'Password',
    name: 'Name',
    email: 'Email',
    avatar: 'Avatar',
    save: 'Save',
    saving: 'Saving...',
    passwordChanged: 'Password changed',
    passwordChangedCopy: 'Your password has been changed successfully.',
    oldPassword: 'Old password',
    newPassword: 'New password',
    retypeNewPassword: 'Retype new password',
    changePassword: 'Change password',
    subscription: 'Subscription',
    pickAvatar: 'Pick avatar',
    removeAvatar: 'Remove',
    language: 'Language'
  },
  subscription: {
    freeWhileInBeta: 'All tiers are free while we are in beta testing phase. Enjoy!',
    free: 'Free',
    basic: 'Basic',
    professional: 'Professional',
    currentTier: 'Current tier',
    comingSoon: 'Coming soon',
    select: 'Select',
    freeTrialExpiresAt: 'Free trial expires at',
    freeTrialExpired: 'Free trial expired. Please subscribe below',
    paymentSuccessful: 'Payment successful! Enjoy your new subscription!',
    paymentFailed: 'Something went wrong with the payment. Please try again.',
    freeTrialStarted: '14 day free trial started! Enjoy Twibie!',
    startFreeTrial: 'Start free 14 day trial',
    subscribe: 'Subscribe',
    subscriptionRenewsAt: 'Subscription renews at',
    benefitJoinEvents: 'You can join unlimited number of events and post pictures in them',
    benefitOrderAlbums: 'You can order unlimited number of albums for events you are part of',
    benefitOrderAlbumsPro: 'You can order photo albums with 10% discount',
    benefitCreateEvents: 'You can create unlimited number of events',
    benefitCreatePublicEvents:
      'Create "public" events, that people can join without creating account (Coming soon!)',
    enableNotifications: 'Notification channels',
    notificationViaEmail: 'Via email',
    notificationViaPush: 'Via in-app notification (push notification)',
    tearFree: 'Free',
    tearBasic: 'Basic',
    tearProfessional: 'Professional',
    year: 'year',
    priceFree: 'Free',
    trialExplanation:
      'All paid subscriptions come with 14 day free trial. No payment details required.'
  },
  more: 'More',
  less: 'less',
  close: 'Close',
  cancel: 'Cancel',
  delete: 'Delete',
  save: 'Save',
  install: 'Install',
  dismiss: 'Dismiss',
  installPwaCopy:
    'You can install Twibie app on your home screen for quick, easy and offline access when you’re on the go. Close this modal, tap the “share” icon, and then tap on “Add to home screen”.',
  generalError: 'Something went wrong. Please contact us for assistance.'
}
