<script setup>
import TwCard from '../ui/TwCard.vue'
import EventDetails from '@/components/event/EventDetails.vue'
import { useEventStore } from '@/stores/Event'
import { storeToRefs } from 'pinia'

const eventStore = useEventStore()
const { eventLoading, event } = storeToRefs(eventStore)
</script>

<template>
  <div
    class="xl:space-y-6 space-y-3 md:pb-12 uk-sticky"
    uk-sticky="end: #js-oversized; offset: 50; media:992"
    style=""
  >
    <template v-if="eventLoading">
      <div class="w-full h-80 bg-slate-200/60 rounded-lg animate-pulse"></div>
    </template>
    <TwCard v-else class="p-5">
      <EventDetails :event="event" />
    </TwCard>
  </div>
  <div
    class="uk-sticky-placeholder"
    style="height: 1482px; width: 370px; margin: 0"
    hidden=""
  ></div>
</template>
