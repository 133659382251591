<script setup>
import IconMore from '@/components/icons/IconMore.vue'
import TwButton from '@/components/ui/TwButton.vue'
import { EVENT_PICTURE_VIEW_GRID, EVENT_PICTURE_VIEW_LIST } from '@/config/EventPictureViewtypes'
import { useEventStore } from '@/stores/Event'
import { computed } from 'vue'

const eventStore = useEventStore()
const currentViewMode = computed(() => eventStore.currentViewMode)

const getBackgroundClass = (viewName) => {
  return currentViewMode.value === viewName ? 'bg-pink-200' : ''
}
const changeView = (view) => {
  eventStore.changeViewMode(view)
}
</script>

<template>
  <TwButton class="mr-2 uk-button" color="secondary">
    <IconMore class="text-pink-600" />
  </TwButton>
  <div
    uk-dropdown="offset:10;pos: bottom-right ; animation: uk-animation-slide-bottom-small; delay-hide:0;"
    class="uk-dropdown"
  >
    <nav>
      <a
        class="cursor-pointer"
        :class="getBackgroundClass(EVENT_PICTURE_VIEW_GRID)"
        @click="changeView(EVENT_PICTURE_VIEW_GRID)"
      >
        <ion-icon class="text-xl" name="grid"></ion-icon>
        {{ $t('event.gridView') }}
      </a>
      <a
        class="cursor-pointer"
        :class="getBackgroundClass(EVENT_PICTURE_VIEW_LIST)"
        @click="changeView(EVENT_PICTURE_VIEW_LIST)"
      >
        <ion-icon class="text-xl" name="image"></ion-icon>
        {{ $t('event.listView') }}
      </a>
      <hr />
      <a uk-toggle="target: #event-details-modal" class="cursor-pointer">
        <ion-icon class="text-xl" name="browsers"></ion-icon>
        {{ $t('event.eventDetails') }}
      </a>
    </nav>
  </div>
</template>
