import { EventMember } from './EventMember'
import { EventNotificationSettings } from '@/models/EventNotificationSettings'

export class EventFull {
  constructor({
    id = 0,
    coverImageUrl = '',
    name = '',
    createdAt = '',
    startAt = '',
    endAt = '',
    eventHash = '',
    hasStarted = true,
    hasEnded = false,
    description = '',
    selfUser = {},
    members = [],
    notificationSettings = null,
    pictureCount = 0,
    canUploadMorePhotos = false
  } = {}) {
    this.id = id
    this.coverImageUrl = coverImageUrl
    this.name = name
    this.createdAt = new Date(createdAt)
    this.startAt = new Date(startAt)
    this.endAt = new Date(endAt)
    this.eventHash = eventHash
    this.hasStarted = hasStarted
    this.hasEnded = hasEnded
    this.description = description
    this.selfUser = new EventMember(selfUser)
    this.members = members.map((m) => new EventMember(m))
    this.notificationSettings = new EventNotificationSettings(notificationSettings)
    this.pictureCount = pictureCount
    this.canUploadMorePhotos = canUploadMorePhotos
  }
}
