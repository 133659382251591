<script setup>
import TwPageTitle from '../components/ui/TwPageTitle.vue'
import TwButton from '../components/ui/TwButton.vue'
import Events from '../components/Events.vue'
import AddEventModal from '@/components/event/ManageEventModal.vue'
import JoinEventModal from '@/components/event/JoinEventModal.vue'
import { ROUTES } from '@/router/Routes'
import { useRouter } from 'vue-router'
import { useEventStore } from '@/stores/Event'

const router = useRouter()
const { clearEvent } = useEventStore()

clearEvent()
const openEvent = (eventId) => {
  router.push({
    name: ROUTES.EVENT,
    params: {
      id: eventId
    }
  })
}
</script>

<template>
  <TwPageTitle>
    {{ $t('event.title') }}
    <template #actions>
      <TwButton class="mr-2" color="primary">
        <ion-icon name="add-outline" class="text-xl"></ion-icon>
      </TwButton>
      <div
        uk-dropdown="offset:10;pos: bottom-right ; animation: uk-animation-slide-bottom-small; delay-hide:0;"
        class="uk-dropdown"
      >
        <nav>
          <a uk-toggle="target: #manage-event-modal" class="cursor-pointer">
            <ion-icon class="text-xl" name="add-outline"></ion-icon>
            {{ $t('events.createEvent') }}
          </a>
          <a uk-toggle="target: #join-event-modal" class="cursor-pointer">
            <ion-icon class="text-xl" name="browsers"></ion-icon>
            {{ $t('events.joinEvent') }}
          </a>
        </nav>
      </div>
    </template>
  </TwPageTitle>

  <div class="w-full flex-1 xl:space-y-6 space-y-3 mt-12">
    <Suspense>
      <template #default>
        <Events />
      </template>
      <template #fallback>
        <div class="rounded-xl shadow-sm p-4 space-y-4 bg-slate-200/40 animate-pulse border1">
          <div class="w-full h-52 rounded-lg bg-slate-300/20 my-3"></div>
          <div class="flex justify-between">
            <div class="w-60 h-5 rounded-md bg-slate-300/20"></div>
            <div class="w-40 h-5 rounded-full bg-slate-300/20"></div>
          </div>
          <div class="w-full h-12 rounded-md bg-slate-300/20"></div>
        </div>
      </template>
    </Suspense>
    <AddEventModal @success="openEvent" />
    <JoinEventModal />
  </div>
</template>
