<template>
  <div class="flex flex-col h-screen justify-center items-center bg-white">
    <div class="max-w-sm mx-auto md:px-10 p-4 w-full">
      <div>
        <!-- logo -->
        <div class="flex flex-col justify-center items-center mb-12">
          <img src="/assets/images/twibie-logo-stacked-v2.png" alt="" class="h-32 shrink-0" />
        </div>
        <slot> </slot>
      </div>
    </div>
  </div>
</template>
<script setup></script>
