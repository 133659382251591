<script setup>
import TwCard from '@/components/ui/TwCard.vue'
import { useBookDesignerState } from '@/stores/BookDesigner'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'
import { formatMoney } from '@/services/MoneyFormatter'
import { EUR } from '@/config/Currencies'
const designerState = useBookDesignerState()
const { bookType, spreads } = storeToRefs(designerState)
const VAT_RATE = 1.21

const numberOfPages = computed(() => {
  return (spreads.value.length - 2) * 2
})

const price = computed(() => {
  return (
    bookType.value.basePrice +
    Math.ceil((numberOfPages.value - bookType.value.includedPages) / 2) *
      bookType.value.pricePerExtraSpread
  )
})

const vat = computed(() => {
  return price.value - price.value / VAT_RATE
})

const shippingPrice = 0

const total = computed(() => {
  return price.value + shippingPrice
})
</script>

<template>
  <TwCard class="p-5 w-full">
    <div class="flex justify-between text-black">
      <h3 class="font-bold text-base">{{ $t('newOrder.albumInformation') }}</h3>
    </div>

    <div class="space-y-3.5 text-xs font-normal mt-5 mb-2 text-gray-600">
      <div class="flex">
        <h4 class="text-slate-400 text-sm">{{ $t('newOrder.pageSize') }}:</h4>
        <h4 class="font-medium text-slate-900 text-sm ml-2">
          {{ bookType.pageWidth }}cm x {{ bookType.pageHeight }}cm
        </h4>
      </div>
      <div class="flex">
        <h4 class="text-slate-400 text-sm">{{ $t('newOrder.cover') }}:</h4>
        <h4 class="font-medium text-slate-900 text-sm ml-2">{{ $t('newOrder.hardCover') }}</h4>
      </div>
      <div class="flex">
        <h4 class="text-slate-400 text-sm">{{ $t('newOrder.numberOfPages') }}:</h4>
        <h4 class="font-medium text-slate-900 text-sm ml-2">{{ numberOfPages }}</h4>
      </div>
      <div class="flex">
        <h4 class="text-slate-400 text-sm">{{ $t('newOrder.priceExclVat') }}:</h4>
        <h4 class="font-medium text-slate-900 text-sm ml-2">
          {{ formatMoney(price - vat, EUR) }}
        </h4>
      </div>
      <div class="flex">
        <h4 class="text-slate-400 text-sm">{{ $t('newOrder.vat') }}:</h4>
        <h4 class="font-medium text-slate-900 text-sm ml-2">
          {{ formatMoney(vat, EUR) }}
        </h4>
      </div>
      <!--      <div class="flex">-->
      <!--        <h4 class="text-slate-400 text-sm">{{ $t('newOrder.shipping_price') }}:</h4>-->
      <!--        <h4 class="font-medium text-slate-900 text-sm ml-2">-->
      <!--          {{ formatMoney(shippingPrice, EUR) }}-->
      <!--        </h4>-->
      <!--      </div>-->
      <div class="flex items-center">
        <h4 class="text-slate-400 text-sm">{{ $t('newOrder.total') }}:</h4>
        <h4 class="text-primary text-xl ml-2">
          {{ formatMoney(total, EUR) }}
          <span class="text-slate-400 text-sm">+ {{ $t('newOrder.shipping') }}</span>
        </h4>
      </div>
      <p class="text-slate-400 text-sm">{{ $t('newOrder.shippingPriceExplanation') }}</p>
    </div>
  </TwCard>
</template>
