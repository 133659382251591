import axios from 'axios'

export class BaseAuthRepository {
  static authToken = null
  static baseUrl = import.meta.env.VITE_API_URL

  async getAuth(path) {
    return await axios.get(`${BaseAuthRepository.baseUrl}${path}`, {
      headers: {
        Authorization: `Bearer ${BaseAuthRepository.authToken}`,
        Accept: 'application/json'
      }
    })
  }

  async deleteAuth(path) {
    return await axios.delete(`${BaseAuthRepository.baseUrl}${path}`, {
      headers: {
        Authorization: `Bearer ${BaseAuthRepository.authToken}`,
        Accept: 'application/json'
      }
    })
  }

  async postAuth(path, payload, progressCallback) {
    const config = {
      headers: {
        Authorization: `Bearer ${BaseAuthRepository.authToken}`,
        Accept: 'application/json'
      }
    }
    if (progressCallback) {
      config.onUploadProgress = progressCallback
    }

    return await axios.post(`${BaseAuthRepository.baseUrl}${path}`, payload, config)
  }

  async putAuth(path, payload, progressCallback) {
    const config = {
      headers: {
        Authorization: `Bearer ${BaseAuthRepository.authToken}`,
        Accept: 'application/json'
      }
    }
    if (progressCallback) {
      config.onUploadProgress = progressCallback
    }

    return await axios.put(`${BaseAuthRepository.baseUrl}${path}`, payload, config)
  }

  async postWithoutAuth(path, payload, progressCallback) {
    const config = {
      headers: {
        Accept: 'application/json'
      }
    }
    if (progressCallback) {
      config.onUploadProgress = progressCallback
    }

    return await axios.post(`${BaseAuthRepository.baseUrl}${path}`, payload, config)
  }
}
