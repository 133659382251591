import { createRouter, createWebHistory } from 'vue-router'
import AuthLayout from '../layouts/AuthLayout.vue'
import PrimaryLayout from '../layouts/PrimaryLayout.vue'
import { ROUTES } from './Routes'
import { auth } from '../../firebaseConfig'
import EventsView from '../views/EventsView.vue'
import OrdersView from '../views/OrdersView.vue'
import NewOrderView from '../views/NewOrderView.vue'
import EventView from '../views/EventView.vue'
import { setRedirectPath } from '@/services/LoginRedirectService'
import JoinEventView from '@/views/JoinEventView.vue'
import NewOrderPlacedView from '@/views/NewOrderPlacedView.vue'
import SettingsGeneral from '@/views/SettingsGeneral.vue'
import SettingsSubscription from '@/views/SettingsSubscription.vue'
import SettingsNotifications from '@/views/SettingsNotifications.vue'
import SettingsPassword from '@/views/SettingsPassword.vue'

const authGuard = (to, from, next) => {
  if (auth.currentUser) {
    next()
  } else {
    setRedirectPath(to.path)
    next({ name: ROUTES.LOGIN })
  }
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: ROUTES.HOME,
      meta: { layout: PrimaryLayout },
      component: EventsView,
      beforeEnter: authGuard
    },
    {
      path: '/events',
      name: ROUTES.EVENTS,
      meta: { layout: PrimaryLayout },
      component: EventsView,
      beforeEnter: authGuard
    },
    {
      path: '/event/:id',
      name: ROUTES.EVENT,
      meta: { layout: PrimaryLayout },
      component: EventView,
      beforeEnter: authGuard
    },
    {
      path: '/settings',
      name: ROUTES.SETTINGS,
      meta: { layout: PrimaryLayout },
      component: SettingsGeneral,
      beforeEnter: authGuard
    },
    {
      path: '/settings/general',
      name: ROUTES.SETTINGS_GENERAL,
      meta: { layout: PrimaryLayout },
      component: SettingsGeneral,
      beforeEnter: authGuard
    },
    {
      path: '/settings/subscription',
      name: ROUTES.SETTINGS_SUBSCRIPTION,
      meta: { layout: PrimaryLayout },
      component: SettingsSubscription,
      beforeEnter: authGuard
    },
    {
      path: '/settings/notifications',
      name: ROUTES.SETTINGS_NOTIFICATIONS,
      meta: { layout: PrimaryLayout },
      component: SettingsNotifications,
      beforeEnter: authGuard
    },
    {
      path: '/settings/password',
      name: ROUTES.SETTINGS_PASSWORD,
      meta: { layout: PrimaryLayout },
      component: SettingsPassword,
      beforeEnter: authGuard
    },
    {
      path: '/orders',
      name: ROUTES.ORDERS,
      meta: { layout: PrimaryLayout },
      component: OrdersView,
      beforeEnter: authGuard
    },
    {
      path: '/new-order',
      name: ROUTES.NEW_ORDER,
      meta: { layout: PrimaryLayout },
      component: NewOrderView,
      beforeEnter: authGuard
    },
    {
      path: '/new-order-placed',
      name: ROUTES.NEW_ORDER_PLACED,
      meta: { layout: PrimaryLayout },
      component: NewOrderPlacedView,
      beforeEnter: authGuard
    },
    {
      path: '/join/:hash',
      name: ROUTES.JOIN_EVENT,
      meta: { layout: PrimaryLayout },
      component: JoinEventView,
      beforeEnter: authGuard
    },

    // External Routes
    {
      path: '/login',
      name: ROUTES.LOGIN,
      meta: { layout: AuthLayout },
      component: () => import('../views/LoginView.vue'),
      beforeEnter: () => {
        if (auth.currentUser) {
          return { name: ROUTES.HOME }
        }
      }
    },
    {
      path: '/registration',
      name: ROUTES.REGISTRATION,
      meta: { layout: AuthLayout },
      component: () => import('../views/RegistrationView.vue'),
      beforeEnter: () => {
        if (auth.currentUser) {
          return { name: ROUTES.HOME }
        }
      }
    },
    {
      path: '/forgot-password',
      name: ROUTES.FORGOT_PASSWORD,
      meta: { layout: AuthLayout },
      component: () => import('../views/ForgotPasswordView.vue'),
      beforeEnter: () => {
        if (auth.currentUser) {
          return { name: ROUTES.HOME }
        }
      }
    }
  ]
})

export default router
