export const lvTranslations = {
  event: {
    title: 'Pasākums',
    addPhoto: 'Pievienojiet fotoattēlu',
    noPhotos: 'Pašlaik šim notikumam nav pievienots neviens fotoattēls.',
    addPhotos: 'Uz priekšu - pievienojiet dažus!',
    statusActive: 'Aktīvs',
    statusEnded: 'Beigās',
    statusNotStarted: 'Nav sākts',
    participants: 'Dalībnieki',
    gridView: 'Režģa skats',
    listView: 'Saraksta skats',
    eventDetails: 'Pasākuma informācija',
    memberTypeOwner: 'īpašnieks',
    memberTypeMember: 'biedrs',
    memberTypeViewer: 'skatītājs',
    deletePhoto: 'Vai dzēst fotoattēlu?',
    deletePhotoConfirm: 'Vai tiešām vēlaties dzēst šo fotoattēlu?',
    delete: 'Dzēst',
    uploadPhotos: 'Augšupielādējiet fotoattēlus',
    dropPhotosHere: 'Nometiet fotoattēlus šeit',
    or: 'Or',
    selectPhotos: 'Izvēlieties fotoattēlus',
    caption: 'Paraksts',
    photoTakenAt: 'Fotoattēls uzņemts plkst',
    removePhoto: 'Noņemt fotoattēlu',
    addMorePhotos: 'Pievienojiet vairāk fotoattēlu',
    uploading: 'Notiek augšupielāde...',
    inviteParticipants: 'Uzaiciniet dalībniekus',
    editEvent: 'Rediģēt notikumu',
    copyLink: 'Kopēt saiti',
    addParticipantsWithLink:
      'Lai uzaicinātu dalībniekus uz savu pasākumu, lūdzu, kopīgojiet ar viņiem šo saiti:',
    addParticipantsWithQrCode: 'Vai arī ļaujiet viņiem skenēt tālāk norādīto QR kodu:',
    makeOwner: 'Padarīt īpašnieku',
    makeMember: 'Padarīt biedru',
    makeViewer: 'Padarīt skatītāju',
    removeMember: 'Noņemt dalībnieku',
    removeMemberConfirm: 'Vai tiešām vēlaties noņemt šo dalībnieku?',
    saveEvent: 'Saglabāt notikumu',
    postPhotosNotificationTitle: 'Ievietojiet pasākumā jaunas fotogrāfijas!',
    postPhotosNotificationBody:
      'Ir pienācis laiks publicēt jaunākos fotoattēlus pakalpojumā {eventName}!'
  },
  events: {
    title: 'Pasākumi',
    createEvent: 'Izveidot notikumu',
    joinEvent: 'Pievienojies pasākumam',
    joinEventCopy:
      'Lai pievienotos esošam notikumam, lūdzu, palūdziet notikuma īpašniekam kopīgot ar jums saiti vai parādīt QR kodu.',
    joiningEventCopy: 'Mēs darām dažus burvjus, lai pievienotu jūs pasākumam. ',
    eventTitle: 'Nosaukums',
    description: 'Apraksts',
    duration: 'Ilgums',
    coverImage: 'Vāka attēls',
    generalSettings: 'Vispārīgie iestatījumi',
    notificationSettings: 'Paziņojumu iestatījumi',
    notificationSettingPostNewPhotos: 'Saņemiet paziņojumu par jaunu attēlu ievietošanu',
    notificationSettingPostedPicturesMembers:
      'Dalībniekiem jāsaņem paziņojums, kad tiek publicēti jauni attēli',
    notificationSettingPostedPicturesViewers:
      'Skatītājiem jāsaņem paziņojums, kad tiek publicēti jauni attēli',
    never: 'Nekad',
    immediate: 'Tūlītēja',
    daily: 'Ikdienas',
    weekly: 'Iknedēļas',
    monthly: 'Ikmēneša',
    selectCoverImage: 'Izvēlieties vāka attēlu',
    removeCoverImage: 'Noņemiet vāka attēlu',
    savingEvent: 'Notiek pasākuma saglabāšana...',
    noEvents: 'Jums pašlaik nav notikumu...',
    addEvent: 'Neuztraucieties — vienkārši izveidojiet vai pievienojieties pirmajam!',
    noEventsOrders: 'Pašlaik jums nav neviena pasākuma, kuram varētu pasūtīt albumus...',
    addEventOrders: 'Neuztraucieties — vienkārši izveidojiet vai pievienojieties pirmajam! ',
    goToEventsPage: 'Dodieties uz notikumu lapu'
  },
  menu: {
    events: 'Pasākumi',
    profile: 'Profils',
    orders: 'Pasūtījumi',
    settings: 'Iestatījumi',
    logOut: 'Izlogoties'
  },
  login: {
    forgotPassword: 'Aizmirsi paroli',
    joinNow: 'Pievienojies tagad',
    signIn: 'Ielogoties',
    resetPassword: 'Atiestatīt paroli',
    noAccount: 'Nav konta?',
    email: 'E-pasts',
    password: 'Parole',
    invalidCredentials: 'Nederīgi akreditācijas dati',
    name: 'Vārds',
    retypePassword: 'Atkārtoti ierakstiet paroli',
    logIn: 'Pieslēgties',
    register: 'Reģistrēties',
    alreadyHaveAccount: 'Jau ir konts?',
    resetPasswordSentCopy: 'Jūs saņemsit e-pastu ar norādījumiem paroles atiestatīšanai.',
    backToLoginCopy: 'Atgriezties uz pieteikšanās lapu',
    errors: {
      noName: 'Lūdzu, ievadiet savu vārdu',
      noPassword: 'Parolei jābūt vismaz 8 simbolu garai',
      noRetypePassword: 'Lūdzu, ievadiet savu paroli vēlreiz',
      passwordsDoNotMatch: 'Paroles nesakrīt',
      invalidEmail: 'Lūdzu ievadiet derīgu e-pasta adresi',
      emailAlreadyInUse: 'Šis e-pasts jau tiek izmantots',
      agreeWithTerms: 'Jums jāpiekrīt noteikumiem un nosacījumiem'
    }
  },
  orders: {
    title: 'Pasūtījumi',
    newOrder: 'Jauns pasūtījums',
    noOrders: 'Jums vēl nav neviena pasūtījuma...',
    addOrder: 'Uz priekšu, izveidojiet savu pirmo!',
    pickEventCopy: 'Lai sāktu veidot jaunu fotoalbumu, lūdzu, izvēlieties notikumu:',
    pickBookTypeCopy: 'Lūdzu, atlasiet fotoalbuma veidu, kuru vēlaties izveidot:',
    back: 'Atpakaļ',
    'ordersBookSize-1-square': '20cm x 20cm',
    'ordersBookSize-2-square': '28cm x 28cm',
    'coverType-hard': 'Cietais vāks',
    'coverType-soft': 'Mīksts vāks',
    'pageType-front-cover': 'Priekšējais vāks',
    'pageType-back-cover': 'Aizmugurējais vāciņš',
    'pageType-front-endsheet': 'Priekšējā beigu lapa',
    'pageType-back-endsheet': 'Aizmugurējā beigu lapa',
    pageCount: 'Lapu skaits',
    pagesIncluded: 'Lapas iekļautas bāzes cenā',
    startingFrom: 'Sākot no',
    cover: 'Piesegt',
    pricePerExtraSpread: 'Cena par papildu izklājumu',
    cantPickPhotoCopy: 'Šai lapai fotoattēlu nevar izvēlēties',
    changePhoto: 'Mainīt bildi',
    addSpread: 'Pievienot atvērumu',
    removePage: 'Noņemt lapu',
    addBlankPage: 'Pievienojiet tukšu lapu',
    pageInformation: 'Lapas informācija',
    selectedPage: 'Atlasītā lapa',
    price: 'Cena',
    continue: 'Turpināt',
    currentPicture: 'Atlasītā lapa',
    createdAt: 'Pasūtījums veikts plkst',
    albumSize: 'Albuma izmērs',
    'status-pending': 'Gaida',
    'status-paid': 'Apmaksāts',
    'status-printing': 'Drukāšana',
    'status-completed': 'Pabeigts',
    'status-invoice-sent': 'Rēķins nosūtīts',
    'status-canceled': 'Atcelts'
  },
  newOrder: {
    title: 'Izveidot jaunu pasūtījumu',
    albumInformation: 'Informācija par albumu',
    pageSize: 'Lapas izmērs',
    cover: 'Piesegt',
    hardCover: 'Cietais vāks',
    softCover: 'Mīksts vāks',
    numberOfPages: 'Lapu skaits',
    showPageNumber: 'Rādīt lappušu numurus',
    showPhotoDate: 'Rādīt datumu lapā',
    vat: 'PVN',
    total: 'Kopā',
    shippingPrice: 'Piegāde',
    priceExclVat: 'Cena bez ',
    showPhotoCaptions: 'Rādīt parakstus',
    placeOrder: 'Pasūtīt',
    shipping: 'Piegāde',
    shippingPriceExplanation:
      'Pirms izsūtīsim rēķinu mēs ar jums sazināsimies par piegādes izmaksām.'
  },
  address: {
    name: 'Vārds',
    address: 'Adrese',
    address2: 'adrese 2',
    city: 'Pilsēta',
    zipCode: 'Pasta indekss',
    country: 'Valsts',
    phone: 'Tālrunis',
    state: 'Valsts',
    shippingAddress: 'Piegādes adrese',
    shippingAddressSameAsBillingAddress: 'Norēķinu adrese ir tāda pati kā piegādes adrese',
    billingAddress: 'Norēķinu adrese'
  },
  newOrderSuccessful: {
    title: 'Pasūtījums veikts',
    copy: 'Paldies, ka izveidojāt brīnišķīgu fotoalbumu! ',
    cta: 'Dodieties uz pasūtījumu sadaļu'
  },
  errors: {
    imageFile: {
      imageFile: {
        file: 'Failam ir jābūt attēlam un 20 MB vai mazākam'
      }
    },
    coverImageFile: {
      coverImageFile: {
        file: 'Failam ir jābūt attēlam un 20 MB vai mazākam'
      }
    },
    avatarFile: {
      avatarFile: {
        file: 'Iemiesojums ir attēls un 20 MB vai mazāks'
      }
    },
    name: {
      string: 'Virsrakstam ir jābūt tekstam',
      required: 'Nosaukums ir nepieciešams'
    },
    description: {
      string: 'Aprakstam ir jābūt tekstam'
    },
    email: {
      unique: 'Šī e-pasta adrese jau ir aizņemta',
      email: 'Lūdzu, ievadiet derīgu e-pasta adresi',
      required: 'Nepieciešams e-pasts'
    }
  },
  settings: {
    title: 'Iestatījumi',
    general: 'Ģenerālis',
    notifications: 'Paziņojumi',
    password: 'Parole',
    name: 'Vārds',
    email: 'E-pasts',
    avatar: 'Iemiesojums',
    save: 'Saglabāt',
    saving: 'Notiek saglabāšana...',
    passwordChanged: 'Parole mainīta',
    passwordChangedCopy: 'Jūsu parole ir veiksmīgi nomainīta.',
    oldPassword: 'Vecā parole',
    newPassword: 'Jauna parole',
    retypeNewPassword: 'Ievadiet jauno paroli vēlreiz',
    changePassword: 'Mainiet paroli',
    subscription: 'Abonēšana',
    pickAvatar: 'Izvēlieties iemiesojumu',
    removeAvatar: 'Noņemt',
    language: 'Valoda'
  },
  subscription: {
    freeWhileInBeta: 'Visi līmeņi ir bezmaksas, kamēr mēs esam beta testēšanas fāzē. ',
    free: 'Bezmaksas',
    basic: 'Pamata',
    professional: 'Profesionāls',
    currentTier: 'Pašreizējais līmenis',
    comingSoon: 'Drīzumā',
    select: 'Izvēlieties',
    freeTrialExpiresAt: 'Bezmaksas izmēģinājuma periods beidzas plkst',
    subscriptionRenewsAt: 'Abonementu atjauno plkst',
    benefitJoinEvents:
      'Jūs varat pievienoties neierobežotam skaitam pasākumu un ievietot tajos attēlus',
    benefitOrderAlbums:
      'Jūs varat pasūtīt neierobežotu skaitu albumu pasākumiem, kuros piedalāties',
    benefitOrderAlbumsPro: 'Fotoalbumus iespējams pasūtīt ar 10% atlaidi',
    benefitCreateEvents: 'Jūs varat izveidot neierobežotu skaitu notikumu',
    benefitCreatePublicEvents:
      'Izveidojiet "publiskus" pasākumus, kuriem cilvēki var pievienoties, neizveidojot kontu (Drīzumā!)',
    enableNotifications: 'Paziņojumu kanāli',
    notificationViaEmail: 'Pa e-pastu',
    notificationViaPush: 'Izmantojot paziņojumu lietotnē (push paziņojumu)'
  },
  more: 'Vairāk',
  less: 'mazāk',
  close: 'Aizvērt',
  cancel: 'Atcelt',
  delete: 'Dzēst',
  save: 'Saglabāt',
  install: 'Uzstādīt',
  dismiss: 'Atlaist',
  installPwaCopy:
    'Varat instalēt lietotni Twibie savā sākuma ekrānā, lai ātri, ērti un bezsaistē piekļūtu, kad atrodaties ceļā. ',
  generalError: 'Kaut kas nogāja greizi. '
}
