export const base64EncodeFile = async (file) => {
  const reader = new FileReader()

  return new Promise((resolve, reject) => {
    reader.readAsDataURL(file)
    reader.onload = function () {
      resolve(reader.result.split('base64,')[1]) // need to remove the image type prefix
    }
    reader.onerror = function (error) {
      reject(error)
    }
  })
}
