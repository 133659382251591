import { Address } from '@/models/Address'

export class OrderAddresses {
  constructor({
    billingSameAsShipping = true,
    shippingAddress = new Address(),
    billingAddress = new Address()
  } = {}) {
    this.billingSameAsShipping = billingSameAsShipping
    this.shippingAddress = shippingAddress
    this.billingAddress = billingAddress
  }
}
