import {
  BookPage,
  PAGE_TYPE_BACK_COVER,
  PAGE_TYPE_FRONT_COVER,
  PAGE_TYPE_NORMAL
} from '@/models/BookPage'
import { generateUUID } from './UuidGenerator'

const MIN_NUMBER_OF_PAGES_TO_ADD = 4
const NUMBER_OF_PAGES_PER_SPREAD = 2

export class BookCreationService {
  static createBook(eventPictures, bookSize) {
    let numberOfPages = bookSize.includedPages
    if (eventPictures.length > numberOfPages) {
      numberOfPages = eventPictures.length
    }

    if (numberOfPages % MIN_NUMBER_OF_PAGES_TO_ADD !== 0) {
      numberOfPages += MIN_NUMBER_OF_PAGES_TO_ADD - (numberOfPages % MIN_NUMBER_OF_PAGES_TO_ADD)
    }

    let spreads = []
    for (let i = 0; i < numberOfPages; i++) {
      const newPage = new BookPage({
        uid: generateUUID(),
        pageNumber: i + 1,
        photo: eventPictures[i] ?? null,
        showCaption: false,
        showDate: false,
        showPageNumber: false,
        pageType: PAGE_TYPE_NORMAL,
        caption: eventPictures[i]?.caption || '',
        date: eventPictures[i]?.photoTakenAt
      })
      spreads = BookCreationService._addPageToLastSpread(spreads, newPage)
    }

    const frontCover = new BookPage({
      uid: generateUUID(),
      pageNumber: 0,
      photo: eventPictures[0] || null,
      showCaption: false,
      showDate: false,
      showPageNumber: false,
      pageType: PAGE_TYPE_FRONT_COVER
    })
    const backCover = new BookPage({
      uid: generateUUID(),
      pageNumber: 0,
      photo: null,
      showCaption: false,
      showDate: false,
      showPageNumber: false,
      pageType: PAGE_TYPE_BACK_COVER
    })

    return [[frontCover], ...spreads, [backCover]]
  }

  static removePage(spreads, removePageUid) {
    let numberOfPages = 0
    const frontCover = spreads[0][0]
    const backCover = spreads[spreads.length - 1][0]

    const pages = spreads.flat()
    pages.splice(0, 1) // remove front cover
    pages.splice(pages.length - 1, 1) // remove back cover
    let newSpreads = [[frontCover], []] // add empty array otherwise page will be added to front cover spread

    for (const pageIndex in pages) {
      const page = pages[pageIndex]
      if (page.uid === removePageUid || !page.photo) {
        continue
      }
      page.pageNumber = numberOfPages + 1
      newSpreads = BookCreationService._addPageToLastSpread(newSpreads, page)
      numberOfPages += 1
    }

    if (numberOfPages % MIN_NUMBER_OF_PAGES_TO_ADD !== 0) {
      const pagesToAdd = MIN_NUMBER_OF_PAGES_TO_ADD - (numberOfPages % MIN_NUMBER_OF_PAGES_TO_ADD)
      for (let i = 0; i < pagesToAdd; i++) {
        const newPage = new BookPage({
          uid: generateUUID(),
          pageNumber: numberOfPages + 1,
          photo: null,
          showCaption: false,
          showDate: false,
          showPageNumber: false,
          pageType: PAGE_TYPE_NORMAL
        })
        newSpreads = BookCreationService._addPageToLastSpread(newSpreads, newPage)
        numberOfPages += 1
      }
    }
    newSpreads.push([backCover])

    return newSpreads
  }

  static addSpread(spreads) {
    let numberOfPages = 0
    const pages = spreads.flat()
    const frontCover = pages[0]
    const backCover = pages[pages.length - 1]
    pages.splice(0, 1) // remove front cover
    pages.splice(pages.length - 1, 1) // remove back cover
    let newSpreads = [[frontCover], []] // add empty array otherwise page will be added to front cover spread

    for (const pageIndex in pages) {
      const page = pages[pageIndex]
      page.pageNumber = numberOfPages + 1
      newSpreads = BookCreationService._addPageToLastSpread(newSpreads, page)
      numberOfPages += 1
    }

    for (let i = 0; i < MIN_NUMBER_OF_PAGES_TO_ADD; i++) {
      const newPage = new BookPage({
        uid: generateUUID(),
        pageNumber: numberOfPages + 1,
        photo: null,
        showCaption: false,
        showDate: false,
        showPageNumber: false,
        pageType: PAGE_TYPE_NORMAL
      })
      newSpreads = BookCreationService._addPageToLastSpread(newSpreads, newPage)
      numberOfPages += 1
    }

    newSpreads.push([backCover])

    return newSpreads
  }

  static _addPageToLastSpread(spreads, page) {
    const newSpreads = [...spreads]
    const lastSpreadIndex = newSpreads.length === 0 ? 0 : newSpreads.length - 1
    const lastSpread = newSpreads[lastSpreadIndex]
    if (!lastSpread || lastSpread.length === NUMBER_OF_PAGES_PER_SPREAD) {
      newSpreads.push([page])
    } else {
      newSpreads[lastSpreadIndex].push(page)
    }

    return newSpreads
  }
}
