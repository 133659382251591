<script setup>
import { useBookDesignerState } from '@/stores/BookDesigner'
import { storeToRefs } from 'pinia'
import TwModal from '@/components/ui/TwModal.vue'
import { ref } from 'vue'
import TwButton from '@/components/ui/TwButton.vue'

const designerState = useBookDesignerState()
const { availablePhotos, currentSelectedPage } = storeToRefs(designerState)
const loading = ref(true)
const selectedPage = ref(currentSelectedPage.value)
const selectedPhoto = ref(currentSelectedPage.value.photo)

designerState.$subscribe((mutation, state) => {
  if (state.currentSelectedPage.uid !== selectedPage.value.uid) {
    selectedPage.value = state.currentSelectedPage
    selectedPhoto.value = state.currentSelectedPage.photo
  }
})
const loadPhotos = () => {
  loading.value = true
  designerState.loadAvailablePhotos()
  loading.value = false
}

const selectEventPicture = (eventPicture) => {
  selectedPhoto.value = eventPicture
}

const changePhoto = () => {
  designerState.changePhoto(selectedPhoto.value)
}

loadPhotos()
</script>

<template>
  <TwModal id="change-photo-modal" :dynamic-height="true" :title="$t('orders.changePhoto')">
    <div v-if="!loading">
      <div class="grid gap-2 md:gap-3 grid-cols-3 mt-2">
        <div
          v-for="eventPicture in availablePhotos"
          :key="`change-photo-${eventPicture.id}`"
          class="lg:hover:scale-105 hover:shadow-lg hover:z-10 duration-500 delay-100 cursor-pointer rounded-lg relative"
          :class="{
            'm-3': selectedPhoto?.id === eventPicture.id
          }"
          @click="selectEventPicture(eventPicture)"
        >
          <ion-icon
            v-if="selectedPhoto?.id === eventPicture.id"
            name="checkmark-circle-outline"
            class="text-green-500 text-2xl absolute -top-3 -left-3 z-10"
          />
          <div class="relative overflow-hidden rounded-lg uk-transition-toggle">
            <div class="relative w-full h-full aspect-[3/3]">
              <img
                :src="eventPicture.photo.thumbnailUrl"
                alt=""
                class="object-cover w-full h-full"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="w-80 bg-slate-200/60 rounded-lg animate-pulse h-6"></div>
      <div class="grid md:gap-3 grid-cols-3 gap-2 mt-2">
        <div
          class="w-full lg:h-60 h-full aspect-[3/3] bg-slate-200/60 rounded-lg animate-pulse"
        ></div>
        <div
          class="w-full lg:h-60 h-full aspect-[3/3] bg-slate-200/60 rounded-lg animate-pulse"
        ></div>
        <div
          class="w-full lg:h-60 h-full aspect-[3/3] bg-slate-200/60 rounded-lg animate-pulse"
        ></div>
        <div
          class="w-full lg:h-60 h-full aspect-[3/3] bg-slate-200/60 rounded-lg animate-pulse"
        ></div>
      </div>
    </div>
    <template #actions>
      <TwButton
        class="uk-modal-close"
        color="bg-primary text-white"
        rounded="rounded-md"
        @click="changePhoto"
      >
        {{ $t('orders.changePhoto') }}
      </TwButton>
    </template>
  </TwModal>
</template>
