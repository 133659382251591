import { formatToHumanReadable } from '@/services/DateTimeFormatter'

export const PAGE_TYPE_FRONT_COVER = 'front-cover'
export const PAGE_TYPE_BACK_COVER = 'back-cover'
export const PAGE_TYPE_NORMAL = 'normal'
export const PAGES_WITH_NO_IMAGE = [PAGE_TYPE_BACK_COVER]
export const PAGES_WITH_NUMBERS = [PAGE_TYPE_NORMAL]

export const DEFAULT_DPI = 90
export const INCH_TO_MM = 25.4
export class BookPage {
  misc = {
    title: null
  }

  constructor({
    uid = 0,
    pageNumber = 0,
    photo = null,
    showCaption = false,
    showDate = false,
    showPageNumber = false,
    pageType = '',
    caption = '',
    date = null
  } = {}) {
    this.uid = uid
    this.pageNumber = pageNumber
    this.photo = photo
    this.showCaption = showCaption
    this.showDate = showDate
    this.showPageNumber = showPageNumber
    this.pageType = pageType
    this.caption = caption
    this.date = date
    this.dateHumanReadable = date ? formatToHumanReadable(date) : ''
  }

  canHavePhoto() {
    return !PAGES_WITH_NO_IMAGE.includes(this.pageType)
  }

  isNormalPage() {
    return PAGES_WITH_NUMBERS.includes(this.pageType)
  }

  isBackCover() {
    return this.pageType === PAGE_TYPE_BACK_COVER
  }
  isFrontCover() {
    return this.pageType === PAGE_TYPE_FRONT_COVER
  }

  setCoverTitle(title) {
    this.misc.frontCoverTitle = title
  }
}
