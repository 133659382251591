import { createI18n } from 'vue-i18n'
import { translations } from '@/config/Translations/Translations'

export const DEFAULT_LOCALE = 'en'
const KEY_CURRENT_LOCALE = 'currentLocale'

export const LOCALE_LV = 'lv'
export const LOCALE_EN = 'en'

export const AVAILABLE_LOCALES = [
  {
    label: 'English',
    locale: LOCALE_EN
  },
  {
    label: 'Latviešu',
    locale: LOCALE_LV
  }
]

export const VALID_LOCALES = AVAILABLE_LOCALES.map((locale) => locale.locale)

export class I18n {
  static i18n = null
  static locale = DEFAULT_LOCALE

  static init() {
    const storedLocale = I18n.getLocale()
    this.locale = storedLocale
    this.i18n = createI18n({
      locale: storedLocale,
      fallbackLocale: DEFAULT_LOCALE,
      messages: translations
    })

    return this.i18n
  }

  static switchLocale(locale) {
    this.locale = locale
    localStorage.setItem(KEY_CURRENT_LOCALE, locale)
    this.i18n.global.locale = locale
  }

  static getLocale() {
    let storedLocale = localStorage.getItem(KEY_CURRENT_LOCALE)
    if (!storedLocale) {
      storedLocale = DEFAULT_LOCALE
      localStorage.setItem(KEY_CURRENT_LOCALE, storedLocale)
    }

    return storedLocale
  }

  static translate(key, keyParams) {
    return this.i18n.global.t(key, keyParams)
  }
}
