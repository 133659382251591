export class UpdatableUser {
  constructor({
    id = 0,
    avatarUrl = '',
    name = '',
    email = '',
    locale = '',
    subscriptionPlan = '',
    subscriptionPlanPaymentFrequency = '',
    emailNotificationsEnabled = false,
    pushNotificationsEnabled = false
  } = {}) {
    this.id = id
    this.avatarUrl = avatarUrl
    this.name = name
    this.email = email
    this.locale = locale
    this.subscriptionPlan = subscriptionPlan
    this.subscriptionPlanPaymentFrequency = subscriptionPlanPaymentFrequency
    this.emailNotificationsEnabled = emailNotificationsEnabled
    this.pushNotificationsEnabled = pushNotificationsEnabled
    this.avatarFile = null
    this.avatarFileName = null
    this.shouldRemoveAvatar = false
  }

  static fromUser(user) {
    return new UpdatableUser({
      id: user.id,
      avatarUrl: user.avatarUrl,
      name: user.name,
      email: user.email,
      locale: user.locale,
      subscriptionPlan: user.subscriptionPlan,
      subscriptionPlanPaymentFrequency: user.subscriptionPlanPaymentFrequency,
      emailNotificationsEnabled: user.emailNotificationsEnabled,
      pushNotificationsEnable: user.pushNotificationsEnabled
    })
  }

  toJson() {
    return {
      name: this.name,
      email: this.email,
      locale: this.locale,
      subscriptionPlan: this.subscriptionPlan,
      subscriptionPlanPaymentFrequency: this.subscriptionPlanPaymentFrequency,
      emailNotificationsEnabled: this.emailNotificationsEnabled,
      pushNotificationsEnable: this.pushNotificationsEnabled,
      avatarFile: this.avatarFile,
      avatarFileName: this.avatarFileName,
      shouldRemoveAvatar: this.shouldRemoveAvatar
    }
  }
}
