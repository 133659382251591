<script setup>
import { BookPage, DEFAULT_DPI, INCH_TO_MM } from '@/models/BookPage'
import { useBookDesignerState } from '@/stores/BookDesigner'
import { computed, ref, toRaw } from 'vue'
import { storeToRefs } from 'pinia'
const FULL_SIZE = 100

const designerState = useBookDesignerState()
const { bookType, albumTitle } = storeToRefs(designerState)
const props = defineProps({
  page: {
    type: BookPage,
    required: true
  },
  pageIndex: {
    type: Number,
    required: true
  }
})
const zoomFactor = ref(1)

const switchPage = () => {
  designerState.openPage(toRaw(props.page))
}

const maxHeightInPx = computed(() => {
  if (!props.page.photo) {
    return FULL_SIZE
  }
  // convert page size to px considering dpi
  const photoHeightInInches = props.page.photo.photo.originalHeight / DEFAULT_DPI
  const pageHeightInInches = (bookType.value.pageHeight * 10) / INCH_TO_MM
  if (photoHeightInInches > pageHeightInInches) {
    return FULL_SIZE * zoomFactor.value
  }

  return (photoHeightInInches / pageHeightInInches) * 100 * zoomFactor.value
})

const maxWidthInPx = computed(() => {
  if (!props.page.photo) {
    return FULL_SIZE
  }
  // convert page size to px considering dpi
  const photoWidthInInches = props.page.photo.photo.originalWidth / DEFAULT_DPI
  const pageWidthInInches = (bookType.value.pageWidth * 10) / INCH_TO_MM
  if (photoWidthInInches > pageWidthInInches) {
    return FULL_SIZE * zoomFactor.value
  }

  return (photoWidthInInches / pageWidthInInches) * 100 * zoomFactor.value
})
</script>

<template>
  <div class="flex flex-col items-center gap-2 w-1/2">
    <div
      class="border border-slate-300 flex justify-center items-center w-full overflow-hidden cursor-pointer"
      :style="{ aspectRatio: `${bookType.pageWidthPx}/${bookType.pageHeightPx}` }"
      @click="switchPage"
    >
      <div
        class="new-order_book-designer-page flex flex-col justify-center items-center h-full w-full overflow-hidden relative"
      >
        <div
          class="grow w-full new-order_book-designer-image flex items-center justify-center overflow-hidden"
        >
          <img
            v-if="page?.photo?.photo.thumbnailUrl"
            :src="page?.photo?.photo.thumbnailUrl"
            alt="Photo"
            :style="{ maxHeight: `${maxHeightInPx}%`, maxWidth: `${maxWidthInPx}%` }"
          />
        </div>
        <div
          class="absolute new-order_book-designer-title text-center"
          :style="{
            color: albumTitle.color,
            fontSize: `${albumTitle.size}%`,
            marginTop: `${albumTitle.verticalOffset}%`
          }"
        >
          {{ albumTitle.title }}
        </div>
      </div>
    </div>
    <div class="text-slate-300">
      {{ $t(`orders.pageType-${page.pageType}`) }}
    </div>
  </div>
</template>

<style scoped>
.new-order_book-designer-page {
  padding: 1%;
}
.new-order_book-designer-image {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}
.new-order_book-designer-title {
  line-height: 100%;
}
</style>
