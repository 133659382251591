import { User } from './User'

export class EventMember {
  constructor({ id = 0, user = {}, role = '', notificationsEnabled = false } = {}) {
    this.id = id
    this.user = new User(user)
    this.role = role
    this.notificationsEnabled = notificationsEnabled
  }

  get isOwner() {
    return this.role === 'owner'
  }

  get isMember() {
    return this.role === 'member'
  }

  get canPostPhotos() {
    return this.isOwner || this.isMember
  }
}
