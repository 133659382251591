<script setup>
import TwCard from '@/components/ui/TwCard.vue'
import { ROUTES } from '@/router/Routes'
import { useRouter } from 'vue-router'
import TwPageTitle from '@/components/ui/TwPageTitle.vue'
import TwButton from '@/components/ui/TwButton.vue'

const router = useRouter()

const goToOrders = () => {
  router.push({
    name: ROUTES.ORDERS
  })
}
</script>
<template>
  <TwPageTitle>
    {{ $t('newOrderSuccessful.title') }}
  </TwPageTitle>
  <div id="js-oversized" class="mt-4 md:mt-10">
    <TwCard class="p-5">
      <div class="flex flex-col items-center">
        <div class="text-green-500 big-icon">
          <ion-icon name="checkmark-circle-outline"></ion-icon>
        </div>
        <div class="text-xl mt-4">
          {{ $t('newOrderSuccessful.copy') }}
        </div>

        <TwButton class="bg-primary text-white mt-8" rounded="rounded-md" @click="goToOrders">
          {{ $t('newOrderSuccessful.cta') }}
        </TwButton>
      </div>
    </TwCard>
  </div>
</template>

<style scoped>
.big-icon {
  font-size: 5rem;
}
</style>
