<script setup>
import { EVENT_PICTURE_VIEW_GRID } from '@/config/EventPictureViewtypes'
import EventPictureDateGroup from './EventPictureDateGroup.vue'
import { computed } from 'vue'
import { EventPictureDateGroupFactory } from '@/services/EventPictureDateGroupFactory'
import { useEventStore } from '@/stores/Event'
import { storeToRefs } from 'pinia'
import TwButton from '@/components/ui/TwButton.vue'
import EventPictureModal from '@/components/event/EventPictureModal.vue'

const eventStore = useEventStore()
const { eventPictures, eventPicturesLoading, currentViewMode, event } = storeToRefs(eventStore)
const eventPictureDateGroups = computed(() =>
  EventPictureDateGroupFactory.createFromEventPictureArray(eventPictures.value)
)
</script>

<template>
  <div class="lg:max-w-[610px] flex-1 xl:space-y-6 space-y-3">
    <template v-if="eventPictureDateGroups.length === 0 && !eventPicturesLoading">
      <div class="w-full text-center flex items-center flex-col gap-2">
        <p>{{ $t('event.noPhotos') }}</p>
        <div v-if="event && event.selfUser.canPostPhotos" class="flex flex-col items-center gap-2">
          <p>{{ $t('event.addPhotos') }}</p>
          <TwButton
            color="bg-primary text-white"
            rounded="rounded-md"
            uk-toggle="target: #add-photo"
          >
            {{ $t('event.addPhoto') }}
          </TwButton>
        </div>
      </div>
    </template>
    <EventPictureDateGroup
      v-for="dateGroup in eventPictureDateGroups"
      v-else
      :key="`date-group-${dateGroup.uid}`"
      :event-picture-date-group="dateGroup"
    />
    <template v-if="eventPicturesLoading">
      <template v-if="currentViewMode === EVENT_PICTURE_VIEW_GRID">
        <div class="w-80 bg-slate-200/60 rounded-lg animate-pulse h-6"></div>
        <div class="grid md:gap-3 grid-cols-3 gap-2 mt-2">
          <div
            class="w-full lg:h-60 h-full aspect-[3/3] bg-slate-200/60 rounded-lg animate-pulse"
          ></div>
          <div
            class="w-full lg:h-60 h-full aspect-[3/3] bg-slate-200/60 rounded-lg animate-pulse"
          ></div>
          <div
            class="w-full lg:h-60 h-full aspect-[3/3] bg-slate-200/60 rounded-lg animate-pulse"
          ></div>
          <div
            class="w-full lg:h-60 h-full aspect-[3/3] bg-slate-200/60 rounded-lg animate-pulse"
          ></div>
        </div>
      </template>
      <template v-else>
        <div class="w-56 bg-slate-200/60 rounded-lg animate-pulse h-4 mb-2"></div>
        <div class="w-full bg-slate-200/60 rounded-lg animate-pulse p-2.5">
          <div class="flex justify-between">
            <div class="flex">
              <div class="bg-slate-300/60 rounded-full animate-pulse h-9 w-9"></div>
              <div class="flex-col ml-2">
                <div class="bg-slate-300/60 rounded-lg animate-pulse h-4 w-32 mb-1"></div>
                <div class="bg-slate-300/60 rounded-lg animate-pulse h-4 w-20"></div>
              </div>
            </div>
            <div class="flex items-center">
              <div class="bg-slate-300/60 rounded-lg animate-pulse h-6 w-6"></div>
            </div>
          </div>
          <div class="w-full bg-slate-300/60 rounded-lg animate-pulse h-96 mt-2"></div>
          <div class="bg-slate-300/60 rounded-lg animate-pulse h-4 w-40 mt-2"></div>
        </div>
      </template>
    </template>
    <EventPictureModal />
  </div>
</template>
