import { BookSize } from '@/models/BookSize'

export const BOOK_SIZE_1_SQUARE = '1-square'
export const BOOK_SIZE_1_ALBUM = '1-landscape'
export const BOOK_SIZE_2_SQUARE = '2-square'
export const BOOK_SIZE_2_ALBUM = '2-landscape'

export const COVER_SOFT = 'soft'
export const COVER_HARD = 'hard'
export const BOOK_SIZES = {
  [BOOK_SIZE_1_SQUARE]: new BookSize({
    sizeName: BOOK_SIZE_1_SQUARE,
    pageHeight: 20,
    pageWidth: 20,
    pageHeightPx: 236,
    pageWidthPx: 236,
    cover: COVER_HARD,
    basePrice: 28,
    pricePerExtraSpread: 0.5,
    includedPages: 24,
    maxPages: 100
  }),
  [BOOK_SIZE_2_SQUARE]: new BookSize({
    sizeName: BOOK_SIZE_2_SQUARE,
    pageHeight: 28,
    pageWidth: 28,
    pageHeightPx: 236,
    pageWidthPx: 236,
    cover: COVER_HARD,
    basePrice: 35,
    pricePerExtraSpread: 0.5,
    includedPages: 24,
    maxPages: 100
  })
  // [BOOK_SIZE_2_ALBUM]: {
  //   pageHeight: 24,
  //   pageWidth: 30,
  //   pageHeightPx: 177,
  //   pageWidthPx: 236,
  //   cover: COVER_HARD
  // },
  // [BOOK_SIZE_1_ALBUM]: {
  //   pageHeight: 18,
  //   pageWidth: 24,
  //   pageHeightPx: 150,
  //   pageWidthPx: 236,
  //   cover: COVER_SOFT
  // },
}
