<script setup>
import { EventFull } from '@/models/EventFull'
import TwBadge from '../ui/TwBadge.vue'
import { formatToHumanReadable } from '@/services/DateTimeFormatter'
import EventMembers from './EventMembers.vue'
import ManageEventModal from '@/components/event/ManageEventModal.vue'
import TwButton from '@/components/ui/TwButton.vue'
import { useEventStore } from '@/stores/Event'

const { loadEvent } = useEventStore()
const props = defineProps({
  event: {
    type: EventFull,
    required: true
  }
})

const getStatusBgClassName = () => {
  if (props.event.hasEnded) {
    return 'bg-red-100'
  }
  if (props.event.hasStarted) {
    return 'bg-green-100'
  }

  return 'bg-slate-200'
}
const getStatusName = () => {
  if (props.event.hasEnded) {
    return 'event.statusEnded'
  }
  if (props.event.hasStarted) {
    return 'event.statusActive'
  }

  return 'event.statusNotStarted'
}
</script>

<template>
  <div class="space-y-3.5 text-xs font-normal mb-2 text-gray-600">
    <div class="flex-col">
      <h4 class="font-medium text-slate-900 text-sm">{{ event.name }}</h4>
      <p>{{ event.description }}</p>
      <div class="flex">
        <TwBadge class="bg-sky-100 mt-2">
          <template v-if="event.startAt.toLocaleString() === event.endAt.toLocaleString()">
            {{ formatToHumanReadable(event.startAt) }}
          </template>
          <template v-else>
            {{ formatToHumanReadable(event.startAt) }}
            -
            {{ formatToHumanReadable(event.endAt) }}
          </template>
        </TwBadge>
      </div>
      <div class="flex mt-2">
        <TwBadge :color="getStatusBgClassName()">
          {{ $t(getStatusName()) }}
        </TwBadge>
      </div>
    </div>
    <EventMembers :members="event.members" />
    <div v-if="event.selfUser.isOwner">
      <div class="flex justify-center">
        <TwButton color="secondary" uk-toggle="target: #manage-event-modal" rounded="rounded-md">
          {{ $t('event.editEvent') }}
        </TwButton>
      </div>
      <ManageEventModal
        title="event.editEvent"
        :event="event"
        save-event-text="event.saveEvent"
        @success="loadEvent(event.id)"
      />
    </div>
  </div>
</template>
