<script setup>
import { EventPictureDateGroup } from '@/models/EventPictureDateGroup'
import { formatToHumanReadable } from '@/services/DateTimeFormatter'
import { EVENT_PICTURE_VIEW_GRID, EVENT_PICTURE_VIEW_LIST } from '@/config/EventPictureViewtypes'
import EventPictureThumb from './EventPictureThumb.vue'
import EventPictureListThumb from '@/components/event/EventPictureListThumb.vue'
import { useEventStore } from '@/stores/Event'

import { storeToRefs } from 'pinia'

const eventStore = useEventStore()
const { currentViewMode } = storeToRefs(eventStore)
const props = defineProps({
  eventPictureDateGroup: {
    type: EventPictureDateGroup,
    required: true
  }
})
</script>

<template>
  <div class="grid mb-8">
    <div class="text-md font-thin flex items-center text-slate-900">
      {{ formatToHumanReadable(props.eventPictureDateGroup.date) }}
    </div>
    <div v-if="currentViewMode === EVENT_PICTURE_VIEW_GRID" class="grid">
      <div class="grid md:gap-3 grid-cols-3 gap-2 mt-2">
        <EventPictureThumb
          v-for="eventPicture in props.eventPictureDateGroup.eventPictures"
          :key="`event-picture-grid-thumb-${eventPicture.id}`"
          :event-picture="eventPicture"
        />
      </div>
    </div>

    <div v-if="currentViewMode === EVENT_PICTURE_VIEW_LIST" class="flex-col space-y-3 mt-2">
      <EventPictureListThumb
        v-for="eventPicture in props.eventPictureDateGroup.eventPictures"
        :key="`event-picture-grid-thumb-${eventPicture.id}`"
        :event-picture="eventPicture"
      />
    </div>
  </div>
</template>
