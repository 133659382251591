<script setup>
import TwCard from '@/components/ui/TwCard.vue'
import { EventsRepository } from '@/repositories/EventsRepository'
import { ref } from 'vue'
import TwButton from '@/components/ui/TwButton.vue'
import { useRouter } from 'vue-router'
import { ROUTES } from '@/router/Routes'
import { formatToHumanReadable } from '@/services/DateTimeFormatter'
import TwBadge from '@/components/ui/TwBadge.vue'
import { useBookDesignerState } from '@/stores/BookDesigner'

const bookDesignerState = useBookDesignerState()
const router = useRouter()
const eventsRepo = new EventsRepository()
const eventsLoading = ref(false)
const events = ref([])

const loadEvents = async () => {
  eventsLoading.value = true
  events.value = await eventsRepo.getEvents()
  eventsLoading.value = false
}

loadEvents()

const pickEvent = (event) => {
  bookDesignerState.pickEvent(event)
}
</script>

<template>
  <div class="w-full">
    <div v-if="!eventsLoading && events.length === 0">
      <div class="w-full text-center flex items-center flex-col gap-2">
        <p>{{ $t('events.noEventsOrders') }}</p>
        <p>{{ $t('events.addEventOrders') }}</p>
        <div class="flex gap-2">
          <TwButton color="bg-sky-100" @click="router.push({ name: ROUTES.EVENTS })">
            {{ $t('events.goToEventsPage') }}
          </TwButton>
        </div>
      </div>
    </div>
    <div v-else-if="!eventsLoading && events.length > 0" class="flex flex-col gap-4">
      <p>{{ $t('orders.pickEventCopy') }}</p>
      <div class="grid gap-6 md:grid-cols-3 grid-cols-2">
        <TwCard
          v-for="(event, index) of events"
          :key="`event-${index}`"
          class="flex flex-col gap-2 cursor-pointer"
          @click="pickEvent(event)"
        >
          <img
            :src="event.coverImageUrl || '/assets/images/avatars/avatar-lg-4.jpg'"
            class="w-full object-cover h-64 rounded-t-xl"
            :alt="event.name"
          />
          <h4 class="text-black font-medium inline-block px-2">{{ event.name }}</h4>
          <div class="flex justify-end pb-4 px-2">
            <TwBadge color="bg-sky-100">
              <template v-if="event.startAt.toLocaleString() === event.endAt.toLocaleString()">
                {{ formatToHumanReadable(event.startAt) }}
              </template>
              <template v-else>
                {{ formatToHumanReadable(event.startAt) }}
                -
                {{ formatToHumanReadable(event.endAt) }}
              </template>
            </TwBadge>
          </div>
        </TwCard>
      </div>
    </div>
    <div v-else class="flex flex-col gap-4">
      <div class="bg-slate-300/60 rounded-md animate-pulse h-6 w-1/2"></div>
      <div class="grid gap-4 md:grid-cols-3 grid-cols-2">
        <div class="flex flex-col gap-2">
          <div class="bg-slate-300/60 rounded-md animate-pulse h-64 w-full"></div>
        </div>
        <div class="flex flex-col gap-2">
          <div class="bg-slate-300/60 rounded-md animate-pulse h-64 w-full"></div>
        </div>
        <div class="flex flex-col gap-2">
          <div class="bg-slate-300/60 rounded-md animate-pulse h-64 w-full"></div>
        </div>
      </div>
    </div>
  </div>
</template>
