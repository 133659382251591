import { Event } from '@/models/Event'
import { BookSize } from '@/models/BookSize'
import { STATUS_PENDING } from '@/config/Orders'
export class Order {
  constructor({
    id = 0,
    eventId = 0,
    event = {},
    createdAt = '',
    total = 0,
    bookType = {},
    status = STATUS_PENDING
  } = {}) {
    this.id = id
    this.eventId = eventId
    this.event = new Event(event)
    this.createdAt = new Date(createdAt)
    this.total = total
    bookType.sizeName = bookType.name
    this.bookType = new BookSize(bookType)
    this.status = status
  }
}
