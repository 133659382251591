<script setup>
import TwModal from '@/components/ui/TwModal.vue'
import { computed, ref } from 'vue'
import {
  NOTIFICATION_FREQUENCY_DAILY,
  NOTIFICATION_FREQUENCY_IMMEDIATE,
  NOTIFICATION_FREQUENCY_MONTHLY,
  NOTIFICATION_FREQUENCY_NEVER,
  NOTIFICATION_FREQUENCY_WEEKLY
} from '@/config/NotificationFrequencies'
import NotificationFrequencyRadio from '@/components/event/NotificationFrequencyRadio.vue'
import VueDatePicker from '@vuepic/vue-datepicker'
import { EventFull } from '@/models/EventFull'
import { base64EncodeFile } from '@/services/Base64EncodeFile'
import { EventsRepository } from '@/repositories/EventsRepository'
import { mapErrors } from '@/services/MapErrors'
import TwErrorsList from '@/components/ui/TwErrorsList.vue'
import IconLoading from '@/components/icons/IconLoading.vue'
import DeleteEventModal from '@/components/event/DeleteEventModal.vue'
import router from '@/router'
import { ROUTES } from '@/router/Routes'
import { timeEndOfDay, timeStartOfDay } from '@/services/DateTimeFormatter'
import { useAuthStore } from '@/stores/auth'
import { storeToRefs } from 'pinia'
import TwButton from '@/components/ui/TwButton.vue'
import { useRouter } from 'vue-router'

const eventsRepo = new EventsRepository()
const authStore = useAuthStore()
const { user } = storeToRefs(authStore)

const props = defineProps({
  event: {
    type: EventFull,
    required: false,
    default: null
  },
  title: {
    type: String,
    default: 'events.createEvent'
  },
  saveEventText: {
    type: String,
    default: 'events.createEvent'
  }
})
const emit = defineEmits(['success'])

const errors = ref([])
const closeButton = ref(null)
const saving = ref(false)
const coverImage = ref(null)
const coverImageThumbUrl = ref(null)
const eventDetails = ref({
  title: props.event?.name || '',
  description: props.event?.description || '',
  startAt: props.event?.startAt || new Date(),
  endAt: props.event?.endAt || new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
  coverImage: null,
  postPictureNotificationFrequency:
    props.event?.notificationSettings.postPictureNotificationFrequency ||
    NOTIFICATION_FREQUENCY_DAILY,
  memberNewPictureNotificationFrequency:
    props.event?.notificationSettings.memberNewPictureNotificationFrequency ||
    NOTIFICATION_FREQUENCY_IMMEDIATE,
  viewerNewPictureNotificationFrequency:
    props.event?.notificationSettings.viewerNewPictureNotificationFrequency ||
    NOTIFICATION_FREQUENCY_IMMEDIATE
})
if (props.event?.coverImageUrl) {
  coverImageThumbUrl.value = props.event.coverImageUrl
}

const hasActiveSubscription = computed(() => {
  return user.value.hasActiveSubscription
})

const canSaveEvent = computed(() => {
  return hasActiveSubscription.value || props.event?.id > 0
})

const pickCoverImage = () => {
  coverImage.value.click()
}

const changeCoverImage = () => {
  const file = coverImage.value.files[0]
  eventDetails.value.coverImage = file
  const reader = new FileReader()
  reader.onload = (e) => {
    coverImageThumbUrl.value = e.target.result
  }
  reader.readAsDataURL(file)
}

const removeCoverImage = () => {
  eventDetails.value.coverImage = null
  coverImageThumbUrl.value = null
}

const closeModal = () => {
  closeButton.value.click()
}

const saveEvent = async () => {
  errors.value = []
  saving.value = true
  const data = {
    name: eventDetails.value.title,
    description: eventDetails.value.description,
    coverImageFile: eventDetails.value.coverImage
      ? await base64EncodeFile(eventDetails.value.coverImage)
      : null,
    removeCoverImage: !eventDetails.value.coverImage && !coverImageThumbUrl.value,
    coverImageFileName: eventDetails.value.coverImage ? eventDetails.value.coverImage.name : null,
    startAt: timeStartOfDay(eventDetails.value.startAt),
    endAt: timeEndOfDay(eventDetails.value.endAt),
    postPictureNotificationFrequency: eventDetails.value.postPictureNotificationFrequency,
    memberNewPictureNotificationFrequency: eventDetails.value.memberNewPictureNotificationFrequency,
    viewerNewPictureNotificationFrequency: eventDetails.value.viewerNewPictureNotificationFrequency
  }
  try {
    if (props.event) {
      const resp = await eventsRepo.updateEvent(props.event.id, data)
      emit('success', resp.data.data.id)
    } else {
      const resp = await eventsRepo.createEvent(data)
      emit('success', resp.data.data.id)
    }
    closeModal()
  } catch (e) {
    errors.value = mapErrors(e.response.data.errors)
  }
  saving.value = false
}

const deleteEvent = async () => {
  saving.value = true
  await eventsRepo.deleteEvent(props.event.id)
  saving.value = false
  closeModal()
  await router.push({
    name: ROUTES.EVENTS
  })
}
</script>

<template>
  <TwModal id="manage-event-modal" :title="$t(title)" :dynamic-height="true">
    <div v-if="canSaveEvent" class="space-y-6">
      <h3 class="font-bold">{{ $t('events.generalSettings') }}</h3>
      <div class="md:flex items-center gap-10">
        <label class="md:w-40 text-right">{{ $t('events.eventTitle') }}</label>
        <div class="flex-1 max-md:mt-4">
          <input v-model="eventDetails.title" type="text" class="lg:w-1/2 w-full" />
        </div>
      </div>
      <div class="md:flex items-start gap-10">
        <label class="md:w-40 text-right">{{ $t('events.description') }} </label>
        <div class="flex-1 max-md:mt-4">
          <textarea v-model="eventDetails.description" class="w-full" rows="3"></textarea>
        </div>
      </div>
      <div class="md:flex items-center gap-10">
        <label class="md:w-40 text-right">{{ $t('events.startOn') }} </label>
        <div class="flex-1 max-md:mt-4">
          <VueDatePicker
            v-model="eventDetails.startAt"
            :month-change-on-scroll="false"
            :enable-time-picker="false"
            input-class-name="w-full tw-custom-input"
            :auto-apply="true"
          />
        </div>
      </div>
      <div class="md:flex items-center gap-10">
        <label class="md:w-40 text-right">{{ $t('events.endsOn') }} </label>
        <div class="flex-1 max-md:mt-4">
          <VueDatePicker
            v-model="eventDetails.endAt"
            :month-change-on-scroll="false"
            :enable-time-picker="false"
            input-class-name="w-full tw-custom-input"
            :auto-apply="true"
          />
        </div>
      </div>
      <div class="md:flex items-center gap-10">
        <label class="md:w-40 text-right">{{ $t('events.coverImage') }}</label>
        <div class="flex-1 max-md:mt-4">
          <input
            ref="coverImage"
            type="file"
            class="hidden"
            accept=".jpg,.jpeg,.png"
            @change="changeCoverImage"
          />
          <div v-if="coverImageThumbUrl">
            <img :src="coverImageThumbUrl" class="object-cover rounded-lg h-40 w-40 mb-2" />
            <button
              type="button"
              class="bg-red-50 text-red-400 rounded-lg py-1.5 px-4 text-sm mb-2"
              @click="removeCoverImage"
            >
              <ion-icon name="trash-outline" class="text-sm mr-1"></ion-icon>
              {{ $t('events.removeCoverImage') }}
            </button>
          </div>
          <button
            type="button"
            class="bg-sky-100 rounded-lg py-1.5 px-4 text-sm"
            @click="pickCoverImage"
          >
            {{ $t('events.selectCoverImage') }}
          </button>
        </div>
      </div>
      <h3 class="font-bold">{{ $t('events.notificationSettings') }}</h3>
      <div class="md:flex items-start gap-10">
        <label class="md:w-60 text-right">
          {{ $t('events.notificationSettingPostNewPhotos') }}
        </label>
        <div class="flex-1 space-y-2 interactive-effect max-md:mt-3">
          <NotificationFrequencyRadio
            id="notif-1-freq-1"
            :notification-type="NOTIFICATION_FREQUENCY_NEVER"
            :value="eventDetails.postPictureNotificationFrequency"
            @change="(value) => (eventDetails.postPictureNotificationFrequency = value)"
          />
          <NotificationFrequencyRadio
            id="notif-1-freq-2"
            :notification-type="NOTIFICATION_FREQUENCY_DAILY"
            :value="eventDetails.postPictureNotificationFrequency"
            @change="(value) => (eventDetails.postPictureNotificationFrequency = value)"
          />
          <NotificationFrequencyRadio
            id="notif-1-freq-3"
            :notification-type="NOTIFICATION_FREQUENCY_WEEKLY"
            :value="eventDetails.postPictureNotificationFrequency"
            @change="(value) => (eventDetails.postPictureNotificationFrequency = value)"
          />
          <NotificationFrequencyRadio
            id="notif-1-freq-4"
            :notification-type="NOTIFICATION_FREQUENCY_MONTHLY"
            :value="eventDetails.postPictureNotificationFrequency"
            @change="(value) => (eventDetails.postPictureNotificationFrequency = value)"
          />
        </div>
      </div>
      <!--      <div class="md:flex items-start gap-10">-->
      <!--        <label class="md:w-60 text-right">-->
      <!--          {{ $t('events.notificationSettingPostedPicturesMembers') }}</label-->
      <!--        >-->
      <!--        <div class="flex-1 space-y-2 interactive-effect max-md:mt-3">-->
      <!--          <NotificationFrequencyRadio-->
      <!--            id="notif-2-freq-1"-->
      <!--            :notification-type="NOTIFICATION_FREQUENCY_NEVER"-->
      <!--            :value="eventDetails.memberNewPictureNotificationFrequency"-->
      <!--            @change="(value) => (eventDetails.memberNewPictureNotificationFrequency = value)"-->
      <!--          />-->
      <!--          <NotificationFrequencyRadio-->
      <!--            id="notif-2-freq-2"-->
      <!--            :notification-type="NOTIFICATION_FREQUENCY_IMMEDIATE"-->
      <!--            :value="eventDetails.memberNewPictureNotificationFrequency"-->
      <!--            @change="(value) => (eventDetails.memberNewPictureNotificationFrequency = value)"-->
      <!--          />-->
      <!--          <NotificationFrequencyRadio-->
      <!--            id="notif-2-freq-3"-->
      <!--            :notification-type="NOTIFICATION_FREQUENCY_DAILY"-->
      <!--            :value="eventDetails.memberNewPictureNotificationFrequency"-->
      <!--            @change="(value) => (eventDetails.memberNewPictureNotificationFrequency = value)"-->
      <!--          />-->
      <!--          <NotificationFrequencyRadio-->
      <!--            id="notif-2-freq-4"-->
      <!--            :notification-type="NOTIFICATION_FREQUENCY_WEEKLY"-->
      <!--            :value="eventDetails.memberNewPictureNotificationFrequency"-->
      <!--            @change="(value) => (eventDetails.memberNewPictureNotificationFrequency = value)"-->
      <!--          />-->
      <!--          <NotificationFrequencyRadio-->
      <!--            id="notif-2-freq-5"-->
      <!--            :notification-type="NOTIFICATION_FREQUENCY_MONTHLY"-->
      <!--            :value="eventDetails.memberNewPictureNotificationFrequency"-->
      <!--            @change="(value) => (eventDetails.memberNewPictureNotificationFrequency = value)"-->
      <!--          />-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div class="md:flex items-start gap-10">-->
      <!--        <label class="md:w-60 text-right">-->
      <!--          {{ $t('events.notificationSettingPostedPicturesViewers') }}-->
      <!--        </label>-->
      <!--        <div class="flex-1 space-y-2 interactive-effect max-md:mt-3">-->
      <!--          <NotificationFrequencyRadio-->
      <!--            id="notif-3-freq-1"-->
      <!--            :notification-type="NOTIFICATION_FREQUENCY_NEVER"-->
      <!--            :value="eventDetails.viewerNewPictureNotificationFrequency"-->
      <!--            @change="(value) => (eventDetails.viewerNewPictureNotificationFrequency = value)"-->
      <!--          />-->
      <!--          <NotificationFrequencyRadio-->
      <!--            id="notif-3-freq-2"-->
      <!--            :notification-type="NOTIFICATION_FREQUENCY_IMMEDIATE"-->
      <!--            :value="eventDetails.viewerNewPictureNotificationFrequency"-->
      <!--            @change="(value) => (eventDetails.viewerNewPictureNotificationFrequency = value)"-->
      <!--          />-->
      <!--          <NotificationFrequencyRadio-->
      <!--            id="notif-3-freq-3"-->
      <!--            :notification-type="NOTIFICATION_FREQUENCY_DAILY"-->
      <!--            :value="eventDetails.viewerNewPictureNotificationFrequency"-->
      <!--            @change="(value) => (eventDetails.viewerNewPictureNotificationFrequency = value)"-->
      <!--          />-->
      <!--          <NotificationFrequencyRadio-->
      <!--            id="notif-3-freq-4"-->
      <!--            :notification-type="NOTIFICATION_FREQUENCY_WEEKLY"-->
      <!--            :value="eventDetails.viewerNewPictureNotificationFrequency"-->
      <!--            @change="(value) => (eventDetails.viewerNewPictureNotificationFrequency = value)"-->
      <!--          />-->
      <!--          <NotificationFrequencyRadio-->
      <!--            id="notif-3-freq-5"-->
      <!--            :notification-type="NOTIFICATION_FREQUENCY_MONTHLY"-->
      <!--            :value="eventDetails.viewerNewPictureNotificationFrequency"-->
      <!--            @change="(value) => (eventDetails.viewerNewPictureNotificationFrequency = value)"-->
      <!--          />-->
      <!--        </div>-->
      <!--      </div>-->
      <TwErrorsList :errors="errors" />
    </div>
    <div v-else>
      <div class="items-center justify-center flex flex-col gap-4">
        <h4>{{ $t('event.noActiveSubscriptionToCreateEvent') }}</h4>
        <TwButton
          color="bg-primary text-white"
          rounded="rounded-md"
          class="uk-modal-close"
          @click="router.push({ name: ROUTES.SETTINGS_SUBSCRIPTION })"
        >
          {{ $t('event.pickSubscription') }}
        </TwButton>
      </div>
    </div>
    <button ref="closeButton" class="hidden uk-modal-close"></button>
    <template #actions>
      <button
        class="px-5 py-1.5 bg-primary text-white rounded-md flex items-center"
        :class="{
          'cursor-not-allowed': saving || !canSaveEvent,
          'bg-sky-50 text-inherit': !canSaveEvent
        }"
        type="button"
        :disabled="saving || !canSaveEvent"
        @click="saveEvent"
      >
        <IconLoading v-if="saving" />
        <span v-else>{{ $t(saveEventText) }}</span>
      </button>
    </template>
    <template #pre-actions>
      <div v-if="event" class="grow flex items-start justify-start">
        <button
          class="px-5 py-1.5 bg-red-50 text-red-400 rounded-md flex items-center"
          type="button"
          :disabled="saving"
          :uk-toggle="`target: #delete-event-${event.id}`"
        >
          <IconLoading v-if="saving" />
          <span v-else>{{ $t('delete') }}</span>
        </button>
      </div>
    </template>
  </TwModal>
  <DeleteEventModal
    v-if="event"
    :id="`delete-event-${event.id}`"
    :event="event"
    @deleted="deleteEvent"
  />
</template>
