export const NOTIFICATION_FREQUENCY_NEVER = 'never'
export const NOTIFICATION_FREQUENCY_IMMEDIATE = 'immediate'
export const NOTIFICATION_FREQUENCY_DAILY = 'daily'
export const NOTIFICATION_FREQUENCY_WEEKLY = 'weekly'
export const NOTIFICATION_FREQUENCY_MONTHLY = 'monthly'

export const TRANSLATION_KEYS = {
  [NOTIFICATION_FREQUENCY_NEVER]: 'events.never',
  [NOTIFICATION_FREQUENCY_IMMEDIATE]: 'events.immediate',
  [NOTIFICATION_FREQUENCY_DAILY]: 'events.daily',
  [NOTIFICATION_FREQUENCY_WEEKLY]: 'events.weekly',
  [NOTIFICATION_FREQUENCY_MONTHLY]: 'events.monthly'
}
