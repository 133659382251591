<script setup>
import TwModal from '@/components/ui/TwModal.vue'
import { useEventStore } from '@/stores/Event'
import { storeToRefs } from 'pinia'
import QrcodeVue from 'qrcode.vue'

const eventStore = useEventStore()
const { event } = storeToRefs(eventStore)
const joinLink = `${import.meta.env.VITE_APP_URL}/join/${event.value.eventHash}`

const copyLink = () => {
  navigator.clipboard.writeText(joinLink)
}
</script>

<template>
  <TwModal id="invite-members-modal" :title="$t('event.inviteParticipants')">
    <div class="space-y-2">
      <p class="">{{ $t('event.addParticipantsWithLink') }}</p>
      <div class="flex items-center gap-3">
        <input type="text" class="w-full" :value="joinLink" readonly />
        <button class="flex items-center" :uk-tooltip="$t('event.copyLink')" @click="copyLink">
          <ion-icon name="copy-outline" class="text-2xl"></ion-icon>
        </button>
      </div>
      <p class="text-center pt-6">{{ $t('event.addParticipantsWithQrCode') }}</p>
      <div class="flex items-center justify-center">
        <div class="p-4 border rounded-lg">
          <QrcodeVue :value="joinLink" :size="180"></QrcodeVue>
        </div>
      </div>
    </div>
    <button ref="closeButton" class="hidden uk-modal-close"></button>
    <template #actions> </template>
  </TwModal>
</template>
