<script setup>
import { BOOK_SIZE_1_SQUARE, BOOK_SIZES } from '@/config/BookSizes'
import { BookPage } from '@/models/BookPage'
import { useBookDesignerState } from '@/stores/BookDesigner'
import { toRaw } from 'vue'

const designerState = useBookDesignerState()
const bookSize = BOOK_SIZES[BOOK_SIZE_1_SQUARE]
const props = defineProps({
  page: {
    type: BookPage,
    required: true
  },
  pageIndex: {
    type: Number,
    required: true
  }
})

const switchPage = () => {
  designerState.openPage(toRaw(props.page))
}

const getBackCoverLogoHeight = () => {
  return (40 * bookSize.pageHeight * 10) / bookSize.pageHeightPx
}
const getBackCoverLogoWidth = () => {
  return (28 * bookSize.pageWidth * 10) / bookSize.pageWidthPx
}
</script>

<template>
  <div class="flex flex-col items-center gap-2 w-1/2">
    <div
      class="border border-slate-300 flex justify-center items-center w-full overflow-hidden cursor-pointer"
      :style="{ aspectRatio: `${bookSize.pageWidthPx}/${bookSize.pageHeightPx}` }"
      @click="switchPage"
    >
      <div
        class="new-order_book-designer-page flex flex-col justify-center items-center h-full w-full overflow-hidden"
      >
        <div class="grow w-full new-order_book-designer-image flex items-end justify-center pb-4">
          <img
            src="/assets/images/twibie-logo-stacked-v2.png"
            alt=""
            :style="[`height:${getBackCoverLogoHeight()}px`, `width:${getBackCoverLogoWidth()}px`]"
          />
        </div>
      </div>
    </div>
    <div class="text-slate-300">
      {{ $t(`orders.pageType-${page.pageType}`) }}
    </div>
  </div>
</template>

<style scoped>
.new-order_book-designer-page {
  padding: 1%;
}
.new-order_book-designer-image {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}
</style>
