export const STEP_PICK_EVENT = 'pickEvent'
export const STEP_PICK_BOOK_TYPE = 'pickBookType'
export const STEP_DESIGN_BOOK = 'designBook'
export const STEP_PICK_ADDRESS = 'pickAddress'
export const STEP_PAYMENT = 'payment'

export const STATUS_PENDING = 'pending'
export const STATUS_PAID = 'paid'
export const STATUS_PRINTING = 'printing'
export const STATUS_COMPLETED = 'completed'
export const STATUS_INVOICE_SENT = 'invoice-sent'
export const STATUS_CANCELED = 'canceled'
