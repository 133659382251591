import { ref } from 'vue'
import { defineStore } from 'pinia'
import { auth, googleAuthProvider, initMessaging } from '../../firebaseConfig'
import { signInWithPopup } from 'firebase/auth'
import { signInWithEmailAndPassword, signOut } from 'firebase/auth'
import { UserRepository } from '@/repositories/UserRepository'
import { BaseAuthRepository } from '@/repositories/BaseAuthRepository'
import { I18n } from '@/services/I18n'

const userRepository = new UserRepository()
export const useAuthStore = defineStore('auth', () => {
  const isProfileLoading = ref(true)
  const isLoggedIn = ref(false)
  const user = ref(null)

  async function login(email, password) {
    try {
      await signInWithEmailAndPassword(auth, email, password)
      BaseAuthRepository.authToken = auth.currentUser?.accessToken
      await this.getUser()

      return true
    } catch (e) {
      return false
    }
  }

  async function logout() {
    await signOut(auth)
    isLoggedIn.value = false
    user.value = null
    window.location.reload()
  }

  async function getUser() {
    this.isProfileLoading = true
    if (auth.currentUser) {
      try {
        user.value = await userRepository.getUser()
        I18n.switchLocale(user.value.locale)
        try {
          await initMessaging()
        } catch (e) {}
      } catch (e) {
        await logout()
      }
    }
    this.isProfileLoading = false
  }

  async function loginWithGoogle() {
    try {
      const result = await signInWithPopup(auth, googleAuthProvider)

      BaseAuthRepository.authToken = auth.currentUser?.accessToken
      // IdP data available using getAdditionalUserInfo(result)
      try {
        user.value = await userRepository.getUser()
      } catch (e) {
        await userRepository.registerViaGoogle({
          name: result.user.displayName,
          email: result.user.email,
          uid: result.user.uid,
          avatarUrl: result.user.photoURL
        })
        user.value = await userRepository.getUser()
      }
    } catch (e) {}
  }

  return {
    isLoggedIn,
    user,
    login,
    logout,
    isProfileLoading,
    getUser,
    loginWithGoogle
  }
})
