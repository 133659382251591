import './assets/main.css'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import { I18n } from '@/services/I18n'
// import './registerServiceWorker'
import { initAuth } from '../firebaseConfig'
import * as Sentry from '@sentry/vue'

initAuth().then(() => {
  const i18n = I18n.init()
  const app = createApp(App)
  if (import.meta.env.VITE_ENV === 'production') {
    Sentry.init({
      dsn: 'https://26db37975f6a124d968e74bff235d0f0@o4506276999200768.ingest.us.sentry.io/4506882620063744',
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false
        })
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', /^https:\/\/twibie\.com\/api/],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })
  }

  app.use(createPinia())
  app.use(router)

  app.use(i18n)

  app.mount('#app')

  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/firebase-messaging-sw.js')
  }
})
