<script setup>
import IconLargePhotos from '@/components/icons/IconLargePhotos.vue'
import { computed, ref } from 'vue'
import TwCloseModalButton from '@/components/ui/TwCloseModalButton.vue'
import * as ExifReader from 'exifreader'
import { PickedPhoto } from '@/models/PickedPhoto'
import '@/assets/css/datetimepicker.css'
import VueDatePicker from '@vuepic/vue-datepicker'
import { EventPictureRepository } from '@/repositories/EventPictureRepository'
import { base64EncodeFile } from '@/services/Base64EncodeFile'
import { useEventStore } from '@/stores/Event'
import TwErrorsList from '@/components/ui/TwErrorsList.vue'
import { mapErrors } from '@/services/MapErrors'
import router from '@/router'
import { ROUTES } from '@/router/Routes'
import TwButton from '@/components/ui/TwButton.vue'
import { useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'

const VALID_FILE_TYPES = ['image/gif', 'image/jpeg', 'image/png']
const eventPictureRepo = new EventPictureRepository()
const eventStore = useEventStore()
const { event } = storeToRefs(eventStore)

const props = defineProps({
  eventId: {
    type: Number,
    required: true
  }
})
const isDragging = ref(false)
const files = ref([])
const fileInput = ref(null)
const filesUploading = ref(false)
const modalCloseButton = ref(null)
const onChange = async () => {
  const filteredFiles = [...fileInput.value.files].filter((file) =>
    VALID_FILE_TYPES.includes(file.type)
  )
  for (const file of filteredFiles) {
    const tags = await ExifReader.load(file)
    const date = tags?.DateTimeOriginal?.description
      ? new Date(PickedPhoto.parseExifDateFormat(tags.DateTimeOriginal.description))
      : new Date()
    files.value.push(
      new PickedPhoto({
        uploadedFile: file,
        caption: '',
        datePhotoTakenAt: date.toJSON()
      })
    )
  }
}

const isOwner = computed(() => {
  return event.value?.selfUser.isOwner
})

const canUploadMorePhotos = computed(() => {
  return event.value?.canUploadMorePhotos
})

const dragover = (e) => {
  e.preventDefault()
  isDragging.value = true
}
const dragleave = () => {
  isDragging.value = false
}
const drop = (e) => {
  e.preventDefault()
  fileInput.value.files = e.dataTransfer.files
  onChange()
  isDragging.value = false
}
const pickPhotos = (e) => {
  e.stopPropagation()
  fileInput.value.click()
}
const getFileUrl = (file) => {
  return URL.createObjectURL(file)
}

const removePhoto = (index) => {
  files.value.splice(index, 1)
}

const closeModal = () => {
  clearPhotos()
  modalCloseButton.value.click()
}
const clearPhotos = () => {
  files.value = []
}

const uploadPhotos = async () => {
  filesUploading.value = true
  files.value.forEach((file) => {
    file.isUploading = true
  })

  for (const file of files.value) {
    const fileContent = await base64EncodeFile(file.uploadedFile)
    try {
      await eventPictureRepo.uploadEventPicture(
        props.eventId,
        {
          caption: file.caption,
          photoTakenAt: new Date(file.datePhotoTakenAt).toISOString(),
          imageFile: fileContent,
          imageFileName: file.uploadedFile.name
        },
        (progress) => {
          file.uploadProgress = progress.progress
        }
      )
    } catch (e) {
      file.errors = mapErrors(e?.response?.data?.errors) || []
      file.uploadProgress = 0
      file.isUploading = false
    }
  }
  // cant remove files one by one, that messes up with indexes...
  files.value = files.value.filter((file) => file.errors.length > 0)

  filesUploading.value = false
  if (files.value.length === 0) {
    closeModal()
    await eventStore.resetEventPictures(props.eventId)
  }
}
</script>

<template>
  <!-- model scrolling -->
  <div id="add-photo" class="lg:p-20 uk-modal w-full h-full" uk-modal="">
    <div
      class="flex flex-col relative mx-auto bg-white rounded-lg shadow-xl uk-modal-dialog w-full md:w-[700px] h-full md:h-5/6"
    >
      <div class="px-6 py-4 border-b">
        <h2 class="text-xl font-semibold">{{ $t('event.uploadPhotos') }}</h2>
      </div>

      <div v-if="canUploadMorePhotos" class="overflow-y-auto grow">
        <input
          ref="fileInput"
          type="file"
          class="hidden"
          multiple
          accept=".jpg,.jpeg,.png"
          @change="onChange"
        />
        <div class="w-full h-full">
          <div
            class="w-full h-full relative overflow-y-auto flex"
            :class="{ 'bg-green-50': isDragging }"
            @dragover="dragover"
            @dragleave="dragleave"
            @drop="drop"
          >
            <div
              v-if="files.length === 0"
              class="space-y-4 absolute flex flex-col justify-center items-center cursor-pointer w-full h-full"
              uk-scrollspy="target: > div; cls: uk-animation-scale-up; delay: 300;repeat:true"
              @click="pickPhotos"
            >
              <div class="flex justify-center">
                <IconLargePhotos />
              </div>
              <div class="mx-auto flex flex-col gap-2 items-center">
                <span class="text-center hidden md:block"
                  >{{ $t('event.dropPhotosHere') }}<br />{{ $t('event.or') }}</span
                >
                <TwButton color="primary" rounded="rounded-md" @click="pickPhotos">
                  {{ $t('event.selectPhotos') }}
                </TwButton>
              </div>
            </div>
            <div v-else class="flex flex-col w-full p-6">
              <TransitionGroup name="list" tag="div">
                <div
                  v-for="(file, index) in files"
                  :key="`photo-upload-item-${index}`"
                  class="w-full flex pb-4 mb-4 border-b"
                >
                  <div class="relative overflow-hidden rounded-lg h-40 w-40 md:h-56 md:w-56">
                    <div class="relative w-full h-full aspect-[3/3]">
                      <img
                        :src="getFileUrl(file.uploadedFile)"
                        alt=""
                        class="object-cover w-full h-full"
                      />
                    </div>
                  </div>
                  <div class="grow flex flex-col ml-4">
                    <div class="flex flex-col">
                      <label class="">{{ $t('event.caption') }}:</label>
                      <div class="">
                        <textarea
                          v-model="file.caption"
                          class="w-full"
                          rows="2"
                          placeholder="Describe the photo..."
                          :disabled="file.isUploading"
                        ></textarea>
                      </div>
                    </div>
                    <div class="flex flex-col">
                      <label class="">{{ $t('event.photoTakenAt') }}:</label>
                      <div class="">
                        <VueDatePicker
                          v-model="file.datePhotoTakenAt"
                          placeholder="Today"
                          time-picker-inline
                          :month-change-on-scroll="false"
                          input-class-name="w-full tw-custom-input"
                          :disabled="file.isUploading"
                        />
                      </div>
                    </div>
                    <div class="">
                      <TwErrorsList :errors="file.errors" />
                      <button
                        v-if="!file.isUploading"
                        class="border mt-4 px-4 text-md py-1.5 leading-6 flex items-center rounded-md bg-red-100 border-red-500/30 border-red-200 text-red-600"
                        type="button"
                        @click="removePhoto(index)"
                      >
                        <ion-icon name="trash-outline" class="mr-1"></ion-icon>
                        {{ $t('event.removePhoto') }}
                      </button>
                      <div
                        v-else
                        class="transition-[width] ease-in-out delay-50 duration-200 min-w-[5%]"
                        :style="{ width: file.uploadProgress * 100 + '%' }"
                      >
                        <div class="w-40 h-5 rounded-md bg-sky-200 animate-pulse mt-2 w-full"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </TransitionGroup>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="overflow-y-auto grow">
        <div
          v-if="isOwner"
          class="items-center justify-center flex flex-col gap-4 text-center h-full px-4"
        >
          <h4>{{ $t('event.noActiveSubscriptionToUploadPhotosOwner') }}</h4>
          <TwButton
            color="bg-primary text-white"
            rounded="rounded-md"
            class="uk-modal-close"
            @click="router.push({ name: ROUTES.SETTINGS_SUBSCRIPTION })"
          >
            {{ $t('event.pickSubscription') }}
          </TwButton>
        </div>
        <div v-else class="items-center justify-center flex flex-col gap-4 text-center h-full">
          <h4>{{ $t('event.noActiveSubscriptionToUploadPhotosMember') }}</h4>
        </div>
      </div>

      <div class="flex justify-end p-6 text-sm font-medium px-6 py-4 border-t gap-2">
        <button
          ref="modalCloseButton"
          class="px-4 py-1.5 rounded-md uk-modal-close"
          type="button"
          @click="clearPhotos"
        >
          {{ $t('cancel') }}
        </button>
        <button
          v-if="files.length > 0 && canUploadMorePhotos"
          type="button"
          class="bg-gray-100 rounded-lg py-1.5 px-4 text-sm"
          @click="pickPhotos"
        >
          {{ $t('event.addMorePhotos') }}
        </button>
        <button
          v-if="files.length > 0 && canUploadMorePhotos"
          class="px-5 py-1.5 rounded-md bg-blue-600 text-white"
          type="button"
          :disabled="filesUploading"
          @click="uploadPhotos"
        >
          {{ filesUploading ? $t('event.uploading') : $t('event.uploadPhotos') }}
        </button>
      </div>

      <!-- close button -->
      <TwCloseModalButton />
    </div>
  </div>
</template>
<style scoped>
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
