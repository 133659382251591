<script setup>
import TwPageTitle from '../components/ui/TwPageTitle.vue'
import { storeToRefs } from 'pinia'
import PickEvent from '@/components/orders/PickEvent.vue'
import {
  STEP_DESIGN_BOOK,
  STEP_PICK_ADDRESS,
  STEP_PICK_BOOK_TYPE,
  STEP_PICK_EVENT
} from '@/config/Orders'
import DesignBook from '@/components/orders/DesignBook.vue'
import { useBookDesignerState } from '@/stores/BookDesigner'
import PickBookType from '@/components/orders/PickBookType.vue'
import FillAddress from '@/components/orders/FillAddress.vue'
const designerState = useBookDesignerState()
const { currentStep } = storeToRefs(designerState)
</script>
<template>
  <TwPageTitle>
    {{ $t('newOrder.title') }}
  </TwPageTitle>
  <div id="js-oversized" class="mt-4 md:mt-10">
    <PickEvent v-if="currentStep === STEP_PICK_EVENT" />
    <PickBookType v-if="currentStep === STEP_PICK_BOOK_TYPE" />
    <DesignBook v-if="currentStep === STEP_DESIGN_BOOK" />
    <FillAddress v-if="currentStep === STEP_PICK_ADDRESS" />
  </div>
</template>
