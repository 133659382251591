<script setup>
import TwCard from '../ui/TwCard.vue'
import { ref } from 'vue'
import BookDesignerPage from './BookDesignerPage.vue'
import { useBookDesignerState } from '@/stores/BookDesigner'
import { storeToRefs } from 'pinia'
const designerState = useBookDesignerState()
const { spreads } = storeToRefs(designerState)

const spreadRefs = ref([])

const moveRight = () => {
  const currentInView = designerState.spreadInFocus
  const scrollToElement = spreadRefs.value[currentInView + 1]
  if (!scrollToElement) {
    return
  }

  designerState.focusOnSpread(currentInView + 1)
  scrollToElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
}
const moveLeft = () => {
  const currentInView = designerState.spreadInFocus
  const scrollToElement = spreadRefs.value[currentInView - 1]
  if (!scrollToElement) {
    return
  }

  designerState.focusOnSpread(currentInView - 1)

  scrollToElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
}
</script>

<template>
  <TwCard class="p-4 relative mb-4">
    <div class="flex lg:flex-col gap-4 overflow-hidden">
      <div
        v-for="(spread, index) in spreads.values()"
        :key="`spread-${index}`"
        ref="spreadRefs"
        class="new-order_book-designer-spread flex flex-col w-full shrink-0"
      >
        <div class="flex justify-center">
          <BookDesignerPage
            v-for="(page, pageIndex) in spread"
            :key="`page-${pageIndex}`"
            :page-index="parseInt(pageIndex)"
            :page="page"
          />
        </div>
      </div>
    </div>
    <button
      class="text-4xl absolute left-0 top-1/2 transform -translate-y-1/2 ml-2 lg:hidden"
      @click="moveLeft()"
    >
      <ion-icon name="caret-back-circle-outline"></ion-icon>
    </button>
    <button
      class="text-4xl absolute right-0 top-1/2 transform -translate-y-1/2 mr-2 lg:hidden"
      @click="moveRight()"
    >
      <ion-icon name="caret-forward-circle-outline"></ion-icon>
    </button>
  </TwCard>
</template>
