<script setup>
import { EventMember } from '@/models/EventMember'
import TwSmallBadge from '../ui/TwSmallBadge.vue'
import TwButton from '@/components/ui/TwButton.vue'
import { computed } from 'vue'
import { useEventStore } from '@/stores/Event'
import { ROLE_MEMBER, ROLE_OWNER, ROLE_VIEWER } from '@/config/MemberRoles'
import RemoveMemberModal from '@/components/event/RemoveMemberModal.vue'

const { changeMemberRole } = useEventStore()
const props = defineProps({
  member: {
    type: EventMember,
    required: true
  },
  canModifyMembers: {
    type: Boolean,
    required: true
  },
  numberOfOwners: {
    type: Number,
    required: true
  }
})
const roleToTranslation = () => {
  switch (props.member.role) {
    case 'owner':
      return 'event.memberTypeOwner'
    case 'member':
      return 'event.memberTypeMember'
    default:
      return 'event.memberTypeViewer'
  }
}

const canModifyMember = computed(() => {
  if (!props.canModifyMembers) {
    return false
  }
  if (!props.member.isOwner) {
    return true
  }

  return props.numberOfOwners > 1
})

const editEventMemberRole = async (role) => {
  await changeMemberRole(props.member, role)
}
</script>

<template>
  <div class="flex justify-between items-center mb-4">
    <div class="flex items-center">
      <img
        :src="member.user.avatarUrl"
        :alt="member.user.name"
        class="w-8 h-8 rounded-full shadow object-cover"
      />
      <div class="text-slate-900 text-sm font-normal ml-2">{{ member.user.name }}</div>
    </div>
    <div class="flex">
      <div v-if="canModifyMember">
        <TwButton color="blue" size="small">
          {{ $t(roleToTranslation()) }}
        </TwButton>
        <div
          uk-dropdown="offset:10;pos: bottom-right ; animation: uk-animation-slide-bottom-small; delay-hide:0;"
          class="uk-dropdown"
        >
          <nav>
            <a class="cursor-pointer" @click="editEventMemberRole(ROLE_OWNER)">
              {{ $t('event.makeOwner') }}
            </a>
            <a class="cursor-pointer" @click="editEventMemberRole(ROLE_MEMBER)">
              {{ $t('event.makeMember') }}
            </a>
            <a class="cursor-pointer" @click="editEventMemberRole(ROLE_VIEWER)">
              {{ $t('event.makeViewer') }}
            </a>
            <hr />
            <a :uk-toggle="`target: #remove-member-${member.id}`" class="cursor-pointer">
              <ion-icon class="text-xl" name="trash-outline"></ion-icon>
              {{ $t('event.removeMember') }}
            </a>
          </nav>
        </div>
      </div>
      <TwSmallBadge v-else class="bg-sky-100">
        {{ $t(roleToTranslation()) }}
      </TwSmallBadge>
    </div>
  </div>
  <div v-if="canModifyMember">
    <RemoveMemberModal :member="member" />
  </div>
</template>
