export class Address {
  constructor({
    name = '',
    phone = '',
    address = '',
    address2 = '',
    city = '',
    zip = '',
    state = '',
    country = ''
  } = {}) {
    this.name = name
    this.phone = phone
    this.address = address
    this.address2 = address2
    this.city = city
    this.zip = zip
    this.state = state
    this.country = country
  }
}
