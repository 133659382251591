import { User } from './User'
import { BookPhoto } from './BookPhoto'
import { generateUUID } from '@/services/UuidGenerator'

export class EventPicture {
  constructor({
    id = 0,
    caption = '',
    thumbnailPath = '',
    mediumPath = '',
    largePath = '',
    commentsCount = 0,
    likesCount = 0,
    createdAt = '',
    photoTakenAt = '',
    isLikedBySelf = false,
    uploadedBy = {},
    eventId = 0,
    originalHeight = 0,
    originalWidth = 0
  } = {}) {
    this.id = id
    this.caption = caption
    this.photo = new BookPhoto({
      uid: generateUUID(),
      imageUrl: largePath,
      thumbnailUrl: thumbnailPath,
      mediumUrl: mediumPath,
      originalWidth: originalWidth,
      originalHeight: originalHeight
    })
    this.commentsCount = commentsCount
    this.likesCount = likesCount
    this.createdAt = new Date(createdAt)
    this.photoTakenAt = new Date(photoTakenAt)
    this.isLikedBySelf = isLikedBySelf
    this.uploadedBy = new User(uploadedBy)
    this.eventId = eventId
  }
}
