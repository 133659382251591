export class EventNotificationSettings {
  constructor({
    postPictureNotificationFrequency = '',
    postPictureNotificationTime = '',
    memberNewPictureNotificationFrequency = '',
    memberNewPictureNotificationNotificationTime = '',
    viewerNewPictureNotificationFrequency = '',
    viewerNewPictureNotificationNotificationTime = ''
  } = {}) {
    this.postPictureNotificationFrequency = postPictureNotificationFrequency
    this.postPictureNotificationTime = postPictureNotificationTime
    this.memberNewPictureNotificationFrequency = memberNewPictureNotificationFrequency
    this.memberNewPictureNotificationNotificationTime = memberNewPictureNotificationNotificationTime
    this.viewerNewPictureNotificationFrequency = viewerNewPictureNotificationFrequency
    this.viewerNewPictureNotificationNotificationTime = viewerNewPictureNotificationNotificationTime
  }
}
