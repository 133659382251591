export class BookPhoto {
  constructor({
    uid = 0,
    imageUrl = '',
    thumbnailUrl = '',
    mediumUrl = '',
    originalHeight = 0,
    originalWidth = 0
  } = {}) {
    this.uid = uid
    this.imageUrl = imageUrl
    this.thumbnailUrl = thumbnailUrl
    this.mediumUrl = mediumUrl
    this.originalHeight = originalHeight
    this.originalWidth = originalWidth
  }
}
