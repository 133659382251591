<script setup>
import TwPageTitle from '@/components/ui/TwPageTitle.vue'
import TwCard from '@/components/ui/TwCard.vue'
import { useRoute, useRouter } from 'vue-router'
import { ROUTES } from '@/router/Routes'
import { EventsRepository } from '@/repositories/EventsRepository'

const router = useRouter()
const route = useRoute()
const eventRepo = new EventsRepository()
const joinEvent = async () => {
  const event = await eventRepo.joinEvent(route.params.hash)
  router.push({
    name: ROUTES.EVENT,
    params: {
      id: event.id
    }
  })
}

joinEvent()
</script>

<template>
  <TwPageTitle>
    {{ $t('events.joinEvent') }}
  </TwPageTitle>
  <div class="md:max-w-[510px] mx-auto flex-1 xl:space-y-6 space-y-3 mt-12">
    <TwCard>
      <div class="p-10 text-center text-xl">
        {{ $t('events.joiningEventCopy') }}
      </div>
    </TwCard>
  </div>
</template>
