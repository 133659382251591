export class PickedPhoto {
  constructor({ uploadedFile = null, caption = '', datePhotoTakenAt = '' } = {}) {
    this.uploadedFile = uploadedFile
    this.caption = caption
    this.datePhotoTakenAt = datePhotoTakenAt
    this.isUploading = false
    this.errors = []
    this.uploadProgress = 0
  }

  static parseExifDateFormat(date) {
    const [dateSegment, time] = date.split(' ')
    const [year, month, day] = dateSegment.split(':')
    const [hour, minute, second] = time.split(':')

    return Date.parse(`${year}-${month}-${day} ${hour}:${minute}:${second}`)
  }
}
