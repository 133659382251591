<script setup>
import { EventPicture } from '@/models/EventPicture'
import DeletePictureModal from '@/components/event/DeletePictureModal.vue'
import { useAuthStore } from '@/stores/auth'
import { useEventStore } from '@/stores/Event'
import { storeToRefs } from 'pinia'
import { formatAgo } from '@/services/DateTimeFormatter'
import EditPictureModal from '@/components/event/EditPictureModal.vue'

const authStore = useAuthStore()
const eventStore = useEventStore()
const { event } = storeToRefs(eventStore)
const emits = defineEmits(['photoDeleted'])
const props = defineProps({
  eventPicture: {
    type: EventPicture,
    required: true
  },
  withCard: {
    type: Boolean,
    default: true
  },
  useFullSizePhoto: {
    type: Boolean,
    default: false
  }
})

const photoDeleted = () => {
  emits('photoDeleted')
}
const canDeletePhoto = () => {
  return props.eventPicture.uploadedBy.uid === authStore.user.uid || event.value.selfUser.isOwner
}
const openEventPicture = () => {
  eventStore.openEventPictureModal(props.eventPicture)
}
</script>

<template>
  <div>
    <div :class="[withCard ? 'bg-white rounded-xl shadow-sm text-sm' : '']">
      <div class="flex gap-3 sm:p-4 p-2.5 text-sm font-medium">
        <img
          :src="eventPicture.uploadedBy.avatarUrl"
          :alt="eventPicture.uploadedBy.name"
          class="w-9 h-9 rounded-full object-cover"
        />
        <div class="flex-1">
          <h4 class="text-black">{{ eventPicture.uploadedBy.name }}</h4>
          <div class="text-xs text-gray-500">{{ formatAgo(props.eventPicture.photoTakenAt) }}</div>
        </div>

        <div v-if="canDeletePhoto()" class="-mr-1">
          <button type="button" class="button__ico w-8 h-8">
            <ion-icon class="text-xl" name="ellipsis-horizontal"></ion-icon>
          </button>
          <div
            class="w-[245px]"
            uk-dropdown="pos: bottom-right; animation: uk-animation-scale-up uk-transform-origin-top-right; animate-out: true; mode: hover; delay-hide: 0"
          >
            <nav>
              <a class="cursor-pointer" :uk-toggle="`target: #edit-photo-${props.eventPicture.id}`">
                <ion-icon class="text-xl shrink-0" name="pencil-outline" />
                {{ $t('event.editPhoto') }}
              </a>
              <a
                class="text-red-400 hover:!bg-red-50 cursor-pointer"
                :uk-toggle="`target: #delete-photo-list-${props.eventPicture.id}`"
              >
                <ion-icon class="text-xl shrink-0" name="trash-outline" />
                {{ $t('delete') }}
              </a>
            </nav>
          </div>
        </div>
      </div>

      <div class="relative w-full sm:px-4 cursor-pointer" @click="openEventPicture">
        <img
          :src="useFullSizePhoto ? eventPicture.photo.imageUrl : eventPicture.photo.mediumUrl"
          class="sm:rounded-lg w-full h-full object-cover"
          alt=""
        />
      </div>

      <div class="sm:p-4 p-2 border-t border-gray-100 font-normal space-y-3 relative">
        <div class="flex items-start gap-3 relative">
          <a
            class="mt-0.5 cursor-pointer"
            :class="{ 'line-clamp-6': !useFullSizePhoto }"
            @click="openEventPicture"
          >
            {{ eventPicture.caption }}
          </a>
        </div>
      </div>
    </div>
  </div>

  <DeletePictureModal
    :id="`delete-photo-list-${props.eventPicture.id}`"
    :event-picture="eventPicture"
    :can-delete-photo="canDeletePhoto()"
    @deleted="photoDeleted"
  />
  <EditPictureModal
    :id="`edit-photo-${props.eventPicture.id}`"
    :event-picture="eventPicture"
    :can-delete-photo="canDeletePhoto()"
  />
</template>
