<script setup>
import { useBookDesignerState } from '@/stores/BookDesigner'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'

const designerState = useBookDesignerState()
const { albumTitle } = storeToRefs(designerState)

const size = computed({
  get() {
    return albumTitle.value.size
  },
  set(val) {
    designerState.setAlbumTitleSize(val)
  }
})
const color = computed({
  get() {
    return albumTitle.value.color
  },
  set(val) {
    designerState.setAlbumTitleColor(val)
  }
})

const verticalOffset = computed({
  get() {
    return albumTitle.value.verticalOffset
  },
  set(val) {
    designerState.setAlbumTitleVerticalOffset(val)
  }
})
</script>

<template>
  <div class="space-y-3.5 text-xs font-normal mt-5 mb-2 text-gray-600">
    <div class="flex flex-col">
      <h4 class="text-slate-400 text-sm">{{ $t('orders.titleColor') }}:</h4>
      <div>
        <label
          class="h-6 w-20 rounded-md border block cursor-pointer"
          :style="{ backgroundColor: color }"
          ><input v-model="color" type="color" class="opacity-0 cursor-pointer" />
        </label>
      </div>
    </div>
    <div class="flex flex-col">
      <h4 class="text-slate-400 text-sm">{{ $t('orders.titleSize') }}:</h4>
      <div>
        <input
          v-model="size"
          type="range"
          max="400"
          min="50"
          class="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
        />
      </div>
    </div>
    <div class="flex flex-col">
      <h4 class="text-slate-400 text-sm">{{ $t('orders.titlePosition') }}:</h4>
      <div>
        <input
          v-model="verticalOffset"
          type="range"
          max="90"
          min="-90"
          class="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
        />
      </div>
    </div>
  </div>
</template>
