export class OpenEventPicture {
  constructor({
    isEventPictureModalOpen = false,
    current = null,
    previous = null,
    next = null
  } = {}) {
    this.isEventPictureModalOpen = isEventPictureModalOpen
    this.current = current
    this.previous = previous
    this.next = next
  }
}
