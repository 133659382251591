<script setup>
import IconLoading from '@/components/icons/IconLoading.vue'
import { computed } from 'vue'

const props = defineProps({
  color: {
    type: String,
    default: 'bg-secondary'
  },
  size: {
    type: String,
    default: 'normal'
  },
  rounded: {
    type: String,
    default: 'rounded-full'
  },
  loading: {
    type: Boolean,
    default: false
  }
})
const BUTTON_SIZES = {
  SMALL: 'small',
  NORMAL: 'normal'
}

const getSizeClasses = () => {
  switch (props.size) {
    case BUTTON_SIZES.SMALL:
      return 'text-xs px-2 font-normal h-6'
    case BUTTON_SIZES.NORMAL:
    default:
      return 'text-sm py-1.5 px-4 font-semibold'
  }
}

const colorClass = computed(() => {
  switch (props.color) {
    case 'primary':
      return 'bg-primary text-white'
    case 'secondary':
      return 'text-pink-600 bg-pink-100 border border-pink-200'
    case 'danger':
      return 'bg-red-50 text-red-400 border-red-200 border'
    case 'blue':
      return 'bg-sky-100 border border-sky-200'
    default:
      return props.color
  }
})
</script>

<template>
  <button
    type="button"
    class="rounded-full leading-6 flex items-center gap-1"
    :class="[colorClass, getSizeClasses(), rounded]"
    :disabled="loading"
  >
    <IconLoading v-if="loading" />
    <slot v-else />
  </button>
</template>
