import { DEFAULT_LOCALE } from '@/services/I18n'

export class User {
  constructor({
    id = 0,
    avatarUrl = '',
    name = '',
    email = '',
    uid = '',
    createdAt = '',
    locale = '',
    subscriptionPlan = '',
    subscriptionPlanPaymentFrequency = '',
    emailNotificationsEnabled = false,
    pushNotificationsEnabled = false,
    hasActiveSubscription = false,
    subscriptionsExpiresAt = '',
    trialExpiresAt = '',
    trialActive = false
  } = {}) {
    this.id = id
    this.avatarUrl = avatarUrl || '/assets/images/avatars/avatar-3.jpg'
    this.name = name
    this.email = email
    this.uid = uid
    this.createdAt = new Date(createdAt)
    this.locale = locale || DEFAULT_LOCALE
    this.subscriptionPlan = subscriptionPlan
    this.subscriptionPlanPaymentFrequency = subscriptionPlanPaymentFrequency
    this.emailNotificationsEnabled = emailNotificationsEnabled
    this.pushNotificationsEnabled = pushNotificationsEnabled
    this.hasActiveSubscription = hasActiveSubscription
    this.subscriptionsExpiresAt = subscriptionsExpiresAt ? new Date(subscriptionsExpiresAt) : null
    this.trialExpiresAt = trialExpiresAt ? new Date(trialExpiresAt) : null
    this.trialActive = trialActive
  }
}
