<script setup>
const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  label: {
    type: String,
    required: true
  },
  hasError: {
    type: Boolean,
    default: false
  },
  modelValue: {
    required: true
  }
})

const emitUpdate = (value) => {
  emit('update:modelValue', value)
}
</script>

<template>
  <div class="md:flex items-center gap-10">
    <label class="md:w-32 text-right">{{ props.label }}</label>
    <div class="grow">
      <input
        type="text"
        class="w-full"
        :class="{ '!border-red-500': props.hasError }"
        :value="props.modelValue"
        @input="emitUpdate($event.target.value)"
      />
    </div>
  </div>
</template>
