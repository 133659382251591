import { ref } from 'vue'
import { defineStore } from 'pinia'
import { EventsRepository } from '@/repositories/EventsRepository'
import { EVENT_PICTURE_VIEW_GRID, KEY_CURRENT_PICTURE_VIEW } from '@/config/EventPictureViewtypes'
import { EventPictureRepository } from '@/repositories/EventPictureRepository'
import { OpenEventPicture } from '@/valueObjects/OpenEventPicture'
import { SocketConnection } from '../services/socket'

const DEFAULT_PICTURES_PER_PAGE = 20
const eventRepo = new EventsRepository()
const eventPictureRepo = new EventPictureRepository()
const socketConnection = new SocketConnection()

export const useEventStore = defineStore('Event', () => {
  const event = ref(null)
  const eventLoading = ref(true)
  const eventPicturesLoading = ref(true)
  const eventPictures = ref([])
  const currentViewMode = ref(
    localStorage.getItem(KEY_CURRENT_PICTURE_VIEW) || EVENT_PICTURE_VIEW_GRID
  )
  const currentPicturePage = ref(1)
  const canLoadMore = ref(true)
  const openEventPicture = ref(new OpenEventPicture())

  async function refreshEvent(eventId) {
    event.value = await eventRepo.getEvent(eventId)
  }

  async function loadEvent(eventId) {
    eventLoading.value = true
    await refreshEvent(eventId)
    eventLoading.value = false
    socketConnection.unregisterEvents()
    socketConnection.registerEvent(
      'EventUpdated',
      event.value.eventHash + eventId,
      async (args) => {
        switch (args.type) {
          case 'reloadEvent':
            await refreshEvent(eventId)
            break
          case 'reloadPicture':
            await loadEventPicture(eventId, args.pictureId)
            break
        }
      }
    )
  }

  function clearEvent() {
    event.value = null
    eventPictures.value = []
    currentPicturePage.value = 1
    canLoadMore.value = true
    openEventPicture.value = new OpenEventPicture()
    eventLoading.value = false
    eventPicturesLoading.value = false
  }

  const changeViewMode = (view) => {
    currentViewMode.value = view
    localStorage.setItem(KEY_CURRENT_PICTURE_VIEW, view)
  }

  function resetEventPaging() {
    currentPicturePage.value = 1
  }

  async function loadEventPictures(eventId) {
    eventPicturesLoading.value = true
    const loadedPictures = await eventPictureRepo.getEventPictures(
      eventId,
      currentPicturePage.value,
      DEFAULT_PICTURES_PER_PAGE
    )
    if (loadedPictures.length === 0) {
      canLoadMore.value = false
    }
    eventPictures.value = [...eventPictures.value, ...loadedPictures]
    eventPicturesLoading.value = false
    sortEventPicturesByDate()
  }

  async function loadEventPicture(eventId, pictureId) {
    try {
      const loadedPicture = await eventPictureRepo.getEventPicture(eventId, pictureId)
      let pictureExists = false
      for (let i = 0; i < eventPictures.value.length; i++) {
        if (eventPictures.value[i].id === pictureId) {
          pictureExists = true
          eventPictures.value[i] = loadedPicture
          break
        }
      }
      if (!pictureExists) {
        eventPictures.value = [...eventPictures.value, loadedPicture]
      }
    } catch (e) {
      eventPictures.value = eventPictures.value.filter((picture) => picture.id !== pictureId)
    }
    sortEventPicturesByDate()
  }

  function sortEventPicturesByDate() {
    // remove duplicates by id
    eventPictures.value = eventPictures.value.filter(
      (value, index, self) => index === self.findIndex((t) => t.id === value.id)
    )

    eventPictures.value.sort((a, b) => {
      return b.photoTakenAt - a.photoTakenAt
    })
  }

  async function resetEventPictures(eventId) {
    eventPictures.value = []
    currentPicturePage.value = 1
    canLoadMore.value = true
    await loadEventPictures(eventId)
  }

  async function loadMoreEventPictures(eventId) {
    if (!canLoadMore.value) {
      return
    }

    currentPicturePage.value += 1
    eventPicturesLoading.value = true
    await loadEventPictures(eventId)
    sortEventPicturesByDate()
  }

  async function deleteEventPicture(eventPicture) {
    const eventPictureId = eventPicture.id
    eventPictures.value = eventPictures.value.filter(
      (eventPicture) => eventPicture.id !== eventPictureId
    )
    await eventPictureRepo.deleteEventPicture(event.value.id, eventPictureId)
    sortEventPicturesByDate()
  }

  function openEventPictureModal(eventPicture) {
    const eventPictureIndex = eventPictures.value.findIndex(
      (picture) => picture.id === eventPicture.id
    )
    const previous = eventPictures.value[eventPictureIndex - 1] || null
    const next = eventPictures.value[eventPictureIndex + 1] || null
    openEventPicture.value = new OpenEventPicture({
      isEventPictureModalOpen: true,
      current: eventPicture,
      previous: previous,
      next: next
    })
  }
  function closeEventPictureModal() {
    openEventPicture.value = new OpenEventPicture()
  }

  async function removeMember(eventMember) {
    eventLoading.value = true
    await eventRepo.removeMember(event.value.id, eventMember.id)
    await loadEvent(event.value.id)
  }

  async function changeMemberRole(eventMember, role) {
    eventLoading.value = true
    await eventRepo.changeMemberRole(event.value.id, eventMember.id, role)
    await loadEvent(event.value.id)
  }

  return {
    loadEvent,
    event,
    eventLoading,
    eventPictures,
    currentViewMode,
    changeViewMode,
    eventPicturesLoading,
    currentPicturePage,
    loadEventPictures,
    loadMoreEventPictures,
    deleteEventPicture,
    canLoadMore,
    resetEventPictures,
    openEventPicture,
    openEventPictureModal,
    removeMember,
    changeMemberRole,
    clearEvent,
    resetEventPaging,
    closeEventPictureModal
  }
})
