<script setup>
import { useEventStore } from '@/stores/Event'
import { storeToRefs } from 'pinia'
import EventPictureListThumb from '@/components/event/EventPictureListThumb.vue'
import TwStaticModal from '@/components/ui/TwStaticModal.vue'

const eventStore = useEventStore()
const { openEventPicture } = storeToRefs(eventStore)
</script>

<template>
  <div v-show="openEventPicture.current">
    <TwStaticModal
      id="eventPictureModal"
      compact
      :dynamic-height="true"
      max-width="700px"
      :close="eventStore.closeEventPictureModal"
    >
      <div v-if="openEventPicture.current">
        <EventPictureListThumb
          :event-picture="openEventPicture.current"
          :with-card="false"
          :use-full-size-photo="true"
          @photoDeleted="eventStore.closeEventPictureModal"
        />
      </div>
    </TwStaticModal>
  </div>
</template>
