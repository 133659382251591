<script setup>
import { useBookDesignerState } from '@/stores/BookDesigner'
import TwButton from '@/components/ui/TwButton.vue'
import TwCard from '@/components/ui/TwCard.vue'
import OrderInformationReview from '@/components/orders/OrderInformationReview.vue'
import AddressInput from '@/components/orders/AddressInput.vue'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'
import IconLoading from '@/components/icons/IconLoading.vue'
import { mapErrors } from '@/services/MapErrors'
import TwErrorsList from '@/components/ui/TwErrorsList.vue'
import { OrdersRepository } from '@/repositories/OrderRepository'
import { useRouter } from 'vue-router'
import { ROUTES } from '@/router/Routes'

const designerState = useBookDesignerState()
const { addresses } = storeToRefs(designerState)
const ordersRepo = new OrdersRepository()
const router = useRouter()

const errors = ref([])

const isLoading = ref(false)
const fieldsWithError = ref([])

const goBack = () => {
  designerState.backToCreateBook()
}

const toggleBillingAddress = () => {
  designerState.toggleBillingAddress()
}
const validateAddresses = () => {
  fieldsWithError.value = []
  const requiredShippingFields = [
    'shippingAddress.name',
    'shippingAddress.phone',
    'shippingAddress.address',
    'shippingAddress.city',
    'shippingAddress.zip',
    'shippingAddress.country'
  ]
  const requiredBillingFields = [
    'billingAddress.name',
    'billingAddress.phone',
    'billingAddress.address',
    'billingAddress.city',
    'billingAddress.zip',
    'billingAddress.country'
  ]

  const requiredFields = addresses.value.billingSameAsShipping
    ? requiredShippingFields
    : [...requiredShippingFields, ...requiredBillingFields]

  requiredFields.forEach((field) => {
    const [addressType, fieldName] = field.split('.')
    if (!addresses.value[addressType][fieldName]) {
      fieldsWithError.value.push(field)
    }
  })
}
const fieldHasError = (field) => {
  return fieldsWithError.value.includes(field)
}

const placeOrder = async () => {
  if (isLoading.value) {
    return
  }

  validateAddresses()
  errors.value = []
  if (fieldsWithError.value.length > 0) {
    return
  }
  isLoading.value = true

  try {
    await ordersRepo.createOrder(designerState.getOrderAlbumData().toJson())
    designerState.resetState()
    await router.push({
      name: ROUTES.NEW_ORDER_PLACED
    })
  } catch (e) {
    if (e.response?.data?.errors) {
      errors.value = mapErrors(e.response.data.errors)
    } else {
      errors.value = ['generalError']
    }
  }
  isLoading.value = false
}
</script>

<template>
  <div class="flex-col">
    <div>
      <TwButton color="bg-primary text-white" @click="goBack">
        <ion-icon name="arrow-back-outline" class="mr-2"></ion-icon>
        {{ $t('orders.back') }}
      </TwButton>
    </div>
    <div id="js-oversized" class="flex max-lg:flex-col xl:gap-10 md:gap-3 gap-2 md:mt-10 mt-4">
      <TwCard class="p-5 w-full order-2">
        <div class="mb-8">
          <h2 class="text-xl font-semibold">{{ $t('address.shippingAddress') }}</h2>
          <div class="flex flex-col gap-4 mt-4">
            <AddressInput
              v-model="addresses.shippingAddress.name"
              :label="$t('address.name')"
              :has-error="fieldHasError('shippingAddress.name')"
            />
            <AddressInput
              v-model="addresses.shippingAddress.phone"
              :label="$t('address.phone')"
              :has-error="fieldHasError('shippingAddress.phone')"
            />
            <AddressInput
              v-model="addresses.shippingAddress.address"
              :label="$t('address.address')"
              :has-error="fieldHasError('shippingAddress.address')"
            />
            <AddressInput
              v-model="addresses.shippingAddress.address2"
              :label="$t('address.address2')"
              :has-error="fieldHasError('shippingAddress.address2')"
            />
            <AddressInput
              v-model="addresses.shippingAddress.city"
              :label="$t('address.city')"
              :has-error="fieldHasError('shippingAddress.city')"
            />
            <AddressInput
              v-model="addresses.shippingAddress.zip"
              :label="$t('address.zipCode')"
              :has-error="fieldHasError('shippingAddress.zip')"
            />
            <AddressInput
              v-model="addresses.shippingAddress.state"
              :label="$t('address.state')"
              :has-error="fieldHasError('shippingAddress.state')"
            />
            <AddressInput
              v-model="addresses.shippingAddress.country"
              :label="$t('address.country')"
              :has-error="fieldHasError('shippingAddress.country')"
            />
          </div>
          <div class="flex items-center mt-4 justify-center">
            <h4 class="text-slate-400 text-sm">
              {{ $t('address.shippingAddressSameAsBillingAddress') }}:
            </h4>
            <span class="ml-2" @click="toggleBillingAddress">
              <input type="checkbox" class="hidden" :checked="addresses.billingSameAsShipping" />
              <span class="switch-button !relative"></span>
            </span>
          </div>
        </div>
        <div v-if="!addresses.billingSameAsShipping" class="mb-8">
          <h2 class="text-xl font-semibold">{{ $t('address.billingAddress') }}</h2>
          <div class="flex flex-col gap-4 mt-4">
            <AddressInput
              v-model="addresses.billingAddress.name"
              :label="$t('address.name')"
              :has-error="fieldHasError('billingAddress.name')"
            />
            <AddressInput
              v-model="addresses.billingAddress.phone"
              :label="$t('address.phone')"
              :has-error="fieldHasError('billingAddress.phone')"
            />
            <AddressInput
              v-model="addresses.billingAddress.address"
              :label="$t('address.address')"
              :has-error="fieldHasError('billingAddress.address')"
            />
            <AddressInput
              v-model="addresses.billingAddress.address2"
              :label="$t('address.address2')"
              :has-error="fieldHasError('billingAddress.address2')"
            />
            <AddressInput
              v-model="addresses.billingAddress.city"
              :label="$t('address.city')"
              :has-error="fieldHasError('billingAddress.city')"
            />
            <AddressInput
              v-model="addresses.billingAddress.zip"
              :label="$t('address.zipCode')"
              :has-error="fieldHasError('billingAddress.zip')"
            />
            <AddressInput
              v-model="addresses.billingAddress.state"
              :label="$t('address.state')"
              :has-error="fieldHasError('billingAddress.state')"
            />
            <AddressInput
              v-model="addresses.billingAddress.country"
              :label="$t('address.country')"
              :has-error="fieldHasError('billingAddress.country')"
            />
          </div>
        </div>
        <TwErrorsList v-if="errors.length > 0" :errors="errors" class="py-5" />

        <div class="flex items-center pt-5 border-t justify-center">
          <TwButton class="bg-primary text-white" rounded="rounded-md" @click="placeOrder">
            <IconLoading v-if="isLoading" class="text-white" />
            <span v-else>{{ $t('newOrder.placeOrder') }}</span>
          </TwButton>
        </div>
      </TwCard>

      <div class="lg:max-w-[320px] w-full mx-auto lg:block order-1 lg:order-2">
        <OrderInformationReview />
      </div>
    </div>
  </div>
</template>
