export const ROUTES = {
  HOME: 'home',
  PROFILE: 'profile',
  EVENTS: 'events',
  ADD_EVENT: 'add-event',
  JOIN_EVENT: 'join',
  EDIT_EVENT: 'edit-event',
  EVENT: 'event',
  SETTINGS: 'settings',
  ORDERS: 'orders',
  NEW_ORDER: 'new-order',
  NEW_ORDER_PLACED: 'new-order-placed',
  SETTINGS_GENERAL: 'settings.general',
  SETTINGS_SUBSCRIPTION: 'settings.subscription',
  SETTINGS_NOTIFICATIONS: 'settings.notifications',
  SETTINGS_PASSWORD: 'settings.password',

  // external routes
  LOGIN: 'login',
  REGISTRATION: 'registration',
  FORGOT_PASSWORD: 'forgot-password'
}
