<script setup>
import IconClose from '@/components/icons/IconClose.vue'
import EventDetails from '@/components/event/EventDetails.vue'
import { useEventStore } from '@/stores/Event'
import { storeToRefs } from 'pinia'

const eventStore = useEventStore()
const { eventLoading, event } = storeToRefs(eventStore)
</script>

<template>
  <div id="event-details-modal" class="lg:p-20 p-10 uk-modal" uk-modal="">
    <div class="relative mx-auto bg-white rounded-lg shadow-xl uk-modal-dialog w-[400px]">
      <div class="p-10">
        <template v-if="eventLoading">
          <div class="w-full h-80 bg-slate-200/60 rounded-lg animate-pulse"></div>
        </template>
        <EventDetails v-else :event="event" />
      </div>
      <div class="flex justify-end p-6 text-sm font-medium px-6 py-4 border-t">
        <button class="px-4 py-1.5 rounded-md uk-modal-close" type="button">
          {{ $t('close') }}
        </button>
      </div>

      <!-- close button -->
      <button
        type="button"
        class="bg-white rounded-full p-2 absolute right-0 top-0 m-3 uk-modal-close"
      >
        <IconClose />
      </button>
    </div>
  </div>
</template>
