export class OrderAlbumData {
  constructor({
    eventId = 0,
    addresses = null,
    bookType = null,
    spreads = [],
    globalShowNumbers = false,
    globalShowCaptions = true,
    globalShowDate = true
  } = {}) {
    this.eventId = eventId
    this.addresses = addresses
    this.bookType = bookType
    this.spreads = this._simplifySpreads(spreads)
    this.globalShowNumbers = globalShowNumbers
    this.globalShowCaptions = globalShowCaptions
    this.globalShowDate = globalShowDate
  }

  toJson() {
    return {
      eventId: this.eventId,
      addresses: this.addresses,
      bookType: this.bookType,
      spreads: this.spreads,
      // globalShowNumbers: this.globalShowNumbers,
      globalShowCaptions: this.globalShowCaptions,
      globalShowDate: this.globalShowDate
    }
  }

  _simplifySpreads(spreads) {
    return spreads.map((spread) => {
      return spread.map((page) => {
        return {
          photoId: page.photo?.id ?? null,
          // showPageNumber: page.showPageNumber,
          showDate: page.showDate,
          showCaption: page.showCaption,
          pageType: page.pageType,
          caption: page.caption,
          date: page.dateHumanReadable,
          metaData: page.misc
        }
      })
    })
  }
}
