<script setup>
import EventMemberSmall from './EventMemberSmall.vue'
import { computed, ref } from 'vue'
import EventMemberFull from './EventMemberFull.vue'
import { useEventStore } from '@/stores/Event'
import { storeToRefs } from 'pinia'
import TwButton from '@/components/ui/TwButton.vue'
import InviteMembersModal from '@/components/event/InviteMembersModal.vue'

const eventStore = useEventStore()
const { event } = storeToRefs(eventStore)

const props = defineProps({
  members: {
    type: Array,
    required: true
  }
})

const isViewExpanded = ref(true)

const canModifyMembers = computed(() => {
  return event.value.selfUser.isOwner && props.members.length > 1
})

const numberOfOwners = computed(() => {
  return props.members.filter((member) => member.isOwner).length
})
</script>

<template>
  <div class="flex-col">
    <div class="flex justify-between">
      <h4 class="text-slate-400 text-sm">{{ $t('event.participants') }}:</h4>
    </div>
    <div v-if="!isViewExpanded" class="flex mt-2 gap-1">
      <EventMemberSmall
        v-for="user in members"
        :key="`event-member-sm-${user.id}`"
        :member="user"
      />
    </div>
    <div v-else class="mt-2">
      <EventMemberFull
        v-for="user in members"
        :key="`event-member-sm-${user.id}`"
        :member="user"
        :can-modify-members="canModifyMembers"
        :number-of-owners="numberOfOwners"
      />
    </div>
    <div v-if="event.selfUser.isOwner" class="flex flex-col items-center gap-4">
      <TwButton color="secondary" uk-toggle="target: #invite-members-modal" rounded="rounded-md">
        {{ $t('event.inviteParticipants') }}
      </TwButton>
    </div>
  </div>
  <InviteMembersModal />
</template>
