import { BaseAuthRepository } from './BaseAuthRepository'
import { Event } from '@/models/Event'
import { EventFull } from '@/models/EventFull'

export class EventsRepository extends BaseAuthRepository {
  async getEvents() {
    const { data } = await this.getAuth('/events')

    return data.data.map((event) => new Event(event))
  }

  async getEvent(id) {
    const { data } = await this.getAuth(`/event/${id}`)

    return new EventFull(data.data)
  }

  async createEvent(data) {
    return await this.postAuth('/event', data)
  }

  async updateEvent(eventId, data) {
    return await this.postAuth(`/event/${eventId}`, data)
  }

  async deleteEvent(eventId) {
    return await this.deleteAuth(`/event/${eventId}`)
  }

  async joinEvent(eventHash) {
    const { data } = await this.postAuth(`/event/${eventHash}/member`)

    return new EventFull(data.data)
  }

  async removeMember(eventId, memberId) {
    return await this.deleteAuth(`/event/${eventId}/member/${memberId}`)
  }

  async changeMemberRole(eventId, memberId, role) {
    return await this.putAuth(`/event/${eventId}/member/${memberId}`, { role })
  }
}
