<script setup>
import TwCard from '../ui/TwCard.vue'
import { useBookDesignerState } from '@/stores/BookDesigner'
import TwButton from '../ui/TwButton.vue'
import ChangePhotoModal from '@/components/orders/ChangePhotoModal.vue'
import { storeToRefs } from 'pinia'
import FrontCoverSettings from '@/components/orders/FrontCoverSettings.vue'

const designerState = useBookDesignerState()
const { currentSelectedPage } = storeToRefs(designerState)

const removePage = () => {
  designerState.removePage(currentSelectedPage.value.uid)
}
</script>

<template>
  <TwCard v-if="currentSelectedPage" class="lg:w-80 p-5">
    <div class="flex justify-between text-black">
      <h3 class="font-bold text-base">Page information</h3>
    </div>
    <div class="space-y-3.5 text-xs font-normal mt-5 mb-2 text-gray-600">
      <div class="flex">
        <h4 class="text-slate-400 text-sm">{{ $t('orders.selectedPage') }}:</h4>
        <h4 class="font-medium text-slate-900 text-sm ml-2">
          <template v-if="currentSelectedPage?.isNormalPage()">
            Page {{ currentSelectedPage.pageNumber }}
          </template>
          <template v-else> {{ $t(`orders.pageType-${currentSelectedPage?.pageType}`) }} </template>
        </h4>
      </div>
      <div class="flex flex-col">
        <h4 class="text-slate-400 text-sm">{{ $t('orders.currentPicture') }}:</h4>
        <div
          v-if="!currentSelectedPage?.canHavePhoto()"
          class="flex justify-center items-center text-sm my-1 border1 rounded-lg bg-slate-100 w-full h-64 p-4"
        >
          {{ $t('orders.cantPickPhotoCopy') }}
        </div>
        <div
          v-else-if="currentSelectedPage.photo"
          class="flex justify-center my-1 border1 rounded-lg bg-slate-100"
        >
          <img
            :src="currentSelectedPage.photo.photo.thumbnailUrl"
            alt="Photo"
            class="fill-current max-h-60 max-w-60"
          />
        </div>
        <div
          v-else
          class="flex justify-center my-1 border1 rounded-lg bg-slate-100 w-full h-64"
        ></div>
        <div
          v-if="currentSelectedPage?.canHavePhoto()"
          class="flex flex-col justify-center mt-1 gap-2 items-center"
        >
          <TwButton color="secondary" uk-toggle="target: #change-photo-modal" rounded="rounded-md">
            {{ $t('orders.changePhoto') }}
          </TwButton>
          <TwButton
            v-if="currentSelectedPage?.isNormalPage()"
            color="danger"
            rounded="rounded-md"
            @click="removePage"
          >
            {{ $t('orders.removePage') }}
          </TwButton>
        </div>
        <FrontCoverSettings v-if="currentSelectedPage?.isFrontCover()" />
      </div>
      <!--            <div class="flex flex-col">-->
      <!--                <h4 class="text-slate-400 text-sm">Caption:</h4>-->
      <!--                {{page.showCaption}}-->
      <!--                <div class="switch" @click="toggleCaptionEnabled">-->
      <!--                    <input type="checkbox" :checked="page.showCaption"><span class="switch-button !relative"></span>-->

      <!--                </div>-->
      <!--                <textarea></textarea>-->
      <!--            </div>-->
    </div>
  </TwCard>
  <ChangePhotoModal />
</template>
