<script setup>
import { EventPicture } from '@/models/EventPicture'
import { useEventStore } from '@/stores/Event'
import IconClose from '@/components/icons/IconClose.vue'

const { deleteEventPicture } = useEventStore()
const emit = defineEmits(['deleted'])
const props = defineProps({
  eventPicture: {
    type: EventPicture,
    required: true
  },
  id: {
    type: String,
    required: true
  },
  canDeletePhoto: {
    type: Boolean,
    required: true
  }
})

const confirmDeletePhoto = () => {
  if (props.canDeletePhoto) {
    deleteEventPicture(props.eventPicture)
    emit('deleted')
  }
}
</script>

<template>
  <div :id="id" class="lg:p-20 p-10 uk-modal" uk-modal="">
    <div class="uk-modal-dialog tt relative mx-auto bg-white rounded-lg shadow-xl w-[400px]">
      <div class="p-6">
        <h2 class="text-xl font-semibold">{{ $t('event.deletePhoto') }}</h2>
      </div>
      <div class="p-6 py-0">
        <p>{{ $t('event.deletePhotoConfirm') }}</p>
      </div>
      <div class="flex justify-end p-6 text-sm font-medium">
        <button class="px-4 py-1.5 rounded-md uk-modal-close" type="button">
          {{ $t('cancel') }}
        </button>
        <button
          class="px-5 py-1.5 bg-red-50 rounded-md uk-modal-close text-red-400 flex items-center"
          type="button"
          @click="confirmDeletePhoto"
        >
          <ion-icon name="trash-outline" class="text-sm mr-1"></ion-icon>
          {{ $t('event.delete') }}
        </button>
      </div>
      <!-- close button -->
      <button
        type="button"
        class="bg-white rounded-full p-2 absolute right-0 top-0 m-3 uk-modal-close"
      >
        <IconClose />
      </button>
    </div>
  </div>
</template>
