<script setup>
import { useAuthStore } from '@/stores/auth'
import { useRoute, useRouter } from 'vue-router'
import { computed } from 'vue'
import { ROUTES } from '@/router/Routes'

const { user } = useAuthStore()
const route = useRoute()
const router = useRouter()

const routeName = computed(() => route.name)
</script>

<template>
  <div v-if="user" class="bg-white border border-slate-200 rounded-xl shadow-sm">
    <div class="flex md:gap-8 gap-4 items-center md:p-10 p-6">
      <div class="relative md:w-20 md:h-20 w-12 h-12 shrink-0">
        <img
          id="img"
          :src="user.avatarUrl"
          class="object-cover w-full h-full rounded-full"
          alt=""
        />
      </div>

      <div class="flex-1">
        <h3 class="md:text-xl text-base font-semibold text-black">
          {{ user.name }}
        </h3>
      </div>
    </div>

    <hr class="border-t border-gray-100" />
    <!-- nav tabs -->
    <div class="relative -mb-px px-2" tabindex="-1" uk-slider="finite: true">
      <nav class="overflow-hidden rounded-xl uk-slider-container pt-2">
        <ul
          class="uk-slider-items w-[calc(100%+10px)] capitalize font-semibold text-gray-500 text-sm"
          uk-switcher="connect: #setting_tab ; animation: uk-animation-slide-right-medium, uk-animation-slide-left-medium"
        >
          <li class="w-auto pr-2.5">
            <a
              class="inline-block p-4 pt-2 border-b-2 border-transparent cursor-pointer"
              :class="{ 'text-blue-500 !border-blue-500': routeName === ROUTES.SETTINGS_GENERAL }"
              @click="router.push({ name: ROUTES.SETTINGS_GENERAL })"
            >
              {{ $t('settings.general') }}
            </a>
          </li>
          <li class="w-auto pr-2.5">
            <a
              class="inline-block p-4 pt-2 border-b-2 border-transparent cursor-pointer"
              :class="{
                'text-blue-500 !border-blue-500': routeName === ROUTES.SETTINGS_SUBSCRIPTION
              }"
              @click="router.push({ name: ROUTES.SETTINGS_SUBSCRIPTION })"
            >
              {{ $t('settings.subscription') }}
            </a>
          </li>
          <li class="w-auto pr-2.5">
            <a
              class="inline-block p-4 pt-2 border-b-2 border-transparent cursor-pointer"
              :class="{
                'text-blue-500 !border-blue-500': routeName === ROUTES.SETTINGS_NOTIFICATIONS
              }"
              @click="router.push({ name: ROUTES.SETTINGS_NOTIFICATIONS })"
            >
              {{ $t('settings.notifications') }}
            </a>
          </li>
          <li class="w-auto pr-2.5">
            <a
              class="inline-block p-4 pt-2 border-b-2 border-transparent cursor-pointer"
              :class="{ 'text-blue-500 !border-blue-500': routeName === ROUTES.SETTINGS_PASSWORD }"
              @click="router.push({ name: ROUTES.SETTINGS_PASSWORD })"
            >
              {{ $t('settings.password') }}
            </a>
          </li>
        </ul>
      </nav>

      <a
        class="absolute -translate-y-1/2 top-1/2 left-0 flex items-center w-20 h-full p-2.5 justify-start rounded-xl bg-gradient-to-r from-white via-white"
        href="#"
        uk-slider-item="previous"
      >
        <ion-icon name="chevron-back" class="text-2xl ml-1"></ion-icon>
      </a>
      <a
        class="absolute right-0 -translate-y-1/2 top-1/2 flex items-center w-20 h-full p-2.5 justify-end rounded-xl bg-gradient-to-l from-white via-white"
        href="#"
        uk-slider-item="next"
      >
        <ion-icon name="chevron-forward" class="text-2xl mr-1"></ion-icon>
      </a>
    </div>
  </div>
</template>
