<script setup>
import IconHomeOutline from '../icons/IconHomeOutline.vue'
import IconHomeSolid from '../icons/IconHomeSolid.vue'
import IconProfileOutline from '../icons/IconProfileOutline.vue'
import IconProfileSolid from '../icons/IconProfileSolid.vue'
import { ROUTES } from '@/router/Routes'
import IconShopOutline from '../icons/IconShopOutline.vue'
import IconShopSolid from '../icons/IconShopSolid.vue'
import { computed } from 'vue'
import { useRoute } from 'vue-router'

const route = useRoute()
const activeMenuItem = computed(() => {
  const { name } = route

  switch (name) {
    case ROUTES.EVENTS:
    case ROUTES.EVENT:
    case ROUTES.HOME:
      return ROUTES.EVENTS
    case ROUTES.ORDERS:
    case ROUTES.NEW_ORDER:
    case ROUTES.NEW_ORDER_PLACED:
      return ROUTES.ORDERS
    case ROUTES.SETTINGS:
    case ROUTES.SETTINGS_GENERAL:
    case ROUTES.SETTINGS_SUBSCRIPTION:
    case ROUTES.SETTINGS_NOTIFICATIONS:
    case ROUTES.SETTINGS_PASSWORD:
      return ROUTES.SETTINGS
    default:
      return ''
  }
})
</script>

<template>
  <nav class="flex-1 max-md:flex max-md:justify-around md:space-y-2">
    <router-link
      :to="{ name: ROUTES.EVENTS }"
      :class="{ active: activeMenuItem === ROUTES.EVENTS }"
    >
      <IconHomeOutline />
      <IconHomeSolid class="hidden" />
      <span class="max-xl:hidden">{{ $t('menu.events') }}</span>
    </router-link>

    <router-link
      :to="{ name: ROUTES.ORDERS }"
      :class="{ active: activeMenuItem === ROUTES.ORDERS }"
    >
      <IconShopOutline />
      <IconShopSolid class="hidden" />
      <span class="max-xl:hidden">{{ $t('menu.orders') }}</span>
    </router-link>

    <router-link
      :to="{ name: ROUTES.SETTINGS_GENERAL }"
      class="max-md:!hidden"
      :class="{ active: activeMenuItem === ROUTES.SETTINGS }"
    >
      <IconProfileOutline />
      <IconProfileSolid class="hidden" />
      <span class="max-xl:hidden">{{ $t('menu.profile') }}</span>
    </router-link>
  </nav>
</template>
