<script setup>
import TwCard from '@/components/ui/TwCard.vue'
import { useBookDesignerState } from '@/stores/BookDesigner'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'
import TwButton from '@/components/ui/TwButton.vue'
import { formatMoney } from '@/services/MoneyFormatter'
import { EUR } from '@/config/Currencies'
const designerState = useBookDesignerState()
const { bookType, globalShowCaptions, globalShowDate, spreads } = storeToRefs(designerState)

const numberOfPages = computed(() => {
  return (spreads.value.length - 2) * 2
})

const price = computed(() => {
  return (
    bookType.value.basePrice +
    Math.ceil((numberOfPages.value - bookType.value.includedPages) / 2) *
      bookType.value.pricePerExtraSpread
  )
})
const toggleCaptions = () => {
  designerState.toggleGlobalShowCaptions()
}

const toggleDate = () => {
  designerState.toggleGlobalShowDate()
}

const addSpread = () => {
  designerState.addSpread()
}
const moveToNextStep = () => {
  designerState.bookCreated()
}
</script>

<template>
  <TwCard class="lg:w-80 p-5">
    <div class="flex justify-between text-black">
      <h3 class="font-bold text-base">{{ $t('newOrder.albumInformation') }}</h3>
    </div>

    <div class="space-y-3.5 text-xs font-normal mt-5 mb-2 text-gray-600">
      <div class="flex">
        <h4 class="text-slate-400 text-sm">{{ $t('newOrder.pageSize') }}:</h4>
        <h4 class="font-medium text-slate-900 text-sm ml-2">
          {{ bookType.pageWidth }}cm x {{ bookType.pageHeight }}cm
        </h4>
      </div>
      <div class="flex">
        <h4 class="text-slate-400 text-sm">{{ $t('newOrder.cover') }}:</h4>
        <h4 class="font-medium text-slate-900 text-sm ml-2">{{ $t('newOrder.hardCover') }}</h4>
      </div>
      <div class="flex">
        <h4 class="text-slate-400 text-sm">{{ $t('newOrder.numberOfPages') }}:</h4>
        <h4 class="font-medium text-slate-900 text-sm ml-2">{{ numberOfPages }}</h4>
      </div>
      <div class="flex">
        <h4 class="text-slate-400 text-sm">{{ $t('orders.price') }}:</h4>
        <h4 class="font-medium text-slate-900 text-sm ml-2">
          {{ formatMoney(price, EUR) }}
        </h4>
      </div>
      <div class="flex items-center">
        <h4 class="text-slate-400 text-sm">{{ $t('newOrder.showPhotoCaptions') }}:</h4>
        <span class="ml-2" @click="toggleCaptions">
          <input type="checkbox" class="hidden" :checked="globalShowCaptions" />
          <span class="switch-button !relative"></span>
        </span>
      </div>
      <div class="flex items-center">
        <h4 class="text-slate-400 text-sm">{{ $t('newOrder.showPhotoDate') }}:</h4>
        <span class="ml-2" @click="toggleDate">
          <input type="checkbox" class="hidden" :checked="globalShowDate" />
          <span class="switch-button !relative"></span>
        </span>
      </div>
      <div class="flex items-center">
        <TwButton color="secondary" rounded="rounded-md" @click="addSpread">
          {{ $t('orders.addSpread') }}
        </TwButton>
      </div>
      <div class="flex items-center mt-5 pt-5 border-t justify-center">
        <TwButton class="text-white bg-primary" rounded="rounded-md" @click="moveToNextStep">
          {{ $t('orders.continue') }}
        </TwButton>
      </div>
    </div>
  </TwCard>
</template>
