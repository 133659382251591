<script setup>
import SubscriptionBenefit from '@/components/settings/SubscriptionBenefit.vue'
import TwButton from '@/components/ui/TwButton.vue'
import { useAuthStore } from '@/stores/auth'
import { storeToRefs } from 'pinia'
import {
  SUBSCRIPTION_PLAN_BASIC,
  SUBSCRIPTION_PLAN_FREE,
  SUBSCRIPTION_PLAN_PROFESSIONAL
} from '@/config/SubscriptionPlans'
import { computed, ref } from 'vue'
import IconLoading from '@/components/icons/IconLoading.vue'
import { UserRepository } from '@/repositories/UserRepository'
import { formatToHumanReadable } from '@/services/DateTimeFormatter'
import TwSuccessToast from '@/components/ui/TwSuccessToast.vue'
import TwFailedToast from '@/components/ui/TwFailedToast.vue'
import { useRoute } from 'vue-router'

const authStore = useAuthStore()
const { user } = storeToRefs(authStore)
const userRepo = new UserRepository()
const route = useRoute()

const isPlanFree = computed(() => {
  return user.value.subscriptionPlan === SUBSCRIPTION_PLAN_FREE
})
const isPlanBasic = computed(() => {
  return user.value.subscriptionPlan === SUBSCRIPTION_PLAN_BASIC
})
const isPlanProfessional = computed(() => {
  return user.value.subscriptionPlan === SUBSCRIPTION_PLAN_PROFESSIONAL
})
const showSuccessToast = computed(() => {
  return route.query?.success === '1'
})
const showFailedToast = computed(() => {
  return route.query?.success === '0'
})
const showTrialSuccessToast = ref(false)

const isLoading = ref(false)

const startTrial = async (plan) => {
  isLoading.value = true
  await userRepo.startTrial(plan)
  await authStore.getUser()
  showTrialSuccessToast.value = true
  isLoading.value = false
}

const subscribe = async (plan) => {
  isLoading.value = true

  const { paymentUrl } = await userRepo.subscribe(plan)
  isLoading.value = false
  window.location = paymentUrl
}
</script>

<template>
  <div class="">
    <div class="flex flex-col gap-1">
      <div class="font-normal">
        {{ $t('subscription.currentTier') }}:
        <span class="font-bold">{{ $t(`subscription.${user.subscriptionPlan}`) }}</span>
      </div>
      <div v-if="user.subscriptionsExpiresAt" class="font-normal">
        {{ $t('subscription.subscriptionRenewsAt') }}:
        <span class="font-bold">{{ formatToHumanReadable(user.subscriptionsExpiresAt) }}</span>
      </div>
      <div v-if="user.trialExpiresAt && user.trialActive" class="font-normal">
        {{ $t('subscription.freeTrialExpiresAt') }}:
        <span class="font-bold">{{ formatToHumanReadable(user.trialExpiresAt) }}</span>
      </div>
      <div
        v-if="!user.hasActiveSubscription && !user.trialActive && user.trialExpiresAt"
        class="font-normal"
      >
        {{ $t('subscription.freeTrialExpiresAt') }}:
        <span class="font-bold">
          {{ $t('subscription.freeTrialExpired') }}
        </span>
      </div>
      <TwSuccessToast v-if="showTrialSuccessToast" class="mt-3">
        {{ $t('subscription.freeTrialStarted') }}
      </TwSuccessToast>
      <TwSuccessToast v-if="showSuccessToast" class="mt-3">
        {{ $t('subscription.paymentSuccessful') }}
      </TwSuccessToast>
      <TwFailedToast v-if="showFailedToast" class="mt-3">
        {{ $t('subscription.paymentFailed') }}
      </TwFailedToast>
    </div>

    <div class="grid lg:grid-cols-3 grid-cols-1 gap-5 mt-10">
      <div>
        <div class="relative p-4 bg-slate-100 shadow-sm rounded-xl">
          <div class="mb-4 text-sm">{{ $t('subscription.tearFree') }}</div>
          <h2 class="text-3xl font-bold text-black relative px-2">
            {{ $t('subscription.priceFree') }}
          </h2>
          <ion-icon
            v-if="isPlanFree"
            name="checkmark-circle"
            class="active absolute top-0 right-0 m-4 text-2xl text-blue-600 uk-animation-scale-up md hydrated"
            role="img"
            aria-label="checkmark circle"
          ></ion-icon>
        </div>
        <div class="pt-4 gap-6 flex flex-col">
          <SubscriptionBenefit
            icon="image"
            text="subscription.benefitJoinEvents"
            icon-colors="bg-teal-100 text-teal-500"
          />
          <SubscriptionBenefit
            icon="cart"
            text="subscription.benefitOrderAlbums"
            icon-colors="bg-sky-100 text-sky-500"
          />
        </div>
        <div class="flex justify-center mt-6"></div>
      </div>

      <div>
        <div class="relative p-4 bg-slate-100 shadow-sm rounded-xl">
          <div class="mb-4 text-sm">{{ $t('subscription.tearBasic') }}</div>
          <h2 class="text-3xl font-bold text-black relative px-2">
            <span class="text-xs absolute top-1.5 -left-1 font-normal text-gray-400">€</span>
            25.00
            <span class="text-sm font-normal text-gray-400">/ {{ $t('subscription.year') }}</span>
          </h2>
          <ion-icon
            v-if="isPlanBasic"
            name="checkmark-circle"
            class="active absolute top-0 right-0 m-4 text-2xl text-blue-600 uk-animation-scale-up md hydrated"
            role="img"
            aria-label="checkmark circle"
          ></ion-icon>
        </div>
        <div class="pt-4 gap-6 flex flex-col">
          <SubscriptionBenefit
            icon="image"
            text="subscription.benefitJoinEvents"
            icon-colors="bg-teal-100 text-teal-500"
          />
          <SubscriptionBenefit
            icon="cart"
            text="subscription.benefitOrderAlbums"
            icon-colors="bg-sky-100 text-sky-500"
          />
          <SubscriptionBenefit
            icon="add-circle-outline"
            text="subscription.benefitCreateEvents"
            icon-colors="bg-orange-100 text-orange-500"
          />
        </div>
        <div class="flex justify-center mt-6">
          <TwButton
            v-if="!isPlanBasic"
            color="bg-primary text-white"
            @click="startTrial(SUBSCRIPTION_PLAN_BASIC)"
          >
            <IconLoading v-if="isLoading" class="text-white" />
            <span v-else>{{ $t('subscription.startFreeTrial') }}</span>
          </TwButton>
          <TwButton
            v-if="isPlanBasic && (!user.hasActiveSubscription || user.trialActive)"
            color="bg-primary text-white"
            @click="subscribe(SUBSCRIPTION_PLAN_BASIC)"
          >
            <IconLoading v-if="isLoading" class="text-white" />
            <span v-else>{{ $t('subscription.subscribe') }}</span>
          </TwButton>
        </div>
      </div>

      <div>
        <div class="relative p-4 bg-slate-100 shadow-sm rounded-xl">
          <div class="mb-4 text-sm">{{ $t('subscription.tearProfessional') }}</div>
          <h2 class="text-3xl font-bold text-black relative px-2">
            <span class="text-xs absolute top-1.5 -left-1 font-normal text-gray-400">€</span>
            200.00
            <span class="text-sm font-normal text-gray-400">/ {{ $t('subscription.year') }}</span>
          </h2>
          <ion-icon
            v-if="isPlanProfessional"
            name="checkmark-circle"
            class="active absolute top-0 right-0 m-4 text-2xl text-blue-600 uk-animation-scale-up md hydrated"
            role="img"
            aria-label="checkmark circle"
          ></ion-icon>
        </div>
        <div class="pt-4 gap-6 flex flex-col">
          <SubscriptionBenefit
            icon="image"
            text="subscription.benefitJoinEvents"
            icon-colors="bg-teal-100 text-teal-500"
          />
          <SubscriptionBenefit
            icon="cart"
            text="subscription.benefitOrderAlbumsPro"
            icon-colors="bg-sky-100 text-sky-500"
          />
          <SubscriptionBenefit
            icon="add-circle-outline"
            text="subscription.benefitCreateEvents"
            icon-colors="bg-orange-100 text-orange-500"
          />
          <SubscriptionBenefit
            icon="people-outline"
            text="subscription.benefitCreatePublicEvents"
            icon-colors="bg-purple-100 text-purple-500"
          />
        </div>
        <div class="flex justify-center mt-6">
          <TwButton v-if="!isPlanProfessional">
            <IconLoading v-if="isLoading" class="text-gray-400" />
            <span v-else>{{ $t('subscription.comingSoon') }}</span>
          </TwButton>
        </div>
      </div>
    </div>

    <div class="py-10 flex justify-between">
      <p class="max-w-xl mx-auto text-center text-xs text-gray-500">
        {{ $t('subscription.trialExplanation') }}
      </p>
    </div>
  </div>
</template>
