<script setup>
import { useBookDesignerState } from '@/stores/BookDesigner'
import TwButton from '@/components/ui/TwButton.vue'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'
import TwCard from '@/components/ui/TwCard.vue'
import { formatToHumanReadable } from '@/services/DateTimeFormatter'
import TwBadge from '@/components/ui/TwBadge.vue'
import EventMemberSmall from '@/components/event/EventMemberSmall.vue'
import BookThumb from '@/components/orders/BookThumb.vue'
import { BOOK_SIZES } from '@/config/BookSizes'

const bookDesignerState = useBookDesignerState()
const { orderEventFull } = storeToRefs(bookDesignerState)
const loading = ref(false)

const loadFullEvent = async () => {
  loading.value = true
  await bookDesignerState.loadFullEvent()
  loading.value = false
}
const goBack = () => {
  bookDesignerState.resetState()
}
const getStatusBgClassName = () => {
  if (orderEventFull.value.hasStarted) {
    return 'bg-green-100'
  }
  if (orderEventFull.value.hasEnded) {
    return 'bg-red-100'
  }

  return 'bg-slate-200'
}
const getStatusName = () => {
  if (orderEventFull.value.hasStarted) {
    return 'event.statusActive'
  }
  if (orderEventFull.value.hasEnded) {
    return 'event.statusEnded'
  }

  return 'event.statusNotStarted'
}

const pickBookType = (type) => {
  bookDesignerState.pickBookType(type)
}
loadFullEvent()
</script>

<template>
  <div class="w-full">
    <div>
      <TwButton color="bg-primary text-white" @click="goBack">
        <ion-icon name="arrow-back-outline" class="mr-2"></ion-icon>
        {{ $t('orders.back') }}
      </TwButton>
    </div>
    <div v-if="!loading" class="flex flex-col gap-6">
      <TwCard class="p-6 mt-6">
        <p class="mb-6">
          {{ $t('orders.pickBookTypeCopy') }}
        </p>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
          <BookThumb
            v-for="book in BOOK_SIZES"
            :key="book.title"
            :book="book"
            @click="pickBookType(book)"
          />
        </div>
      </TwCard>
      <TwCard class="p-6">
        <div class="flex flex-col md:flex-row gap-4">
          <div class="w-full md:w-1/3 grow">
            <img
              :src="orderEventFull.coverImageUrl || '/assets/images/avatars/avatar-lg-4.jpg'"
              class="w-full object-cover h-64"
              :alt="orderEventFull.name"
            />
          </div>
          <div class="w-full md:w-2/3 flex flex-col gap-2">
            <h4 class="font-medium text-black text-xl">{{ orderEventFull.name }}</h4>
            <div class="flex gap-2">
              <TwBadge :color="getStatusBgClassName()">
                {{ $t(getStatusName()) }}
              </TwBadge>
              <TwBadge class="bg-sky-100">
                <template
                  v-if="
                    orderEventFull.startAt.toLocaleString() ===
                    orderEventFull.endAt.toLocaleString()
                  "
                >
                  {{ formatToHumanReadable(orderEventFull.startAt) }}
                </template>
                <template v-else>
                  {{ formatToHumanReadable(orderEventFull.startAt) }}
                  -
                  {{ formatToHumanReadable(orderEventFull.endAt) }}
                </template>
              </TwBadge>
            </div>
            <p>{{ orderEventFull.description }}</p>
            <div class="flex">
              <span class="text-slate-500">Members:</span>
              <div class="flex ml-2">
                <EventMemberSmall
                  v-for="user in orderEventFull.members"
                  :key="`event-member-sm-${user.id}`"
                  :member="user"
                />
              </div>
            </div>
            <div>
              <span class="text-slate-500">Photos:</span>
              {{ orderEventFull.pictureCount }}
            </div>
          </div>
        </div>
      </TwCard>
    </div>
    <div v-else class="flex flex-col gap-6 mt-6">
      <div class="bg-slate-300/60 rounded-md animate-pulse h-64 w-full"></div>
      <div class="bg-slate-300/60 rounded-md animate-pulse h-64 w-full"></div>
    </div>
  </div>
</template>
