import { ref } from 'vue'
import { defineStore } from 'pinia'
import {
  STEP_DESIGN_BOOK,
  STEP_PICK_ADDRESS,
  STEP_PICK_BOOK_TYPE,
  STEP_PICK_EVENT
} from '@/config/Orders'
import { EventsRepository } from '@/repositories/EventsRepository'
import { EventPictureRepository } from '@/repositories/EventPictureRepository'
import { BookCreationService } from '@/services/BookCreationService'
import { OrderAddresses } from '@/models/OrderAddresses'
import { OrderAlbumData } from '@/models/OrderAlbumData'
import { AlbumTitle } from '@/models/AlbumTitle'

const eventsRepo = new EventsRepository()
const eventPictureRepo = new EventPictureRepository()

export const useBookDesignerState = defineStore('BookDesigner', () => {
  const orderEvent = ref(null)
  const albumTitle = ref(new AlbumTitle())
  const orderEventFull = ref(null)
  const availablePhotos = ref([])
  const bookType = ref(null)
  const currentStep = ref(STEP_PICK_EVENT)

  const currentSelectedPage = ref(null)
  const spreadInFocus = ref(0)
  const spreads = ref([])

  const globalShowNumbers = ref(false)
  const globalShowCaptions = ref(true)
  const globalShowDate = ref(true)

  const addresses = ref(new OrderAddresses())

  const toggleBillingAddress = () => {
    addresses.value.billingSameAsShipping = !addresses.value.billingSameAsShipping
  }

  const toggleGlobalShowDate = () => {
    globalShowDate.value = !globalShowDate.value
    setMiscPageData()
  }
  const toggleGlobalShowNumbers = () => {
    globalShowNumbers.value = !globalShowNumbers.value
    setMiscPageData()
  }

  const toggleGlobalShowCaptions = () => {
    globalShowCaptions.value = !globalShowCaptions.value
    setMiscPageData()
  }

  const setMiscPageData = () => {
    spreads.value.forEach((spread) => {
      spread.forEach((page) => {
        page.showPageNumber = globalShowNumbers.value
        page.showDate = globalShowDate.value
        page.showCaption = globalShowCaptions.value
      })
    })
  }

  const changePhoto = (photo) => {
    if (!currentSelectedPage.value) {
      return
    }

    currentSelectedPage.value.photo = photo
    currentSelectedPage.value.caption = photo.caption || ''
    currentSelectedPage.value.date = photo.photoTakenAt
  }
  const loadAvailablePhotos = async () => {
    if (availablePhotos.value.length > 0) {
      return
    }

    const photos = await eventPictureRepo.getEventPictures(orderEvent.value.id, 1, 5000)
    availablePhotos.value = photos.reverse()
  }
  const loadFullEvent = async () => {
    orderEventFull.value = await eventsRepo.getEvent(orderEvent.value.id)
  }

  const pickBookType = (type) => {
    bookType.value = type
    currentStep.value = STEP_DESIGN_BOOK
  }

  const resetState = () => {
    orderEvent.value = null
    orderEventFull.value = null
    bookType.value = null
    currentStep.value = STEP_PICK_EVENT
    currentSelectedPage.value = null
    spreadInFocus.value = 0
    spreads.value = []
    globalShowDate.value = true
    globalShowNumbers.value = false
    globalShowCaptions.value = true
    addresses.value = new OrderAddresses()
  }

  const backToPickBookType = () => {
    currentStep.value = STEP_PICK_BOOK_TYPE
  }

  const backToCreateBook = () => {
    currentStep.value = STEP_DESIGN_BOOK
  }

  const pickEvent = (event) => {
    currentStep.value = STEP_PICK_BOOK_TYPE
    orderEvent.value = event
    albumTitle.value.title = event.name
  }

  const bookCreated = () => {
    currentStep.value = STEP_PICK_ADDRESS
  }

  const focusOnSpread = (spreadIndex) => {
    spreadInFocus.value = spreadIndex
  }

  const openPage = (page) => {
    currentSelectedPage.value = page
  }

  const setSpreads = (newSpreads) => {
    spreads.value = newSpreads
    setMiscPageData()
  }

  const removePage = (pageUid) => {
    const pages = spreads.value.flat()
    let nextPageIndex = 0
    const pageIndex = pages.findIndex((page) => page.uid === pageUid)
    if (pageIndex === -1) {
      return
    }

    const newSpreads = BookCreationService.removePage(spreads.value, pageUid)
    setSpreads(newSpreads)
    openPage(pages[nextPageIndex])
  }

  const addSpread = () => {
    const newSpreads = BookCreationService.addSpread(spreads.value)
    setSpreads(newSpreads)
  }

  const getOrderAlbumData = () => {
    // add title to the cover
    const frontCover = spreads.value[0][0]
    frontCover.setCoverTitle(albumTitle.value)

    return new OrderAlbumData({
      eventId: orderEvent.value.id,
      addresses: addresses.value,
      bookType: bookType.value,
      spreads: spreads.value,
      globalShowNumbers: globalShowNumbers.value,
      globalShowCaptions: globalShowCaptions.value,
      globalShowDate: globalShowDate.value
    })
  }

  const setAlbumTitleColor = (color) => {
    albumTitle.value.color = color
  }
  const setAlbumTitleSize = (size) => {
    albumTitle.value.size = size
  }
  const setAlbumTitleVerticalOffset = (verticalOffset) => {
    albumTitle.value.verticalOffset = verticalOffset
  }

  return {
    currentSelectedPage,
    spreadInFocus,
    spreads,
    openPage,
    focusOnSpread,
    setSpreads,
    orderEvent,
    bookType,
    currentStep,
    pickEvent,
    resetState,
    orderEventFull,
    loadFullEvent,
    pickBookType,
    backToPickBookType,
    availablePhotos,
    loadAvailablePhotos,
    changePhoto,
    globalShowNumbers,
    globalShowCaptions,
    toggleGlobalShowCaptions,
    toggleGlobalShowNumbers,
    globalShowDate,
    toggleGlobalShowDate,
    removePage,
    addSpread,
    bookCreated,
    backToCreateBook,
    addresses,
    toggleBillingAddress,
    getOrderAlbumData,
    albumTitle,
    setAlbumTitleColor,
    setAlbumTitleSize,
    setAlbumTitleVerticalOffset
  }
})
