<script setup>
import NewOrderBookDesigner from '@/components/orders/NewOrderBookDesigner.vue'
import PageSettings from '@/components/orders/PageSettings.vue'
import { BookCreationService } from '@/services/BookCreationService'
import { useBookDesignerState } from '@/stores/BookDesigner'
import TwButton from '@/components/ui/TwButton.vue'
import { ref } from 'vue'
import { storeToRefs } from 'pinia'
import AlbumInformation from '@/components/orders/AlbumInformation.vue'
const designerState = useBookDesignerState()
const { availablePhotos, bookType, spreads } = storeToRefs(designerState)
const loading = ref(true)

const loadSpreads = async () => {
  if (spreads.value.length > 0) {
    loading.value = false

    return
  }
  loading.value = true
  await designerState.loadAvailablePhotos()
  designerState.setSpreads(BookCreationService.createBook(availablePhotos.value, bookType.value))
  designerState.openPage(designerState.spreads[0][0])
  loading.value = false
}
const goBack = () => {
  designerState.backToPickBookType()
}
loadSpreads()
</script>

<template>
  <div class="flex-col">
    <div>
      <TwButton color="bg-primary text-white" @click="goBack">
        <ion-icon name="arrow-back-outline" class="mr-2"></ion-icon>
        {{ $t('orders.back') }}
      </TwButton>
    </div>
    <div v-if="!loading" class="flex flex-col lg:flex-row mt-6 lg:gap-6">
      <div class="w-full lg:w-2/3">
        <NewOrderBookDesigner />
      </div>

      <!-- right sidebar -->
      <div class="w-full lg:w-1/3">
        <div
          class="xl:space-y-6 space-y-3 md:pb-12 uk-sticky"
          uk-sticky="end: #js-oversized; offset: 50; media:992"
          style=""
        >
          <PageSettings />
          <AlbumInformation />
        </div>
        <div
          class="uk-sticky-placeholder"
          style="height: 1482px; width: 370px; margin: 0px"
          hidden=""
        ></div>
      </div>
    </div>
    <div v-else class="flex flex-col lg:flex-row mt-6 lg:gap-6">
      <div class="w-full lg:w-2/3">
        <div class="bg-slate-300/60 rounded-md animate-pulse h-64 w-full"></div>
      </div>
      <div class="w-full lg:w-1/3 xl:space-y-6 space-y-3 md:pb-12">
        <div class="bg-slate-300/60 rounded-md animate-pulse h-32 w-full"></div>
        <div class="bg-slate-300/60 rounded-md animate-pulse h-32 w-full"></div>
      </div>
    </div>
  </div>
</template>
