export class BookSize {
  constructor({
    sizeName = '',
    pageHeight = 0,
    pageWidth = 0,
    pageHeightPx = 0,
    pageWidthPx = 0,
    cover = '',
    basePrice = 0,
    pricePerExtraSpread = 0,
    includedPages = 0,
    maxPages = 0
  } = {}) {
    this.sizeName = sizeName
    this.pageHeight = pageHeight
    this.pageWidth = pageWidth
    this.pageHeightPx = pageHeightPx
    this.pageWidthPx = pageWidthPx
    this.cover = cover
    this.basePrice = basePrice
    this.pricePerExtraSpread = pricePerExtraSpread
    this.includedPages = includedPages
    this.maxPages = maxPages
  }
}
