import { format, formatDistance } from 'date-fns'
import { I18n, LOCALE_EN, LOCALE_LV } from '@/services/I18n'
import { enGB, lv } from 'date-fns/locale'

const locales = {
  [LOCALE_LV]: lv,
  [LOCALE_EN]: enGB
}

export const formatToHumanReadable = (date) => {
  return format(date, 'd MMM, yyyy', { locale: locales[I18n.locale] })
}

export const formatMDY = (date) => {
  return format(date, 'MM/dd/yyyy', { locale: locales[I18n.locale] })
}

export const formatAgo = (date) => {
  return formatDistance(date, new Date(), { addSuffix: true, locale: locales[I18n.locale] })
}

export const timeStartOfDay = (date) => {
  return date.toISOString().slice(0, 10) + ' 00:00:00'
}

export const timeEndOfDay = (date) => {
  return date.toISOString().slice(0, 10) + ' 23:59:59'
}
