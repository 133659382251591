<script setup></script>

<template>
  <div class="flex justify-between items-center my-4 md:my-0">
    <h3 class="font-extrabold text-2xl text-black">
      <slot />
    </h3>
    <div>
      <slot name="actions" />
    </div>
  </div>
</template>
