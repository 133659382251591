<script setup>
import TwPageTitle from '../components/ui/TwPageTitle.vue'
import { useRoute } from 'vue-router'
import TwButton from '../components/ui/TwButton.vue'
import EventPicturesContent from '../components/event/EventPicturesContent.vue'
import EventViewSwitcher from '../components/event/EventViewSwitcher.vue'
import EventDetailsCard from '@/components/event/EventDetailsCard.vue'
import EventMoreMobileMenu from '@/components/event/EventMoreMobileMenu.vue'
import EventDetailsModal from '@/components/event/EventDetailsModal.vue'
import { useEventStore } from '@/stores/Event'
import { storeToRefs } from 'pinia'
import AddEventPhotoModal from '@/components/event/AddEventPhotoModal.vue'

const route = useRoute()
const eventStore = useEventStore()
const { eventPicturesLoading, canLoadMore, event } = storeToRefs(eventStore)
const eventId = parseInt(route.params.id)

eventStore.loadEvent(eventId)
eventStore.resetEventPaging()
eventStore.loadEventPictures(eventId)
window.onscroll = () => {
  if (eventPicturesLoading.value || !canLoadMore.value) {
    return
  }
  if (window.innerHeight + Math.round(window.scrollY) >= document.body.offsetHeight - 200) {
    eventStore.loadMoreEventPictures(eventId)
  }
}
</script>
<template>
  <TwPageTitle>
    {{ $t('event.title') }}
    <template #actions>
      <div class="flex">
        <div class="lg:flex hidden">
          <EventViewSwitcher />
        </div>
        <div class="lg:hidden flex">
          <EventMoreMobileMenu />
        </div>
        <TwButton
          v-if="event?.selfUser.canPostPhotos"
          color="bg-primary text-white"
          uk-toggle="target: #add-photo"
        >
          <ion-icon name="add-outline" class="text-xl"></ion-icon>
          {{ $t('event.addPhoto') }}
        </TwButton>
      </div>
    </template>
  </TwPageTitle>

  <div id="js-oversized" class="flex max-lg:flex-col xl:gap-10 md:gap-3 md:mt-10">
    <EventPicturesContent />

    <div class="lg:max-w-[270px] w-full mx-auto hidden lg:block">
      <EventDetailsCard />
    </div>
  </div>
  <EventDetailsModal />
  <AddEventPhotoModal :event-id="eventId" />
</template>
