<script setup>
import { EVENT_PICTURE_VIEW_GRID, EVENT_PICTURE_VIEW_LIST } from '@/config/EventPictureViewtypes'
import { useEventStore } from '@/stores/Event'
import { computed } from 'vue'

const eventStore = useEventStore()
const currentViewMode = computed(() => eventStore.currentViewMode)

const getBackgroundClass = (viewName) => {
  return currentViewMode.value === viewName
    ? 'bg-primary text-white'
    : 'bg-pink-100 border border-pink-200 text-pink-600'
}
const changeView = (view) => {
  eventStore.changeViewMode(view)
}
</script>

<template>
  <div class="flex mr-2">
    <button
      :class="getBackgroundClass(EVENT_PICTURE_VIEW_GRID)"
      class="text-sm rounded-l-full py-1.5 pl-4 pr-2 font-semibold leading-6 flex items-center gap-1"
      @click="changeView(EVENT_PICTURE_VIEW_GRID)"
    >
      <ion-icon name="grid"></ion-icon>
    </button>
    <button
      :class="getBackgroundClass(EVENT_PICTURE_VIEW_LIST)"
      class="text-sm rounded-r-full py-1.5 pr-4 pl-2 font-semibold leading-6 flex items-center gap-1"
      @click="changeView(EVENT_PICTURE_VIEW_LIST)"
    >
      <ion-icon name="image"></ion-icon>
    </button>
  </div>
</template>
