import { EventPictureDateGroup } from '@/models/EventPictureDateGroup'
import { generateUUID } from './UuidGenerator'

export class EventPictureDateGroupFactory {
  static createFromEventPictureArray(eventPictures) {
    const eventPictureDateGroupsObject = {}

    eventPictures.forEach((eventPicture) => {
      const year = eventPicture.photoTakenAt.getFullYear()
      const month = (eventPicture.photoTakenAt.getMonth() + 1).toString().padStart(2, '0')
      const day = eventPicture.photoTakenAt.getDate().toString().padStart(2, '0')
      const eventPictureDateString = `${year}/${month}/${day}`
      if (!eventPictureDateGroupsObject[eventPictureDateString]) {
        eventPictureDateGroupsObject[eventPictureDateString] = new EventPictureDateGroup({
          uid: generateUUID(),
          date: new Date(eventPictureDateString),
          eventPictures: []
        })
      }
      eventPictureDateGroupsObject[eventPictureDateString].eventPictures.push(eventPicture)
    })

    return Object.values(eventPictureDateGroupsObject)
  }
}
