export class Event {
  constructor({
    id = 0,
    coverImageUrl = '',
    name = '',
    createdAt = '',
    startAt = '',
    endAt = '',
    eventHash = '',
    hasStarted = true,
    hasEnded = false,
    description = ''
  } = {}) {
    this.id = id
    this.coverImageUrl = coverImageUrl
    this.name = name
    this.createdAt = new Date(createdAt)
    this.startAt = new Date(startAt)
    this.endAt = new Date(endAt)
    this.eventHash = eventHash
    this.hasStarted = hasStarted
    this.hasEnded = hasEnded
    this.description = description
  }
}
