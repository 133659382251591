<script setup>
import { TRANSLATION_KEYS } from '@/config/NotificationFrequencies'

const props = defineProps({
  id: {
    type: String,
    required: true
  },
  notificationType: {
    type: String,
    required: true
  },
  value: {
    type: String,
    default: ''
  }
})
const emit = defineEmits(['change'])

const handleInput = () => emit('change', props.notificationType)
</script>

<template>
  <div>
    <label class="inline-flex items-center">
      <input
        :id="id"
        type="radio"
        :value="notificationType"
        :checked="notificationType === value"
        @input="handleInput"
      />
      <label class="ml-3" :for="id">{{ $t(TRANSLATION_KEYS[notificationType]) }}</label>
    </label>
  </div>
</template>
