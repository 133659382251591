<script setup>
import TwButton from '@/components/ui/TwButton.vue'
import IconLoading from '@/components/icons/IconLoading.vue'
import { ref } from 'vue'
import TwErrorsList from '@/components/ui/TwErrorsList.vue'
import { auth } from '../../../firebaseConfig'
import { updatePassword } from 'firebase/auth'
import TwSuccess from '@/components/ui/TwSuccess.vue'

const isLoading = ref(false)
const saved = ref(false)
const password = ref('')
const retypePassword = ref('')
const errors = ref([])

const validate = () => {
  errors.value = []
  if (password.value.length < 8) {
    errors.value.push('login.errors.noPassword')
  }
  if (password.value !== retypePassword.value) {
    errors.value.push('login.errors.passwordsDoNotMatch')
  }
  if (retypePassword.value.length < 8) {
    errors.value.push('login.errors.noRetypePassword')
  }
}
const save = async () => {
  saved.value = false
  if (isLoading.value) {
    return
  }
  validate()
  if (errors.value.length > 0) {
    return
  }
  isLoading.value = true
  await updatePassword(auth.currentUser, password.value)
  isLoading.value = false
  saved.value = true
  password.value = ''
  retypePassword.value = ''
}
</script>

<template>
  <div>
    <div>
      <div class="space-y-6">
        <TwSuccess v-if="saved" message="settings.passwordChanged" />
        <div class="md:flex items-center gap-16 justify-between max-md:space-y-3">
          <label class="md:w-40 text-right">{{ $t('login.password') }}</label>
          <div class="flex-1 max-md:mt-4">
            <input v-model="password" type="password" class="w-full" />
          </div>
        </div>

        <div class="md:flex items-center gap-16 justify-between max-md:space-y-3">
          <label class="md:w-40 text-right"> {{ $t('login.retypePassword') }} </label>
          <div class="flex-1 max-md:mt-4">
            <input v-model="retypePassword" type="password" class="w-full" />
          </div>
        </div>
      </div>
      <TwErrorsList :errors="errors" />

      <div class="flex items-center justify-center gap-4 mt-16">
        <TwButton color="primary" rounded="rounded-md" @click="save">
          <IconLoading v-if="isLoading" class="text-white" />
          <span v-else>{{ $t('save') }}</span>
        </TwButton>
      </div>
    </div>
  </div>
</template>
