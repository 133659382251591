<script setup>
import { EventPicture } from '@/models/EventPicture'
import IconClose from '@/components/icons/IconClose.vue'
import TwButton from '@/components/ui/TwButton.vue'
import VueDatePicker from '@vuepic/vue-datepicker'
import { EventPictureRepository } from '@/repositories/EventPictureRepository'
import { useEventStore } from '@/stores/Event'
import { ref } from 'vue'

const repository = new EventPictureRepository()
const { resetEventPictures } = useEventStore()
const props = defineProps({
  eventPicture: {
    type: EventPicture,
    required: true
  },
  id: {
    type: String,
    required: true
  },
  canDeletePhoto: {
    type: Boolean,
    required: true
  }
})

const caption = ref(props.eventPicture.caption)
const photoTakenAt = ref(props.eventPicture.photoTakenAt)
const loading = ref(false)

const reloadEvent = () => {
  resetEventPictures(props.eventPicture.eventId)
}
const editPhoto = async () => {
  loading.value = true
  await repository.updateEventPicture(
    props.eventPicture.eventId,
    props.eventPicture.id,
    caption.value || '',
    photoTakenAt.value
  )
  loading.value = false
  reloadEvent()
}
</script>

<template>
  <div :id="id" class="lg:p-20 p-10 uk-modal" uk-modal="">
    <div class="uk-modal-dialog tt relative mx-auto bg-white rounded-lg shadow-xl w-[400px]">
      <div class="p-6">
        <h2 class="text-xl font-semibold">{{ $t('event.editPhoto') }}</h2>
      </div>
      <div class="p-6 py-0">
        <div>
          <img
            :src="eventPicture.photo.mediumUrl"
            class="sm:rounded-lg w-full h-full object-cover"
            alt=""
          />
        </div>
        <div class="flex flex-col mt-6 gap-4">
          <div class="flex flex-col">
            <label class="">{{ $t('event.caption') }}:</label>
            <div class="">
              <textarea
                v-model="caption"
                class="w-full"
                rows="2"
                placeholder="Describe the photo..."
                :disabled="loading"
              ></textarea>
            </div>
          </div>
          <div class="flex flex-col">
            <label class="">{{ $t('event.photoTakenAt') }}:</label>
            <div class="">
              <VueDatePicker
                v-model="photoTakenAt"
                placeholder="Today"
                time-picker-inline
                :month-change-on-scroll="false"
                input-class-name="w-full tw-custom-input"
                :auto-apply="true"
                :disabled="loading"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-end p-6 text-sm font-medium">
        <button class="px-4 py-1.5 rounded-md uk-modal-close" type="button">
          {{ $t('cancel') }}
        </button>
        <TwButton
          class="bg-primary text-white uk-modal-close"
          :loading="loading"
          @click="editPhoto"
        >
          <ion-icon name="save-outline" class="text-sm mr-1"></ion-icon>
          {{ $t('save') }}
        </TwButton>
      </div>
      <!-- close button -->
      <button
        type="button"
        class="bg-white rounded-full p-2 absolute right-0 top-0 m-3 uk-modal-close"
      >
        <IconClose />
      </button>
    </div>
  </div>
</template>
