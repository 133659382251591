export const mapErrors = (inputErrors) => {
  const outputArray = []

  const keys = Object.keys(inputErrors)
  keys.forEach((key) => {
    inputErrors[key].forEach((value) => {
      const outputText = `errors.${key}.${value}`
      outputArray.push(outputText)
    })
  })

  return outputArray
}
