<script setup>
import TwBadge from '@/components/ui/TwBadge.vue'
import {
  STATUS_CANCELED,
  STATUS_COMPLETED,
  STATUS_INVOICE_SENT,
  STATUS_PAID,
  STATUS_PENDING,
  STATUS_PRINTING
} from '@/config/Orders'

const props = defineProps({
  status: {
    type: String,
    required: true
  }
})
const getColor = (status) => {
  switch (status) {
    case STATUS_PENDING:
      return 'bg-slate-50 text-slate-700 border border-slate-500/30'
    case STATUS_COMPLETED:
      return 'bg-green-50 text-green-700 border border-green-500/30'
    case STATUS_INVOICE_SENT:
      return 'bg-yellow-50 text-yellow-700 border border-yellow-500/30'
    case STATUS_PAID:
      return 'bg-blue-50 text-blue-700 border border-blue-500/30'
    case STATUS_CANCELED:
      return 'bg-red-50 text-red-700 border border-red-500/30'
    case STATUS_PRINTING:
      return 'bg-orange-50 text-orange-700 border border-orange-500/30'
    default:
      return 'bg-blue-50 text-blue-700 border border-blue-500/30'
  }
}
</script>

<template>
  <TwBadge :color="getColor(status)">
    {{ $t(`orders.status-${status}`) }}
  </TwBadge>
</template>
