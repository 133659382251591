// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth'
import { getMessaging, getToken } from 'firebase/messaging'
import { BaseAuthRepository } from '@/repositories/BaseAuthRepository'
import { GoogleAuthProvider } from 'firebase/auth'
import { I18n } from '@/services/I18n'
import { UserRepository } from '@/repositories/UserRepository'

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID
}
const googleAuthProvider = new GoogleAuthProvider()

// Initialize Firebase
const app = initializeApp(firebaseConfig)

//initialize firebase auth
const auth = getAuth()
auth.languageCode = I18n.getLocale()

const initAuth = async () => {
  await setPersistence(auth, browserLocalPersistence)
  BaseAuthRepository.authToken = auth.currentUser?.accessToken
}

const initMessaging = async () => {
  const messaging = getMessaging(app)
  // const user_id = usePage().props.auth.user.id;
  try {
    const token = await getToken(messaging, {
      vapidKey: import.meta.env.VITE_FIREBASE_VAPID_KEY
    })
    const userRepo = new UserRepository()
    await userRepo.updateFcmToken(token)
  } catch (e) {
    requestPermission()
  }

  function requestPermission() {
    Notification.requestPermission()
  }
}

export { app, auth, googleAuthProvider, initMessaging, initAuth }
