import md5 from 'js-md5'
import { io } from 'socket.io-client'

const socket = io(import.meta.env.VITE_SOCKET_URL)

export class SocketConnection {
  _channels = []
  constructor() {}

  registerEvent(channelName, key, callback) {
    const hashedKey = md5(key)
    const channel = `${channelName}.${hashedKey}`
    this._channels.push(channel)
    socket.on(channel, callback)
  }

  unregisterEvents() {
    this._channels.forEach((channel) => {
      socket.off(channel)
    })
  }
}
