import { BaseAuthRepository } from './BaseAuthRepository'
import { Order } from '@/models/Order'

export class OrdersRepository extends BaseAuthRepository {
  async getOrders() {
    const { data } = await this.getAuth('/orders')

    return data.data.map((order) => new Order(order))
  }
  async createOrder(orderData) {
    return await this.postAuth(`/orders`, orderData)
  }
}
