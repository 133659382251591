<script setup></script>

<template>
  <div class="p-2 border border-yellow-500/30 rounded-xl">
    <div class="inline-flex items-center gap-6">
      <!-- icon -->
      <div class="p-1 text-white bg-yellow-500 shadow rounded-xl shadow-yellow-300">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          class="w-8 h-8"
        >
          <path
            fill-rule="evenodd"
            d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 01.67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </div>

      <!-- text -->
      <div class="text-base font-semibold text-yellow-700">
        <slot />
      </div>
    </div>
  </div>
</template>
