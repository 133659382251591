import { BaseAuthRepository } from './BaseAuthRepository'
import { EventPicture } from '@/models/EventPicture'

export class EventPictureRepository extends BaseAuthRepository {
  async getEventPictures(eventId, page = 1, perPage = 20) {
    const { data } = await this.getAuth(`/event/${eventId}/pictures/${page}/${perPage}`)

    return data.data.map((event) => new EventPicture(event))
  }

  async getEventPicture(eventId, pictureId) {
    const { data } = await this.getAuth(`/event/${eventId}/picture/${pictureId}`)

    return new EventPicture(data.data)
  }

  async deleteEventPicture(eventId, eventPictureId) {
    await this.deleteAuth(`/event/${eventId}/picture/${eventPictureId}`)
  }
  async uploadEventPicture(eventId, payload, progressCallback) {
    return await this.postAuth(`/event/${eventId}/picture`, payload, progressCallback)
  }

  async updateEventPicture(eventId, pictureId, caption, photoTakenAt) {
    return await this.putAuth(`/event/${eventId}/picture/${pictureId}`, {
      caption,
      photoTakenAt
    })
  }
}
